<template>
  <div class="container-fluid">
    <h2 class="mt-3 mb-3">দৈনিক গড় ব্যালেন্স রিপোর্ট</h2>

    <div class="row mb-3">
      <h5 class="text-bold">কাস্টমারের তালিকা</h5>
        <ModelSelect :options="identity"
                     v-model="item"
                     placeholder="এখানে ক্নিক করে কাস্টমার নির্বাচন করুন"/>
    </div>
    <div class="row mb-3">
      <form onsubmit="return false" class="form-inline w-100">
        <label>রিপোর্ট শুরুর তারিখ</label>
        <datepicker  :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="from"
                     format="dd MMMM yyyy"></datepicker>
        হতে
        <datepicker  :highlighted="{days: [5]}" input-class="form-control ml-2 mr-2" v-model="to"
                     format="dd MMMM yyyy"></datepicker>

        <label>পর্যন্ত</label>
        <button class="btn btn-primary ml-4" @click="generateReport" :disabled="from=='' || to==''">রিপোর্ট তৈরী করুন</button>
      </form>

    </div>
    <div class="my-4">
    <h5 class="text-center" v-if="countDaysInOrderVariable">আপনি <span class="bn-number mx-3 text-bold">{{ DateAdjust.fullDateToReportDate(this.from) }}</span> থেকে <span  class="bn-number mx-3 text-bold">{{ DateAdjust.fullDateToReportDate(this.to) }}</span> পর্যন্ত <span class="bn-number mx-3 text-bold">{{ countDaysInOrderVariable }}</span> এর রিপোর্ট তৈরী করছেন। </h5>
    <h4 class="text-center text-bold text-red" v-if="negativeOnly">উপরোক্ত নির্দিষ্ট সময়ে এই কাস্টমারের সাথে ব্যবসা করে আপনার গড়ে <b class="bn-number f-24 mx-4">{{ AmountService.commaDecimalWithCurrency(lastBalance*(-1)) }} টাকা</b> <b class="mx-3 bn-number f-24">{{ daysToDuration(cumDays) }}</b>  যাবৎ আটকে ছিল</h4>
    <h4 class="text-center text-bold text-red" v-if="withPositive">উপরোক্ত নির্দিষ্ট সময়ে এই কাস্টমারের সাথে ব্যবসা করে আপনার গড়ে <b class="bn-number f-24 mx-4">{{ AmountService.commaDecimalWithCurrency(trxList[trxList.length-1].avgBalanceWithPositive*(-1)) }} টাকা</b> <b class="mx-3 bn-number f-24">{{ daysToDuration(trxList[trxList.length-1].cumDaysWithPositive) }}</b> যাবৎ আটকে ছিল</h4>
    <h4 class="text-center text-bold text-info" v-if="neutral">উপরোক্ত নির্দিষ্ট সময়ে এই কাস্টমারের সাথে ব্যবসা করে আপনার কোন অর্থনৈতিক ক্ষতি হয়নি</h4>
    </div>
    <table class="table tab-content">
      <thead>
      <tr>
        <td colspan="6">
        </td>
        <td colspan="2" class="text-center text-bold table-bt table-bl table-br bg-danger">শুধুমাত্র বাকি থাকা দিনের হিসাব</td>
        <td colspan="2" class="text-center text-bold table-bt table-bl table-br bg-info">সকল দিনের হিসাব</td>
      </tr>
        <tr>
          <th>তারিখ</th>
          <th>নোট</th>
          <th class="text-center">জমা</th>
          <th class="text-center">খরচ</th>
          <th class="text-center">ব্যালেন্স</th>
          <th>দিন</th>

          <th class="table-bt table-bl bg-danger text-center">মোট দিন</th>
<!--          <th>Weighted</th>-->
          <th class="table-bt table-br bg-danger text-right">গড় ব্যালেন্স</th>
<!--          <th>ব্যালেন্স</th>-->

          <th class="table-bt text-center">মোট দিন (+)</th>
<!--          <th>Weighted (+)</th>-->
          <th class="table-bt table-br text-right">গড় ব্যালেন্স (+)</th>
<!--          <th>ব্যালেন্স (+)</th>-->
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in trxList" v-bind:key="data.id">
          <td class="bn-number">{{ data.date }}</td>
          <td>{{ data.note }}</td>
          <template v-if="data.amount>=0">
            <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(data.amount) }}</td>
            <td></td>
          </template>
          <template v-else>
            <td></td>
            <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(data.amount*(-1)) }}</td>
          </template>
          <td class="bn-number text-right text-danger" v-if="data.balance<0">({{ AmountService.commaDecimalWithCurrency((data.balance*(-1))) }})</td>
          <td class="text-right bn-number" v-else>{{ AmountService.commaDecimalWithCurrency(data.balance) }}</td>
          <td class="bn-number">{{ data.days }}</td>


          <td class="table-bl table-bb text-center bn-number">{{ data.cumDays }}</td>
<!--      <td>{{ data.weightedBalanceDays }}</td>-->
          <td class="table-br table-bb bn-number text-right text-danger" v-if="data.avgBalance<0">({{ AmountService.commaDecimalWithCurrency((data.avgBalance*(-1))) }})</td>
          <td class="table-br table-bb text-right bn-number" v-else>{{ AmountService.commaDecimalWithCurrency(data.avgBalance) }}</td>

<!--          <td>{{data.cumAvgBalanceDays}} *</td>-->
<!---->
<!---->

          <td class="text-center table-bb bn-number">{{ data.cumDaysWithPositive }}</td>
<!--          <td>{{ data.weightedBalanceDaysWithPositive }}</td>-->
          <td class="table-br bn-number table-bb text-right text-danger" v-if="data.avgBalanceWithPositive<0">({{ AmountService.commaDecimalWithCurrency((data.avgBalanceWithPositive*(-1))) }})</td>
          <td class="table-br text-right table-bb bn-number" v-else>{{ AmountService.commaDecimalWithCurrency(data.avgBalanceWithPositive) }}</td>
<!--          <td>{{ data.cumAvgBalanceDaysWithPositive }}</td>-->
        </tr>
      <tr>
        <td colspan="2" class="text-right text-bold">মোট</td>
        <td class="text-right text-bold bn-number">{{ AmountService.commaDecimalWithCurrency(totalDeposit) }}</td>
        <td class="text-right text-bold bn-number">{{ AmountService.commaDecimalWithCurrency(totalExpense) }}</td>
        <td colspan="2" class="text-right text-bold"></td>
        <td class="table-bt table-bl table-bb  text-center bn-number" v-if="negativeOnly">{{ cumDays }} </td>
        <td class="table-bt table-br  table-bb bn-number text-right text-danger" v-if="negativeOnly">({{ AmountService.commaDecimalWithCurrency(lastBalance*(-1)) }})</td>
        <td colspan="2" v-if="negativeOnly"></td>
        <td colspan="2" v-if="withPositive"></td>
        <td class="table-bt table-bl table-bb  text-center bn-number" v-if="withPositive">{{ trxList[trxList.length-1].cumDaysWithPositive }} </td>
        <td class="table-bt table-br  table-bb bn-number text-right text-danger" v-if="withPositive">({{ AmountService.commaDecimalWithCurrency(trxList[trxList.length-1].avgBalanceWithPositive*(-1)) }})</td>


      </tr>

      </tbody>
    </table>
  </div>

</template>

<script>
import DateAdjust from "@/services/DateAdjust";
import Datepicker from 'vuejs-datepicker';
import AmountService from "@/services/amountService";
import ModelSelect from '@/components/ModelSelect/ModelSelect';
import Identity from "@/services/DataStructure/Identity";
export default {
  name: "ProfitReportComponent",
  computed: {
    DateAdjust() {
      return DateAdjust
    },
    AmountService() {
      return AmountService
    }
  },
  components: {Datepicker,
    ModelSelect
  },
  data(){
    return{
      trxList: [],
      trxListTemp: [],
      from: '',
      to: '',
      item: [],
      identity: [],
      totalDeposit: 0,
      totalExpense: 0,
      cumDays: 0,
      lastBalance: 0,
      negativeOnly: false,
      withPositive: false,
      neutral: false,
      countDaysInOrderVariable: ""
    }
  },
  methods:{
    getPercent(sales, profit){
      let s = parseFloat(sales)
      let p = parseFloat(profit)
      let percent = (p/s)*100
      return percent
    },
    countDaysInOrder(){
      let days  = (DateAdjust.formatDateToTimestamp(this.to, "to")+1000 - DateAdjust.formatDateToTimestamp(this.from, "from"))/86400000
      this.countDaysInOrderVariable = this.daysToDuration(days)
    },
    generateReport(){
      this.totalDeposit =0
      this.totalExpense = 0
      this.cumDays = 0
      let fromDate = DateAdjust.formatDateToTimestamp(this.from, "from")
      let toDate = DateAdjust.formatDateToTimestamp(this.to, "to")
      this.trxList = DateAdjust.printAllTrx()
      this.trxList  = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.info.id==this.item.value)
      this.trxListTemp = this.trxList
      //this.trxList = abc
      let structuredArray = []
      this.trxList.forEach(function(x) {
        let amount = 0
        if(x.section=="deposit")
          amount = x.amount
        else
          amount = x.amount*(-1)
        structuredArray.push({
          date: DateAdjust.formalDateToReportDate(x.date),
          amount: amount,
          timestamp: DateAdjust.plainUKDatetoTimestamp(x.date),
          balance: 0,
          note: x.description,
          days: 0,
          avgBalance: 0
        })
      })
      console.log(Identity.getIdentityBalanceToDateByID(this.item.value, this.from))
        let tempFirstDay = {
          date: DateAdjust.fullDateToReportDate(this.from),
          amount:  Identity.getIdentityBalanceToDateByID(this.item.value, this.from-86400000),
          timestamp: DateAdjust.formatDateToTimestamp(fromDate, "from"),
          balance:  Identity.getIdentityBalanceToDateByID(this.item.value, this.from-86400000),
          note: "পূর্বের জের",
          days: 0,
          avgBalance: 0
        }
        structuredArray.unshift(tempFirstDay)




      structuredArray.sort((a,b)=>Number(a.timestamp) - Number(b.timestamp))
      let balance = 0
      let cumulativeDays = 0
      let cumulativeWeightedBalance = 0
      let weightedBalanceDays = 0




      for(let i = 0; i<structuredArray.length-1; i++){
        balance += structuredArray[i].amount
        structuredArray[i].balance = balance
        if(i==0 && balance==0){
          structuredArray[i].days = 0
        }else{
          structuredArray[i].days = (parseInt(structuredArray[i+1].timestamp) - parseInt(structuredArray[i].timestamp))/86400000
        }
        if(structuredArray[i].balance<0){
          cumulativeDays += structuredArray[i].days
          weightedBalanceDays = structuredArray[i].days * structuredArray[i].balance
          cumulativeWeightedBalance += weightedBalanceDays


          structuredArray[i].cumDays = cumulativeDays
          structuredArray[i].weightedBalanceDays = weightedBalanceDays
          structuredArray[i].avgBalance = cumulativeWeightedBalance/cumulativeDays
          structuredArray[i].cumAvgBalanceDays = structuredArray[i].avgBalance*structuredArray[i].days
          this.cumDays += structuredArray[i].days
          this.lastBalance = structuredArray[i].avgBalance
        }

        // console.log(structuredArray[i].date+" -> "+structuredArray[i].timestamp+" -> "+typeof structuredArray[i].timestamp)
      }


      let lastIndex = structuredArray.length-1
      balance += structuredArray[lastIndex].amount
      structuredArray[lastIndex].balance = balance
      structuredArray[lastIndex].days = (DateAdjust.formatDateToTimestamp(this.to, "to")+1000 - parseInt(structuredArray[lastIndex].timestamp))/86400000
      if(structuredArray[lastIndex].balance<0){
        cumulativeDays += structuredArray[lastIndex].days
        weightedBalanceDays = structuredArray[lastIndex].days * structuredArray[lastIndex].balance
        cumulativeWeightedBalance += weightedBalanceDays


        structuredArray[lastIndex].cumDays = cumulativeDays
        structuredArray[lastIndex].weightedBalanceDays = weightedBalanceDays
        structuredArray[lastIndex].avgBalance = cumulativeWeightedBalance/cumulativeDays
        structuredArray[lastIndex].cumAvgBalanceDays = structuredArray[lastIndex].avgBalance*structuredArray[lastIndex].days
        this.cumDays += structuredArray[lastIndex].days
        this.lastBalance = structuredArray[lastIndex].avgBalance


      }
      this.trxList = structuredArray
      this.calculateWithPositive()
      this.totalDeposit = this.trxList.filter(x=>x.amount>=0).reduce((sum, x)=> sum+x.amount,0)
      this.totalExpense = this.trxList.filter(x=>x.amount<0).reduce((sum, x)=> sum+x.amount,0)*(-1)
      this.determineMaxLoss()
      this.countDaysInOrder()
    },
    calculateWithPositive(){
      let cumDaysWithPositive = 0;
      let cumWeightedBalanceDays = 0;
      for (let i = 0; i<this.trxList.length; i++){
        cumDaysWithPositive+=this.trxList[i].days
        this.trxList[i].cumDaysWithPositive = cumDaysWithPositive
        this.trxList[i].weightedBalanceDays = this.trxList[i].days * this.trxList[i].balance
        cumWeightedBalanceDays += this.trxList[i].weightedBalanceDays
        this.trxList[i].cumWeightedBalanceDaysWithPositive = cumWeightedBalanceDays
        this.trxList[i].avgBalanceWithPositive = this.trxList[i].cumWeightedBalanceDaysWithPositive / this.trxList[i].cumDaysWithPositive
      }
    },
    determineMaxLoss(){
      let negativeOnlyTotal = this.cumDays * this.lastBalance
      let withPositive = this.trxList[this.trxList.length-1].avgBalanceWithPositive * this.trxList[this.trxList.length-1].cumDaysWithPositive
      if(negativeOnlyTotal>=0 && withPositive>=0){
        this.negativeOnly = false
        this.withPositive = false
        this.neutral = true
      }else if(negativeOnlyTotal<withPositive) {
        this.negativeOnly = true
        this.withPositive = false
        this.neutral = false
      }else {
        this.withPositive = true
        this.negativeOnly = false
        this.neutral = false
      }
    },
    daysToDuration(day){
      let result = ""
      if(day>=365){
        result += parseInt(day/365) +" বছর "
        day%=365
      }
      if(day>=30){
        result += parseInt(day/30)+" মাস "
        day%=30
      }
      if(day>0){
        result += day+" দিন"
      }
      return result
    }
  },
  created() {
    let specificIdentityList = Identity.getIdentitiesByType("customer")
    specificIdentityList.forEach(x=>this.identity.push({value: x.id, text: x.primaryName + "=" +x.secondaryName + "="+ x.keyword + "="+ x.address + "=" + x.mobile, id: x.id}))
  }
}
</script>

<style scoped>
.table-bt{
  border-top: 2px solid #999999;
}
.table-bl{
  border-left: 2px solid #999999;
}
.table-br{
  border-right: 2px solid #999999;
}
.table-bb{
  border-bottom: 2px solid #999999;
}
.f-24{
  font-size: 22px;
}
</style>