<template>
  <div>
    <b-button v-b-modal.modal-expense class="btn btn-sm btn-danger float-right absolute-button no-print"><i class="bi bi-plus-lg"></i> খরচ</b-button>
    <b-modal id="modal-expense" size="xl" title="খরচের খাতা">
      <b-container>
        <b-row class="border-bottom">
          <b-col offset="3" cols="6">
            <b-form-group label-cols="4" label-cols-lg="4" label="খরচের ধরণ নির্বাচন করুন"
                          label-for="input-default">
              <b-form-select v-model="currentSelect" placeholder="ddsfdsfdsfds" :disabled="previewStatus"
                             :options="expenseOptions"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>


        <div v-if="currentSelect === 'stock'">


          <b-row class="mt-3">
            <b-col>
              <div role="group">
                <label for="input-live">প্রোডাক্ট নির্বাচন করুন:</label>
                <b-form-select v-model="currentProduct" placeholder="ddsfdsfdsfds" :disabled="previewStatus"
                               :options="getProductList"></b-form-select>
                <p class="mt-2">নির্বাচিত প্রোডাক্ট: <b>{{ selectedProduct.primaryName }}</b></p>
                <table class="table table-bordered table-sm">
                  <thead>
                  <tr>
                    <th scope="col">পরিমান
                      </th>
                    <th scope="col">দর
                      </th>
                    <th scope="col">মোট মূল্য</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input class="form-control digit-input" type="number"  :readonly="previewStatus" v-model="quantity"/></td>
                    <td><input class="form-control digit-input" type="number" disabled v-model="rate"/></td>
                    <td><input class="form-control digit-input" type="number" :readonly="previewStatus" v-model="total"/></td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">গাড়িভাড়া</td>
                    <td><input class="form-control digit-input" type="number" :readonly="previewStatus" v-model="transportationCost"/>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">লেবার খরচ</td>
                    <td><input class="form-control digit-input" type="number" :readonly="previewStatus" v-model="laborCost"/></td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">সর্বমোট</td>
                    <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(this.grandTotal) }}</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </b-col>
            <b-col>
              <div v-if="previewStatus">
                <h3 class="text-center h3 text-warning">সতর্কতার সাথে নিশ্চিত করুন</h3>
                <p>নির্বাচিত প্রোডাক্ট:
                  {{ confirmedData.info.name }}</p>
                <table class="table table-bordered table-sm">


                  <tbody>

                  <tr>

                    <th class="text-right">পরিমান</th>
                    <th class="text-right">দর</th>
                    <th class="text-right">মোট মূল্য</th>
                  </tr>
                  <tr>
                    <td class="text-right bn-number">{{ confirmedData.quantity }}</td>
                    <td class="text-right bn-number">{{ AmountService.commaWithCurrency(confirmedData.purchaseRate) }}</td>
                    <td class="text-right bn-number">{{ AmountService.commaWithCurrency(confirmedData.total) }}</td>
                  </tr>
                  <tr>

                    <td colspan="2" class="text-right">গাড়িভাড়া</td>
                    <td class="text-right bn-number">{{ AmountService.commaWithCurrency(confirmedData.transportationCost) }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">লেবার খরচ</td>
                    <td class="text-right bn-number">{{ AmountService.commaWithCurrency(confirmedData.laborCost) }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">সর্বমোট</td>
                    <td class="text-right bn-number">{{ AmountService.commaWithCurrency(confirmedData.amount) }}</td>
                  </tr>
                  <tr>
                    <td colspan="3"><i>কথায়: </i>{{ AmountService.toWordCurrency(getNumberInWord) }}</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </b-col>
          </b-row>

        </div>

        <div v-else>
          <b-row class="mt-3">
            <b-col>

              <ModelSelect :options="identities"
                           v-model="item"
                           placeholder="এখানে ক্নিক করে নির্বাচন করুন"/>
              <b-form-group
                  label="টাকার পরিমান" class="mt-4">
              <b-form-input class="digit-input" :readonly="previewStatus" type="number" label="Amount" v-model="amount"> </b-form-input>
              </b-form-group>
              <label for=""></label>
              <b-form-group
                  label="লেনদেনের বিবরণ">
              <b-form-textarea class="form-control" :readonly="previewStatus" v-model="amountDescription"></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col>
              <div v-if="previewStatus">


                <h3 class="text-center h3 text-warning">সতর্কতার সাথে নিশ্চিত করুন</h3>
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>নির্বাচিত তথ্য</td>
                      <td colspan="2"><ModelSelectListRenderer :text="item.text" /></td>
                    </tr>
                    <tr>
                      <td>টাকার পরিমান</td>
                      <td colspan="2"> <p class="bn-number">{{ AmountService.commaDecimalWithCurrency(amount) }}</p>{{ AmountService.toWordCurrency(amount) }}</td>
                    </tr>
                    <tr>
                      <td>লেনদেনের বিবরণ</td>
                      <td colspan="2" class="description-text-break"> {{ amountDescription }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </div>


        <b-row>
          <b-col>
            <b-button @click="preview" class="float-right button-abc" variant="primary">চেক করুন</b-button>
          </b-col>
          <b-col>
            <div v-if="previewStatus">
              <b-button @click="correction" variant="danger">সংশোধন করুন</b-button>
              <b-button class="float-right" @click="confirm" variant="success">নিশ্চিত করুন</b-button>
            </div>

          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="{ cancel }">

        <b-button  variant="info" @click="reset()">
          <i class="fa fa-undo" aria-hidden="true"></i>
          রিসেট করুন
        </b-button>
        <b-button variant="danger" @click="cancel()">
          <i class="fa fa-times" aria-hidden="true"></i> বন্ধ করুন
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// const fmt = require('indian-number-format');
// const {toBengaliWord} = require('bengali-number');
import 'vue-search-select/dist/VueSearchSelect.css'
import AmountService from "../../services/amountService";
import ModelSelect from '@/components/ModelSelect/ModelSelect'
import Identity from "@/services/DataStructure/Identity";
import identity from "@/services/DataStructure/Identity";
import ModelSelectListRenderer from "@/components/MiscComponents/ModelSelectListRenderer.vue";
import axios from "axios";
// /home/asif/Development/Shohug_Enterprise/shohug-enterprise-frontend/node_modules/vue-search-select/dist/VueSearchSelect.css
export default {
  name: 'ExpenseModal',
  components: {
    ModelSelectListRenderer,
      ModelSelect
  },
  props:{
    identityProp: [],
    identityListProp: [],
    productListProp: []
  },
  data() {
    return {
      currentSelect: "stock",
      currentProduct: "",
      rate: "",
      total: "",
      quantity: "",
      grandTotal: "",
      laborCost: 0,
      transportationCost: 0,
      previewStatus: false,
      amount: "",
      amountDescription: "",
      identities: [],
      confirmedData: {},
      item: {
        value: '',
        text: ''
      },
      selectedIdentity: {},
      selectedProduct:{}
    }
  },
  methods: {
    preview() {
      this.previewStatus = true;
      if(this.currentSelect==='stock'){

        this.confirmedData = {
          info: this.selectedProduct,
          quantity: isNaN(parseFloat(this.quantity)) ? 0: parseFloat(this.quantity),
          purchaseRate: parseFloat(this.rate),
          total: parseFloat(this.total),
          laborCost: parseFloat(this.laborCost),
          transportationCost: parseFloat(this.transportationCost),
          amount: parseFloat(this.grandTotal)
        }
      }else{


        this.confirmedData = {
          info: {
            id: this.selectedIdentity.id,
            primaryName: this.selectedIdentity.primaryName,
            secondaryName: this.selectedIdentity.secondaryName,
            mobile: this.selectedIdentity.mobile,
            address: this.selectedIdentity.address,
            type: this.selectedIdentity.type
          },
          amount: parseFloat(this.amount),
          description: this.amountDescription,
        }






        // this.confirmedData = {
        //   info: {
        //     id: "",
        //     name: this.item.text
        //   },
        //   amount: parseFloat(this.amount),
        //   description: this.amountDescription,
        //   type: "expense"
        // }
      }
    },
    correction() {
      this.confirmedData = null
      this.previewStatus = false;
    },
    confirm() {
      let finalData;
      if(this.currentSelect==='stock') {
        finalData = this.confirmedData
        finalData.type = "stock"
        finalData.status= "in"

      }else{
        finalData = this.confirmedData
      }
      this.currentProduct= ""
      this.rate= ""
      this.total= ""
      this.quantity= ""
      this.grandTotal= ""
      this.laborCost= 0
      this.transportationCost= 0
      this.previewStatus= false
      this.amount= ""
      this.amountDescription= ""
      this.confirmedData = null;
      this.previewStatus = false
      this.$emit('receiveModalData', finalData);
      if(this.currentSelect!=='stock') {
        window.open("/"+finalData.info.type+"/"+finalData.info.id)
      }else{
        this.backupNow()
      }

      // this.$swal('Success!!!', "তথ্য সফলভাবে যোগ হয়েছে", 'success');
      this.$bvModal.hide("modal-expense")
    },
    clearNow($event) {
      $event.target.value = ""
    },
    checkEmit() {
      this.$emit('hhhh', 'hello, world!')
    },
    async backupNow(){
      this.$swal.showLoading();
      let zer = JSON.parse(localStorage.getItem("zer"))
      let identity = JSON.parse(localStorage.getItem("identity"))
      let trxInfo = JSON.parse(localStorage.getItem("trxInfo"))
      let product = JSON.parse(localStorage.getItem("product"))
      let blackBox = JSON.parse(localStorage.getItem("blackBox"))
      await axios.post("https://xxxxxxxxxxxxxxxx.a.run.app/api/update/database",{
        zer: zer,
        identity: identity,
        trxInfo: trxInfo,
        product: product,
        blackBox: blackBox
      },{
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'text/plain'
      }).then((response) => {
        this.$swal.close()
        this.myData = response.data
        if(response.data.status === 'success'){
          this.$swal('তথ্য সংরক্ষণ সফল হয়েছে!', "আপনার তথ্য সফলভাবে সার্ভারে আপলোড হয়েছে", 'success');
        }else{
          this.$swal('বিপদ সংকেত!!!', "তথ্য সংরক্ষণ ব্যর্থ হয়েছে! দয়া করে আবার চেষ্টা করুন...", 'danger');
        }


      })
    }
  },
  computed: {
    identity() {
      return identity
    },
    AmountService() {
      return AmountService
    },

  getProductList(){
    let pList = []
    if(this.productListProp){
      this.productListProp.forEach((x)=>pList.push({value: x.id, text: x.primaryName, id: x.id}))
    }
    return pList
  },
    getNumberInWord: function () {
      // return toBengaliWord(this.grandTotal ? this.grandTotal : 0)
      return this.grandTotal ? this.grandTotal : 0
    },
    expenseOptions: function (){
      let x = this.identityProp.slice()
      x.push({value: 'stock', text: 'প্রোডাক্ট খরিদ'})
      x = x.filter(v=> v.value != 'profit')
      return x
    }
  },
  watch: {
    total: function (val) {
      this.rate = (val / this.quantity).toFixed(2)
      // this.total = val * this.rate
      this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)
    },
    // rate: function (val) {
    //   // this.total = val * this.quantity
    //   this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)
    // },
    laborCost: function (){
      this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)

    },
    transportationCost: function() {
      this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)

    },
    currentSelect(){
      this.selectedIdentity = {}
      this.selectedProduct = {}
      this.currentProduct = {}
      this.item = {}
      this.identities = [];
      let specificIdentityList = Identity.getIdentitiesByType(this.currentSelect)
      specificIdentityList.forEach(x=>this.identities.push({value: x.id, text: x.primaryName + "=" +x.secondaryName + "="+ x.keyword + "="+ x.address + "=" + x.mobile, id: x.id}))
    },
    item(){
      let singleIdentityArray = this.identityListProp.filter((x)=>x.id===this.item.id)
      if(singleIdentityArray.length>0){
        this.selectedIdentity = singleIdentityArray[0]
      }else{
        this.selectedIdentity = {}
      }
    },
    currentProduct(){
      console.log(this.currentProduct)
      console.log(this.productListProp)

      let singleProductArray = this.productListProp.filter((x)=>x.id===this.currentProduct)
      if(singleProductArray.length>0){
        this.selectedProduct = singleProductArray[0]
      }else{
        this.selectedProduct = {}
      }
    }
  }
}
</script>
<style>
.modal-title {
  font-size: 24px;
  /* text-align: center;
  margin-left: auto;
  margin-right: auto; */
}
.description-text-break{
  white-space: unset !important;
}
.button-abc:active {
  color: #333333;
  background-color: #FFFFFF;
}
</style>
