<template>
  <div class="container-fluid">
    <h2 class="mt-3 mb-3">বিক্রয় ও লাভের রিপোর্ট</h2>

    <div class="row mb-3">
      <form onsubmit="return false" class="form-inline">

        <label>রিপোর্ট শুরুর তারিখ</label>
        <datepicker  :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="from"
                    format="dd MMMM yyyy"></datepicker>
      হতে
        <datepicker  :highlighted="{days: [5]}" input-class="form-control ml-2 mr-2" v-model="to"
                    format="dd MMMM yyyy"></datepicker>

        <label>পর্যন্ত</label>
        <button class="btn btn-primary ml-4" @click="getList">রিপোর্ট তৈরী করুন</button>
      </form>

    </div>

  <table class="table">
    <thead>
    <tr>
      <th>বিক্রয়ের তারিখ</th>
      <th>প্রোডাক্টের নাম</th>
      <th class="text-center">ক্রয় দর ( P )</th>
      <th class="text-center">বিক্রয় দর ( S )</th>
      <th class="text-center">পরিমান ( Q )</th>
      <th class="text-right">মোট বিক্রয় ( S &#215; Q )</th>
      <th class="text-right">লাভ ( S &#215; Q ) - ( P &#215; Q )</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="i in trxList" v-bind:key="i.id">
      <td>{{ i.date }}</td>
      <td>{{ i.info.primaryName }}</td>
      <td class="bn-number text-center">{{ AmountService.commaDecimalWithCurrency( i.purchaseRate )}}</td>
      <td class="bn-number text-center">{{ AmountService.commaDecimalWithCurrency( i.saleRate ) }}</td>
      <td class="bn-number text-center">{{  i.quantity }}</td>
      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency( i.amount ) }}</td>
      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency( i.profit.toFixed(2) ) }}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr class="font-weight-bold">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td><h4 class="font-weight-bold text-right">সর্বমোট</h4></td>
      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(salesMot.toFixed(2))}}</td>
      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(profitMot.toFixed(2))}}</td>
    </tr>
    </tfoot>
  </table>

    <h1>দৈনিক বিক্রয় ও লাভের হিসাব</h1>
    <table class="table">
      <thead>
      <tr>
        <th>বিক্রয়ের তারিখ</th>
        <th class="text-right">মোট বিক্রয়</th>
        <th class="text-right">লাভ</th>
        <th class="text-right">শতকরা লাভের পরিমান(%)</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="i in dateWise" v-bind:key="i.timestamp">
        <td>{{ i.date }}</td>
        <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency( i.sales )}}</td>
        <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency( i.profit.toFixed(2) ) }}</td>
        <td class="bn-number text-right">{{ AmountService.commaDecimal( getPercent(i.sales, i.profit.toFixed(2) ) ) }}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr class="font-weight-bold">
        <td><h4 class="font-weight-bold text-right">সর্বমোট</h4></td>
        <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(salesMot.toFixed(2))}}</td>
        <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(profitMot.toFixed(2))}}</td>
        <td class="bn-number text-right">{{ AmountService.commaDecimal( getPercent(salesMot.toFixed(2), profitMot.toFixed(2)) ) }}%</td>

      </tr>
      </tfoot>
    </table>
    <div v-if="chartData.labels.length>0">
      <BarChart :graphData="chartData"/>
    </div>

  </div>

</template>

<script>
import DateAdjust from "@/services/DateAdjust";
import Datepicker from 'vuejs-datepicker';
import AmountService from "@/services/amountService";
import BarChart from "@/components/GraphChartComponents/BarChart.vue";

export default {
  name: "ProfitReportComponent",
  computed: {
    AmountService() {
      return AmountService
    }
  },
  components: {BarChart, Datepicker},
  data(){
    return{
      trxList: [],
      from: '',
      to: '',
      profitMot: 0.00,
      salesMot: 0.00,
      dateWise: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'বিক্রয়',
            backgroundColor: '#0D47A1',
            data: []
          },
          {
            label: 'লাভ',
            backgroundColor: '#1B5E20',
            data: []
          }
        ]
      }
    }
  },
  methods:{
    getPercent(sales, profit){
      let s = parseFloat(sales)
      let p = parseFloat(profit)
      let percent = (p/s)*100
      return percent
    },
    getList(){
      this.chartData.labels =[]
      this.chartData.datasets[0].data = []
      this.chartData.datasets[1].data = []

      let year = new Date(this.from).getFullYear()
      let month = new Date(this.from).getMonth()+1
      if(month>0 && month<10){
        month = "0"+month
      }
      let date = new Date(this.from).getDate()
      if(date>0 && date<10){
        date = "0"+date
      }
      let fromDate = year+"-"+month+"-"+date+"T00:00:00Z"
      year = new Date(this.to).getFullYear()
      month = new Date(this.to).getMonth()+1
      if(month>0 && month<10){
        month = "0"+month
      }
      date = new Date(this.to).getDate()
      if(date>0 && date<10){
        date = "0"+date
      }
      let toDate = year+"-"+month+"-"+date+"T23:59:59Z"




      console.log("From Date: "+fromDate)
      this.from = new Date(fromDate).getTime()
      this.to = new Date(toDate).getTime()
      this.trxList = DateAdjust.printAllTrx()
      this.trxList = this.trxList.filter(x=> x.zz<=this.to && x.zz>=this.from).filter(x=>x.status=="out")
      this.profitMot = this.trxList.reduce((sum, x)=> sum+x.profit,0)
      this.salesMot = this.trxList.reduce((sum, x)=> sum+x.amount,0)
      this.getDateWise()
    },
    getDateWise(){

      let result = [];
      this.trxList.reduce(function(res, value) {
        if (!res[value.date]) {

          let selfDate = value.date.split("-")
          let year = selfDate[2]
          let month = selfDate[1]
          if(month>0 && month<10){
            month = "0"+month
          }
          let date = selfDate[0]
          if(date>0 && date<10){
            date = "0"+date
          }
          let isoDate = year+"-"+month+"-"+date



          console.log(isoDate)

          let dateItem = new Date(isoDate).getTime();
          res[value.date] = { date: value.date, sales: 0, profit: 0, timestamp: dateItem, full: isoDate };
          res[value.date].timestamp = new Date(value.date).getTime();
          result.push(res[value.date])
        }
        res[value.date].sales += value.amount;
        res[value.date].profit += value.profit;

        return res;
      }, {});
      for(let i = 0; i<result.length-1; i++){
        for(let j = i+1; j<result.length; j++){
          if(result[i].timestamp>result[j].timestamp){
            let temp = result[i].timestamp;
            result[i].timestamp = result[j].timestamp
            result[j].timestamp = temp
          }
        }
      }

      this.dateWise = result;
     for(let i = 0 ; i<result.length; i++){
        this.chartData.labels.push(result[i].date)

       // for(let j = 0 ; j<result.length; j++) {
         this.chartData.datasets[0].data.push(result[i].sales)
       // }


       // for(let k = 0 ; k<result.length; k++) {
         this.chartData.datasets[1].data.push(result[i].profit)
       // }


     }
      console.log(this.chartData)
    }

  }
}
</script>

<style scoped>

</style>