<template>
    <div class="container-fluid">
      <h2 class="mt-3 mb-3">প্রোডাক্ট ক্যাটাগরি ভিত্তিক আয়ের রিপোর্ট</h2>

      <div class="row mb-3">
        <form onsubmit="return false" class="form-inline">

          <label>রিপোর্ট শুরুর তারিখ</label>
          <datepicker  :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="from"
                       format="dd MMMM yyyy"></datepicker>
          হতে
          <datepicker  :highlighted="{days: [5]}" input-class="form-control ml-2 mr-2" v-model="to"
                       format="dd MMMM yyyy"></datepicker>

          <label>পর্যন্ত</label>
          <button class="btn btn-primary ml-4" @click="generateReport()">রিপোর্ট তৈরী করুন</button>
        </form>
      </div>
<template v-if="visibility">
      <h3 class="mt-3">প্রোডাক্ট ক্যাটাগরি ভিত্তিক আয় রিপোর্টের সারমর্ম</h3>

        <div class="row">
        <div class="col-6">
        <table class="table table-bordered">
          <tr>
            <th colspan="2"><h4 class="text-bold">সিমেন্ট</h4></th>
          </tr>
          <tr>
            <th colspan="2"><h5 class="text-bold">আয়</h5></th>
          </tr>
          <tr>
            <td class="statement-title">বিক্রয় আয়</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.sales) }}</td>
          </tr>
          <tr>
            <td class="statement-title">বিক্রিত পণ্য ব্যয়</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.cogs) }}</td>
          </tr>
          <tr>
            <td class="statement-title">লভ্যাংশ</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.incentive) }}</td>
          </tr>
          <tr>
            <td><h5>মোট আপাত আয়</h5></td>
            <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(cement.revenue) }}</td>
          </tr>
          <tr>
            <th colspan="2"><h5 class="text-bold">ব্যয়</h5></th>
          </tr>
          <tr>
            <td class="statement-title">লেবার খরচ</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.labor) }}</td>
          </tr>
          <tr>
            <td class="statement-title">পরিবহন খরচ</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.transport) }}</td>
          </tr>
          <tr>
            <td><h5>মোট ব্যয়</h5></td>
            <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(cement.expense) }}</td>
          </tr>
          <tr>
            <td><h4 class="text-bold">সিমেন্ট: মোট প্রকৃত আয়</h4></td>
            <td>
              <template v-if="cement.income<0">
                <h4 class="bn-number text-bold text-right text-red">({{ AmountService.commaDecimalWithCurrency(cement.income*(-1)) }})</h4>
              </template>
              <template v-else>
                <h4 class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(cement.income) }}</h4>
              </template>
            </td>
          </tr>
        </table>
        </div>
<div class="col-6">
        <table class="table table-bordered">
          <tr>
            <th colspan="2"><h4 class="text-bold">রড</h4></th>
          </tr>
          <tr>
            <th colspan="2"><h5 class="text-bold">আয়</h5></th>
          </tr>
          <tr>
            <td class="statement-title">বিক্রয় আয়</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.sales) }}</td>
          </tr>
          <tr>
            <td class="statement-title">বিক্রিত পণ্য ব্যয়</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.cogs) }}</td>
          </tr>
          <tr>
            <td class="statement-title">লভ্যাংশ</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.incentive) }}</td>
          </tr>
          <tr>
            <td><h5>মোট আপাত আয়</h5></td>
            <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(steel.revenue) }}</td>
          </tr>
          <tr>
            <th colspan="2"><h5 class="text-bold">ব্যয়</h5></th>
          </tr>
          <tr>
            <td class="statement-title">লেবার খরচ</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.labor) }}</td>
          </tr>
          <tr>
            <td class="statement-title">পরিবহন খরচ</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.transport) }}</td>
          </tr>
          <tr>
            <td><h5>মোট ব্যয়</h5></td>
            <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(steel.expense) }}</td>
          </tr>
          <tr>
            <td><h4 class="text-bold">রড: মোট প্রকৃত আয়</h4></td>
            <td>
              <template v-if="steel.income<0">
              <h4 class="bn-number text-bold text-right text-red">({{ AmountService.commaDecimalWithCurrency(steel.income*(-1)) }})</h4>
              </template>
              <template v-else>
                <h4 class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(steel.income) }}</h4>
              </template>
            </td>
          </tr>
        </table>
</div>
      </div>
<!--      <hr>-->
<!--      <hr>-->
<!--      <hr>-->
<!--      <hr>-->
<!--      <hr>-->

<!--      <h3>বিস্তারিত বিবরণী (প্রতিটি লেনদেন)</h3>-->
<!--      <table class="table">-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th colspan="9"><h4 class="text-bold">সিমেন্ট</h4></th>-->
<!--        </tr>-->
<!--          <tr>-->
<!--            <th>তারিখ</th>-->
<!--            <th>প্রোডাক্টের বিবরণ</th>-->
<!--            <th>প্রোডাক্টের বিবরণ</th>-->
<!--            <th>ক্রয় দর</th>-->
<!--            <th>বিক্রয় দর</th>-->
<!--            <th>পরিমান</th>-->
<!--            <th>বিক্রয় আয়</th>-->
<!--            <th>বিক্রিত পণ্য ব্যয়</th>-->
<!--            <th>মোট আপাত আয়</th>-->
<!--          </tr>-->
<!--        </thead>-->
<!--      </table>-->


<!--      <b-table :items="trxList"></b-table>-->
</template>
    </div>
</template>

<script>
import AmountService from "@/services/amountService";
import Datepicker from "vuejs-datepicker";
import DateAdjust from "@/services/DateAdjust";

export default {
  name: "ProductCategoryBasedReportComponent",
  computed: {
    AmountService() {
      return AmountService
    }
  },
  components: { Datepicker},
  data() {
    return {
      visibility: false,
      from: "",
      to: "",
      trxList: [],
      cementList: [],
      steelList: [],
      cementLaborList: [],
      steelLaborList: [],
      allPurchaseList: [],
      cement:{
        sales: 0,
        cogs: 0,
        incentive: 0,
        revenue: 0,
        transport: 0,
        labor: 0,
        expense: 0,
        income: 0
      },
      steel:{
        sales: 0,
        cogs: 0,
        incentive: 0,
        revenue: 0,
        transport: 0,
        labor: 0,
        expense: 0,
        income: 0
      },
      allList: []
    }
  },
  methods:{
    generateReport(){
      let fromDate = DateAdjust.formatDateToTimestamp(this.from, "from")
      let toDate = DateAdjust.formatDateToTimestamp(this.to, "to")
      this.trxList = DateAdjust.printAllTrx()
      this.cement.incentive = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="CEMENT" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)
      this.steel.incentive = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="STEEL" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)
      this.allList = this.trxList.filter(x=>x.status=="in"||x.status=="out"||x.info.id=="exp03"||x.info.id=="exp04")
      this.allPurchaseList = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.status=="in")
      this.cementLaborList = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.info.id=="exp03")
      this.steelLaborList = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.info.id=="exp04")
      this.trxList = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.status=="out")

      this.cementList = this.trxList.filter(x=> x.info.type === "cement")
      this.steelList = this.trxList.filter(x=> x.info.type === "steel")
      this.calculateCementIncomeStatement()
      this.calculateSteelIncomeStatement()
      this.visibility = true
    },
    calculateCementIncomeStatement(){
      this.cement.sales = this.cementList.reduce((sum, x)=> sum+x.amount,0)
      this.cement.cogs = this.cementList.reduce((sum, x)=> sum+(x.quantity * x.purchaseRate),0)
      this.cement.revenue = this.cement.sales - this.cement.cogs + this.cement.incentive

      this.cement.labor = this.allPurchaseList.filter(x=>x.info.type == 'cement').reduce((sum,x)=> sum+x.laborCost,0 ) + this.cementLaborList.reduce((sum,x)=> sum+x.amount,0 )
      this.cement.transport = this.allPurchaseList.filter(x=>x.info.type == 'cement').reduce((sum,x)=> sum+x.transportationCost,0 )
      this.cement.expense = this.cement.labor + this.cement.transport
      this.cement.income = this.cement.revenue - this.cement.expense
    },
    calculateSteelIncomeStatement(){
      this.steel.sales = this.steelList.reduce((sum, x)=> sum+x.amount,0)
      this.steel.cogs = this.steelList.reduce((sum, x)=> sum+(x.quantity * x.purchaseRate),0)
      this.steel.revenue = this.steel.sales - this.steel.cogs + this.steel.incentive

      this.steel.labor = this.allPurchaseList.filter(x=>x.info.type == 'steel').reduce((sum,x)=> sum+x.laborCost,0 ) + this.steelLaborList.reduce((sum,x)=> sum+x.amount,0 )
      this.steel.transport = this.allPurchaseList.filter(x=>x.info.type == 'steel').reduce((sum,x)=> sum+x.transportationCost,0 )
      this.steel.expense = this.steel.labor + this.steel.transport
      this.steel.income = this.steel.revenue - this.steel.expense
    }
  }
}
</script>
<style scoped>
.statement-title{
  font-size: 20px;
  padding-left: 48px;
}
</style>