<template>
  <div class="container-fluid">
    <h2 class="mt-3 mb-3">কাস্টমারের বাকির রিপোর্ট</h2>
    <div class="row">
      <form onsubmit="return false" class="form-inline">
      <label>বাকির পরিমান</label>
      <input class="form-control mx-3" v-model="minLimit" />
      টাকার থেকে বেশী
        <button class="btn btn-primary ml-4" @click="getHigherThan">রিপোর্ট তৈরী করুন</button>
      </form>
    </div>

    <h4 class="text-center my-3 text-bold"><span class="bn-number">{{ AmountService.commaDecimalWithCurrency(minLimitFinal) }}</span> টাকার বেশী বাকি রয়েছে <span class="bn-number">{{ items.length }}</span> জন কাস্টমারের। সর্বমোট পাওনা <span class="bn-number">{{ AmountService.commaDecimalWithCurrency(dueMot) }}</span> টাকা</h4>
    <b-table striped hover :items="items" :fields="fields" :sort-by.sync="sortBy" sort-desc.sync="false">
      <template #cell(limit)="data">
        <b class="bn-number text-danger">{{
            AmountService.commaDecimalWithCurrency(data.item.limit)
          }}</b>
      </template>
      <template #cell(balance)="data">
        <b class="bn-number text-danger" v-if="data.item.balance<0">{{

            AmountService.commaDecimalWithCurrency(data.item.balance*(-1))
          }}</b>
        <b class="bn-number" v-else>{{

            AmountService.commaDecimalWithCurrency(data.item.balance)
          }}</b>
      </template>
      <template #cell(actions)="row">
        <b-link size="sm" class="btn btn-success" variant="success" :to="row.item.link">
          খতিয়ান দেখুন
        </b-link>
      </template>
    </b-table>

<!--    <div class="row mb-3">-->
<!--      <form onsubmit="return false" class="form-inline">-->

<!--        <label>রিপোর্ট শুরুর তারিখ</label>-->
<!--        <datepicker input-class="form-control ml-2 mr-2" v-model="from"-->
<!--                    format="dd MMMM yyyy"></datepicker>-->
<!--      হতে-->
<!--        <datepicker input-class="form-control ml-2 mr-2" v-model="to"-->
<!--                    format="dd MMMM yyyy"></datepicker>-->

<!--        <label>পর্যন্ত</label>-->
<!--        <button class="btn btn-primary ml-4" @click="getList">রিপোর্ট তৈরী করুন</button>-->
<!--      </form>-->

<!--    </div>-->


  </div>

</template>

<script>
// import DateAdjust from "@/services/DateAdjust";
// import Datepicker from 'vuejs-datepicker';
import AmountService from "@/services/amountService";
// import BarChart from "@/components/GraphChartComponents/BarChart.vue";
import Identity from "@/services/DataStructure/Identity";

export default {
  name: "ProfitReportComponent",
  computed: {
    AmountService() {
      return AmountService
    }
  },
  components: {
    // BarChart,
    // Datepicker
  },
  data(){
    return{
      trxInfo:[],
      sortBy: 'balance',
      items:[],
      minLimit: "",
      minLimitFinal: 0,
      dueMot: 0,
      fields:[
        {
          key: 'primaryName',
          label: "নাম (বাংলায়)"
        },
        {
          key: 'secondaryName',
          label: "নাম (ইংরেজি)"
        },
        {
          key: 'address',
          label: "ঠিকানা"
        },
        {
          key: 'mobile',
          label: "মোবাইল"
        },
        {
          key: 'limit',
          label: "বাকি সীমা"
        },
        {
          key: 'balance',
          label: "Balance"
        },
        'actions'],
    }
  },
  methods:{

    loadData(){
      this.trxInfo = JSON.parse(localStorage.getItem('trxInfo'))
      let data = Identity.getIdentitiesByType("customer")
      data.forEach(function(x){
        x.link = "/" + x.type + "/" + x.id
      })
      //this.items = data.filter(x=>x.type === this.type)
      data.map(function (x){
        x.balance = this.getCurrentBalance(x.id)
      }, this)
      data.filter(x=> x.balance<0)
      this.items = data.filter(x=> x.balance<0)
      this.dueMot = this.items.reduce((sum, x)=> sum+x.balance,0)
      this.dueMot = (-1)*this.dueMot
      console.log(data)
    },
    getCurrentBalance(id){
      let trxInfoTemp = this.trxInfo.filter((x)=>x.info.id===id)
      return trxInfoTemp.filter((x)=>x.section==="deposit").reduce((sum,i)=>sum+i.amount,0)-trxInfoTemp.filter((x)=>x.section==="expense").reduce((sum,i)=>sum+i.amount,0)
    },
    getHigherThan(){
      this.loadData()
      // this.items = ""
      this.minLimitFinal = this.minLimit
      let actualValue = (-1) * this.minLimit
      this.items = this.items.filter(x=> x.balance<actualValue)
      this.dueMot = this.items.reduce((sum, x)=> sum+x.balance,0)
      this.dueMot = (-1)*this.dueMot

      console.log(this.items)
    }


    // getPercent(sales, profit){
    //   let s = parseFloat(sales)
    //   let p = parseFloat(profit)
    //   let percent = (p/s)*100
    //   return percent
    // },
    // getList(){
    //   this.chartData.labels =[]
    //   this.chartData.datasets[0].data = []
    //   this.chartData.datasets[1].data = []
    //
    //   let year = new Date(this.from).getFullYear()
    //   let month = new Date(this.from).getMonth()+1
    //   if(month>0 && month<10){
    //     month = "0"+month
    //   }
    //   let date = new Date(this.from).getDate()
    //   if(date>0 && date<10){
    //     date = "0"+date
    //   }
    //   let fromDate = year+"-"+month+"-"+date+"T00:00:00Z"
    //   year = new Date(this.to).getFullYear()
    //   month = new Date(this.to).getMonth()+1
    //   if(month>0 && month<10){
    //     month = "0"+month
    //   }
    //   date = new Date(this.to).getDate()
    //   if(date>0 && date<10){
    //     date = "0"+date
    //   }
    //   let toDate = year+"-"+month+"-"+date+"T23:59:59Z"
    //
    //
    //
    //
    //   console.log("From Date: "+fromDate)
    //   this.from = new Date(fromDate).getTime()
    //   this.to = new Date(toDate).getTime()
    //   this.trxList = DateAdjust.printAllTrx()
    //   this.trxList = this.trxList.filter(x=> x.zz<=this.to && x.zz>=this.from).filter(x=>x.status=="out")
    //   this.profitMot = this.trxList.reduce((sum, x)=> sum+x.profit,0)
    //   this.salesMot = this.trxList.reduce((sum, x)=> sum+x.amount,0)
    //   this.getDateWise()
    // },
    // getDateWise(){
    //
    //   let result = [];
    //   this.trxList.reduce(function(res, value) {
    //     if (!res[value.date]) {
    //
    //       let selfDate = value.date.split("-")
    //       let year = selfDate[2]
    //       let month = selfDate[1]
    //       if(month>0 && month<10){
    //         month = "0"+month
    //       }
    //       let date = selfDate[0]
    //       if(date>0 && date<10){
    //         date = "0"+date
    //       }
    //       let isoDate = year+"-"+month+"-"+date
    //
    //
    //
    //       console.log(isoDate)
    //
    //       let dateItem = new Date(isoDate).getTime();
    //       res[value.date] = { date: value.date, sales: 0, profit: 0, timestamp: dateItem, full: isoDate };
    //       res[value.date].timestamp = new Date(value.date).getTime();
    //       result.push(res[value.date])
    //     }
    //     res[value.date].sales += value.amount;
    //     res[value.date].profit += value.profit;
    //
    //     return res;
    //   }, {});
    //   for(let i = 0; i<result.length-1; i++){
    //     for(let j = i+1; j<result.length; j++){
    //       if(result[i].timestamp>result[j].timestamp){
    //         let temp = result[i].timestamp;
    //         result[i].timestamp = result[j].timestamp
    //         result[j].timestamp = temp
    //       }
    //     }
    //   }
    //
    //   this.dateWise = result;
    //  for(let i = 0 ; i<result.length; i++){
    //     this.chartData.labels.push(result[i].date)
    //
    //    // for(let j = 0 ; j<result.length; j++) {
    //      this.chartData.datasets[0].data.push(result[i].sales)
    //    // }
    //
    //
    //    // for(let k = 0 ; k<result.length; k++) {
    //      this.chartData.datasets[1].data.push(result[i].profit)
    //    // }
    //
    //
    //  }
    //   console.log(this.chartData)
    // }

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>