<template>
    <div class="container-fluid">
      <h2 class="mt-3 mb-3">ট্রাক পারফর্মেন্স রিপোর্ট</h2>
      <div class="row mb-3">
        <form onsubmit="return false" class="form-inline">

          <label>রিপোর্ট শুরুর তারিখ</label>
          <datepicker  :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="from"
                       format="dd MMMM yyyy"></datepicker>
          হতে
          <datepicker  :highlighted="{days: [5]}" input-class="form-control ml-2 mr-2" v-model="to"
                       format="dd MMMM yyyy"></datepicker>

          <label>পর্যন্ত</label>
          <button class="btn btn-primary ml-4" @click="generateReport()">রিপোর্ট তৈরী করুন</button>
        </form>

      </div>

      <table class="table text-center">
        <thead>
          <tr>
            <th>পজিশন</th>
            <th>অর্জন</th>
            <th class="text-left">ট্রাকের তথ্য</th>
            <th>পারফর্মেন্স</th>
            <th>খতিয়ান</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="v in vehicleList" v-bind:key="v.position">
            <td class="bn-number">{{ v.position }} </td>
            <td>
              <template v-if="v.performance>0 && v.position===1">
                <span style="color: #FFD700"><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i></span>
              </template>
              <template v-if="v.performance>0 && v.position===2">
                <span style="color: #C0C0C0"><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i></span>
              </template>
              <template v-if="v.performance>0 && v.position===3">
                <span style="color: #CD7F32"><i class="fas fa-star fa-2x"></i></span>
              </template>
              <template v-if="v.performance<0">
                <span style="color: #ff0000"><i class="fas fa-times fa-lg"></i></span>
              </template>
            </td>
            <td class="vehicle-name text-left">{{ v.primaryName }} ({{ v.secondaryName }})</td>
            <td class="bn-number">
              <i class="fa-solid fa-up-long"></i><i class="fa-solid fa-chevron-up"></i>
              <template v-if="v.performance>=0">
                <span class="text-green text-bold"><i class="fas fa-play fa-rotate-270 mr-3"></i> {{ AmountService.commaDecimalWithCurrency( v.performance ) }}</span>
              </template>
              <template v-else>
                <span class="text-red"><i class="fas fa-play fa-rotate-90 mr-3"></i>({{ AmountService.commaDecimalWithCurrency( v.performance*(-1) ) }})</span>
              </template>

            </td>
            <td>
              <b-link size="sm" class="btn btn-success" variant="success" :to="v.link"  target="_blank">
                খতিয়ান দেখুন <i class="fas fa-external-link-alt"></i>
              </b-link>
              </td>
          </tr>
        </tbody>
      </table>

    </div>
</template>

<script>
import AmountService from "@/services/amountService";
import Datepicker from "vuejs-datepicker";
import DateAdjust from "@/services/DateAdjust";
import Identity from "@/services/DataStructure/Identity";

export default {
  name: "ProfitReportComponent",
  computed: {
    AmountService() {
      return AmountService
    }
  },
  components: { Datepicker},
  data() {
    return {
      from: "",
      to: "",
      trxList: [],
      vehicleList: []
    }
  },
  methods:{
    generateReport(){
      let fromDate = DateAdjust.formatDateToTimestamp(this.from, "from")
      let toDate = DateAdjust.formatDateToTimestamp(this.to, "to")
      this.trxList = DateAdjust.printAllTrx()
      this.trxList = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.info.type==="vehicle")
      this.vehicleList = Identity.getIdentitiesByType("vehicle")
      for(let i = 0; i<this.vehicleList.length; i++){
        this.vehicleList[i].performance = this.processVehicleTrx(this.vehicleList[i].id)
      }
      this.vehicleList.sort((a,b)=>{
        if (a.performance > b.performance) {
          return -1;
        }
      })
      for(let i = 0; i<this.vehicleList.length; i++){
        this.vehicleList[i].position = i+1
      }
      this.vehicleList.forEach(function(x){
        x.link = "/" + x.type + "/" + x.id
      })
    },
    processVehicleTrx(vehicleID){
      let trxInfoTemp = this.trxList.filter((x)=>x.info.id===vehicleID)
      return trxInfoTemp.filter((x) => x.section === "deposit").reduce((sum, i) => sum + i.amount, 0) - trxInfoTemp.filter((x) => x.section === "expense").reduce((sum, i) => sum + i.amount, 0)
    }
  }
}
</script>
<style scoped>
.vehicle-name{
  font-size: 18px;
}
</style>