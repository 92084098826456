<template>
<div class="container-fluid">



        <div class="float-right"><router-link :to="newLink" class="btn btn-primary my-3"> <i class="bi bi-plus-lg"></i> {{label.formTitle}}</router-link></div>
    <h3 class="pt-4 text-bold">{{ globalIdentityNameSuffix }} তালিকা</h3>
  <b-table striped hover :items="items" :fields="fields" :sort-by.sync="sortBy" sort-desc.sync="false">
    <template #cell(limit)="data">
      <b class="bn-number text-danger">{{
          AmountService.commaDecimalWithCurrency(data.item.limit)
        }}</b>
    </template>
    <template #cell(balance)="data">
      <b class="bn-number text-danger" v-if="data.item.balance<0">{{

          AmountService.commaDecimalWithCurrency(data.item.balance*(-1))
        }}</b>
      <b class="bn-number" v-else>{{

          AmountService.commaDecimalWithCurrency(data.item.balance)
        }}</b>
    </template>
  <template #cell(actions)="row">
    <b-link size="sm" class="btn btn-success" variant="success" :to="row.item.link">
      খতিয়ান দেখুন
    </b-link>
  </template>
  </b-table>
</div>
</template>

<script>
import Identity from "@/services/DataStructure/Identity";
import IdentityType from "@/services/DataStructure/IdentityType";
import AmountService from "@/services/amountService";

export default {
  name: "IdentityList",
  props: {
    type: String
  },
  data(){
    return{
      sortBy: 'primaryName',
      items:[],
      fields:[
          {
              key: 'primaryName',
              label: "নাম (বাংলায়)"
          },
          {
              key: 'secondaryName',
              label: "নাম (ইংরেজি)"
          },
          {
              key: 'address',
              label: "ঠিকানা"
          },
        {
          key: 'mobile',
          label: "মোবাইল"
        },
        {
          key: 'limit',
          label: "বাকি সীমা"
        },
        {
          key: 'balance',
          label: "Balance"
        },
          'actions'],
      label:{},
      newLink:"",
        globalIdentityName: "",
        globalIdentityNameSuffix: "",
      trxInfo:[]
    }
  },
  methods:{
    loadData(){
      this.trxInfo = JSON.parse(localStorage.getItem('trxInfo'))
      let data = Identity.getIdentitiesByType(this.type)
      data.forEach(function(x){
        x.link = "/" + x.type + "/" + x.id
      })
      //this.items = data.filter(x=>x.type === this.type)
      data.map(function (x){
        x.balance = this.getCurrentBalance(x.id)
      }, this)

      this.items = data

      console.log(data)
    },
    getCurrentBalance(id){
      let trxInfoTemp = this.trxInfo.filter((x)=>x.info.id===id)
      return trxInfoTemp.filter((x)=>x.section==="deposit").reduce((sum,i)=>sum+i.amount,0)-trxInfoTemp.filter((x)=>x.section==="expense").reduce((sum,i)=>sum+i.amount,0)
    },
    applyIdentityConfig() {
      let identityName;
      let identityNameSuffix;
      switch (this.type) {
        case IdentityType.CUSTOMER:
          identityName = "কাস্টমার";
          identityNameSuffix = "কাস্টমারের";
          break;
        case IdentityType.SUPPLIER:
          identityName = "সাপ্লাইয়ার";
          identityNameSuffix = "সাপ্লাইয়ারের";
          break;
        case IdentityType.VEHICLE:
          identityName = "ট্রাক";
          identityNameSuffix = "ট্রাকের";
          break;
        case IdentityType.CONTRACT:
          identityName = "ঠিকাদারী কাজ";
          identityNameSuffix = "ঠিকাদারী কাজের";
          break;
        case IdentityType.BANK:
          identityName = "ব্যাংক একাউন্ট";
          identityNameSuffix = "ব্যাংক একাউন্টের";
          break;
        case IdentityType.THIRD_PARTY:
          identityName = "থার্ড পার্টি";
          identityNameSuffix = "থার্ড পার্টির";
          break;
        case IdentityType.HARVEST:
          identityName = "ফসল";
          identityNameSuffix = "ফসলের";
          break;
        case IdentityType.EXPENSE:
          identityName = "খরচ";
          identityNameSuffix = "খরচের";
          break;
        case IdentityType.DRAWING:
          identityName = "উত্তোলন";
          identityNameSuffix = "উত্তোলনের";
          break;
        case IdentityType.PROFIT:
          identityName = "লভ্যাংশ";
          identityNameSuffix = "লভ্যাংশের";
          break;
      }
      this.label = {
        formTitle: "নতুন " + identityName + " যোগ করুন",
        primaryName: identityNameSuffix + " নাম(বাংলায়)",
        primaryNamePlaceholder: "এখানে বাংলায় " + identityNameSuffix + " নাম লিখুন...",
        secondaryName: identityNameSuffix + " নাম(ইংরেজীতে)",
        secondaryNamePlaceholder: "এখানে ইংরেজীতে " + identityNameSuffix + " নাম লিখুন...",
        keyword: identityName + " খুঁজে পাওয়ার কিওয়ার্ড",
        keywordPlaceholder: "এখানে " + identityName + " খুঁজে পাওয়ার কিওয়ার্ড লিখুন...",
        address: identityNameSuffix + " ঠিকানা",
        addressPlaceholder: "এখানে " + identityNameSuffix + " ঠিকানা লিখুন",
        mobile: identityNameSuffix + " মোবাইল নং",
        mobilePlaceholder: "এখানে " + identityNameSuffix + " মোবাইল নং লিখুন...",
        limit: identityNameSuffix + " লেনদেন সীমা(লিমিট)",
        limitPlaceholder: "",
        note: "বিশেষ নোট(যদি থাকে)",
        notePlaceholder: "এখানে বিশেষ নোট লিখুন...",
        modalTitle: "নতুন " + identityNameSuffix + " তথ্য সতর্কতার সাথে নিশ্চিত করুন"
      }
      this.globalIdentityName = identityName
        this.globalIdentityNameSuffix = identityNameSuffix
    }
  },
  watch:{
    type(){
      this.loadData()
      this.applyIdentityConfig()
      this.newLink = "/"+this.type+"/add"
    }
  },
  computed: {
    AmountService() {
      return AmountService
    }
  },
  created() {
    this.loadData()
    this.applyIdentityConfig()
    this.newLink = "/"+this.type+"/add"

  }
}
</script>

<style scoped>

</style>