<template>
  <body class="hold-transition sidebar-mini layout-fixed">
  <!-- Site wrapper -->
  <div class="wrapper overflow-hidden">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <router-link to="/daily" class="btn btn-primary">দৈনিক রেজিস্টার খাতা</router-link>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item d-none d-sm-inline-block mr-3">
          <a class="btn btn-primary" @click="backupNow">
            <i class="far fa-save"></i> Save
          </a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <router-link to="/backup" class="btn btn-success">সার্ভার ব্যাক-আপ করুন</router-link>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <a class="nav-link" id="popover-button-sync" href="#" @click="show = !show">
            <i class="fas fa-calculator"></i>
          </a>
          <b-popover :show.sync="show" placement="bottom" target="popover-button-sync" title="ক্যালকুলেটর">
            <CalculatorComponent />
          </b-popover>
        </li>
        <!-- Language Dropdown Menu -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown"  href="#" style="z-index: 2000">
            <i class="flag-icon flag-icon-us"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right p-0">
            <a href="#" class="dropdown-item active">
              <i class="flag-icon flag-icon-us mr-2"></i> English
            </a>
            <a href="#" class="dropdown-item">
              <i class="flag-icon flag-icon-bd mr-2"></i> বাংলা
            </a>
            <a href="#" class="dropdown-item">
              <i class="flag-icon flag-icon-jp mr-2"></i> 日本語
            </a>

          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
            <i class="fas fa-th-large"></i>
          </a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <button @click="logout" class="btn btn-danger">লগ আউট করুন</button>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="#" class="brand-link">
        <span class="brand-text font-weight-light">সোহাগ এন্টারপ্রাইজ</span>
      </a>
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user (optional) -->
        <div class="user-panel mt-3 pb-2 mb-2 d-flex">
          <div class="image">
          </div>
          <div class="info">
            <a href="#" class="d-block text-white">প্রোপ্রাইটর: <strong>এবিএম সোহাগ</strong></a>
            <p class="text-white license-text">লাইসেন্সকৃত সফটওয়্যার</p>
            <p class="text-white expiry">মেয়াদঃ ৩০শে নভেম্বর ২০২৩</p>
          </div>
        </div>


        <!-- Sidebar Menu -->
        <nav class="mt-1">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
            <li class="nav-item">
              <router-link to="/home" class="nav-link">
                <i class="nav-icon fas fa-home"></i>
                <p>
                  মূলপাতা
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/product" class="nav-link">
                <i class="nav-icon fas fa-boxes"></i>
                <p>
                  প্রোডাক্ট
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/customer" class="nav-link">
                <i class="nav-icon fas fa-id-card"></i>
                <p>
                  কাস্টমার
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/supplier" class="nav-link">
                <i class="nav-icon far fa-address-card"></i>
                <p>
                  সাপ্লাইয়ার
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contract" class="nav-link">
                <i class="nav-icon fas fa-tools"></i>
                <p>
                  ঠিকাদারী কাজ
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/vehicle" class="nav-link">
                <i class="nav-icon fas fa-truck-moving"></i>
                <p>
                  ট্রাক
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/bank" class="nav-link">
                <i class="nav-icon fas fa-file-invoice-dollar"></i>
                <p>
                  ব্যাংক একাউন্ট
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/third-party" class="nav-link">
                <i class="nav-icon fas fa-users"></i>
                <p>
                  থার্ড পার্টি
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/harvest" class="nav-link">
                <i class="nav-icon fab fa-pagelines"></i>
                <p>
                  ফসল
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/expense" class="nav-link">
                <i class="nav-icon fas fa-comments-dollar"></i>
                <p>
                  খরচের খাত
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/drawing" class="nav-link">
                <i class="nav-icon far fa-minus-square"></i>
                <p>
                  উত্তোলনের খাত
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/profit" class="nav-link">
                <i class="nav-icon fas fa-percent"></i>
                <p>
                  লভ্যাংশ
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <a to="#" class="nav-link">
                <i class="nav-icon fas fa-file-alt"></i>
                <p>
                  রিপোর্ট
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview" style="display: none;">
                <li class="nav-header">প্রধান রিপোর্ট</li>
                <li class="nav-item">
                  <router-link to="/report/profit" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>বিক্রয় ও লাভের রিপোর্ট</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/income-compact" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>ইনকাম স্টেটমেন্ট</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/vehicle" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>ট্রাক পারফর্মেন্স রিপোর্ট</p>
                  </router-link>
                </li>
                <li class="nav-header">কাস্টমার সংক্রান্ত রিপোর্ট</li>
                <li class="nav-item">
                  <router-link to="/report/customer-receivable" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>কাস্টমারের বাকি রিপোর্ট</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/limit-exceeded-customer-receivable" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>বাকির সীমা অতিক্রান্ত কাস্টমারের বাকি রিপোর্ট</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/customer-balance" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>দৈনিক গড় ব্যালেন্স রিপোর্ট</p>
                  </router-link>
                </li>


                <li class="nav-header">প্রোডাক্ট ভিত্তিক রিপোর্ট</li>
                <li class="nav-item">
                  <router-link to="/report/stock" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>সার্বিক স্টক রিপোর্ট</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/stock-individual" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>প্রোডাক্ট ভিত্তিক স্টক রিপোর্ট</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/product-based" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>একক প্রোডাক্ট ভিত্তিক স্টক রিপোর্ট</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/product/category-wise" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>প্রোডাক্ট ক্যাটাগরি ভিত্তিক আয়</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/statement/purchase" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>ক্রয় বিবরণী</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/statement/sales" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>বিক্রয় বিবরণী</p>
                  </router-link>
                </li>

                <li class="nav-header">দৈনিক রেজিস্টার ভিত্তিক রিপোর্ট</li>

                <li class="nav-item">
                  <router-link to="/report/statement/deposit" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>জমা বিবরণী</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/report/statement/expense" class="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>খরচ বিবরণী</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item">
                <router-link to="/blackbox" class="nav-link">
                    <i class="nav-icon fas fa-user-shield"></i>
                    <p>
                        BlackBox
                    </p>
                </router-link>
            </li>
            <li class="nav-item">
              <button @click="logout" class="btn btn-danger">লগ আউট করুন</button>
            </li>

          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header)
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1>Blank Page</h1>
            </div>
          </div>
        </div>
      </section>-->

      <!-- Main content -->
      <section class="content overflow-auto" style="height: 93vh;">
        <!-- <div class="m-3">
          <h5 class="text-center">সম্মানিত গ্রাহক, আপনার <span class="text-bold"> ডেমো সফটওয়্যারের মেয়াদ গত ৩০ সেপ্টেম্বর ২০২৩ তারিখে শেষ হয়েছে</span>।</h5>
          <h5 class="text-center text-danger text-bold">নিরবিচ্ছিন্ন সফটওয়্যার সেবা পেতে ১২ অক্টোবর ২০২৩ এর মধ্যে সম্পূর্ণ বিল পরিশোধ করতে অনুরোধ করা হলো। <br>অন্যাথায়, ১৫ অক্টোবর ২০২৩ তারিখে সার্ভার এবং তথ্যাবলীসহ সফটওয়্যারটি স্থায়ীভাবে বন্ধ করা হবে। বিস্তারিত জানতে কাস্টমার কেয়ারে যোগাযোগ করুন।</h5>
        </div> -->
          <router-view />

      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper

    <footer class="main-footer">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> 3.2.0
      </div>
      <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights reserved.
    </footer>
    -->
    <!-- Control Sidebar -->
    <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->

  </body>
</template>

<script>
import CalculatorComponent from "@/components/MiscComponents/CalculatorComponent.vue";
import axios from "axios";
export default {
  name: "BlankComponent",
  components: {CalculatorComponent},
  data(){
    return{
      show: false,

    }
  },
  methods:{
    async logout(){
      await this.backupNow()
      localStorage.setItem("trxInfo", "")
      localStorage.setItem("identity", "")
      localStorage.setItem("zer", "")
      localStorage.setItem("product", "")
      this.$router.push("/")
    },
    async backupNow(){
      this.$swal.showLoading();
      let zer = JSON.parse(localStorage.getItem("zer"))
      let identity = JSON.parse(localStorage.getItem("identity"))
      let trxInfo = JSON.parse(localStorage.getItem("trxInfo"))
      let product = JSON.parse(localStorage.getItem("product"))
      let blackBox = JSON.parse(localStorage.getItem("blackBox"))
      await axios.post("https://xxxxxxxxxxxxxxxx.a.run.app/api/update/database",{
        zer: zer,
        identity: identity,
        trxInfo: trxInfo,
        product: product,
        blackBox: blackBox
      },{
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'text/plain'
      }).then((response) => {
        this.$swal.close()
        this.myData = response.data
        if(response.data.status === 'success'){
          this.$swal('তথ্য সংরক্ষণ সফল হয়েছে!', "আপনার তথ্য সফলভাবে সার্ভারে আপলোড হয়েছে", 'success');
        }else{
          this.$swal('বিপদ সংকেত!!!', "তথ্য সংরক্ষণ ব্যর্থ হয়েছে! দয়া করে আবার চেষ্টা করুন...", 'danger');
        }


      })
    }
  }
}
</script>

<style scoped>
.nav-header{
  font-weight: bold;
}
.license-text{
  font-size: 14px;
  margin: 0px;
  font-weight: normal;
}
.expiry{
  font-size: 14px;
  margin: 0px
;
}
</style>