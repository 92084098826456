<template>
  <div class="container-fluid">
    <h2 class="mt-3 mb-3">ইনকাম স্টেটমেন্ট V2</h2>

    <div class="row mb-3">
      <form onsubmit="return false" class="form-inline">

        <label>রিপোর্ট শুরুর তারিখ</label>
        <datepicker :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="from"
                    format="dd MMMM yyyy"></datepicker>
        হতে
        <datepicker :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="to"
                    format="dd MMMM yyyy"></datepicker>

        <label>পর্যন্ত</label>
        <button class="btn btn-primary ml-4" @click="generateReport">রিপোর্ট তৈরী করুন</button>
      </form>

    </div>
    <div class="container">
      <table class="table table-sm">
        <tr class="sub-border-bottom">
          <th colspan="2"><h4 class="text-bold">আয়</h4></th>
        </tr>
        <tr>
          <th colspan="2"><h5 class="text-bold">সিমেন্ট</h5></th>
        </tr>
        <tr>
          <td class="statement-title">বিক্রয় আয়</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.sales)}}</td>
        </tr>
        <tr>
          <td class="statement-title">বিক্রিত পণ্য ব্যয়</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.cogs)}}</td>
        </tr>
        <tr>
          <td class="statement-title">সিমেন্ট লভ্যাংশ</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(cement.incentive)}}</td>
        </tr>
        <tr class="sub-border-bottom">
          <td><h5>সিমেন্ট আপাত আয়</h5></td>
          <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(cement.revenue)}}</td>
        </tr>
        <tr>
          <th colspan="2"><h5 class="text-bold">রড</h5></th>
        </tr>
        <tr>
          <td class="statement-title">বিক্রয় আয়</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.sales)}}</td>
        </tr>
        <tr>
          <td class="statement-title">বিক্রিত পণ্য ব্যয়</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.cogs)}}</td>
        </tr>
        <tr>
          <td class="statement-title">রড লভ্যাংশ</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(steel.incentive)}}</td>
        </tr>
        <tr class="sub-border-bottom">
          <td><h5>রড আপাত আয়</h5></td>
          <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(steel.revenue)}}</td>
        </tr>
        <tr>
          <th colspan="2"><h5 class="text-bold">হার্ডওয়্যার</h5></th>
        </tr>
        <tr>
          <td class="statement-title">বিক্রয় আয়</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(hardware.sales)}}</td>
        </tr>
        <tr>
          <td class="statement-title">হার্ডওয়্যার লভ্যাংশ</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(hardware.incentive)}}</td>
        </tr>
        <tr class="sub-border-bottom">
          <td><h5>হার্ডওয়্যার আপাত আয়</h5></td>
          <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(hardware.incentive)}}</td>
        </tr>

        <tr>
          <th colspan="2"><h5 class="text-bold">অন্যান্য লভ্যাংশ আয়</h5></th>
        </tr>
        <tr>
          <td class="statement-title">ট্রাক লভ্যাংশ ＊</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(profit.truck) }}</td>
        </tr>
        <tr>
          <td class="statement-title">ফসল লভ্যাংশ ＊</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(profit.harvest) }}</td>
        </tr>
        <tr>
          <td class="statement-title">ঠিকাদারী লভ্যাংশ ＊</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(profit.contract) }}</td>
        </tr>
        <tr v-for="p in otherProfitList" v-bind:key="p.name">
          <td class="statement-title">{{ p.name }} লভ্যাংশ ＊</td>
          <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(p.balance) }}</td>
        </tr>
        <tr class="sub-border-bottom">
          <td><h5>মোট অন্যান্য লভ্যাংশ আয়</h5></td>
          <td class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(profit.total) }}</td>
        </tr>
        <tr style="border-bottom: #333333 5px solid">
          <td><h4 class="text-bold">মোট আপাত আয়</h4></td>
          <td>
            <h4 class="bn-number text-bold text-right">{{ AmountService.commaDecimalWithCurrency(revenueTotal) }}</h4>
          </td>
        </tr>
        <tr></tr>
        <tr></tr>


        <tr>
          <th colspan="2"><h4 class="text-bold">ব্যয়</h4></th>
        </tr>
        <tr v-for="expList in expenseList" v-bind:key="expList.name">
          <td class="statement-title">{{ expList.name }}</td>
          <td class="bn-number text-danger">({{ AmountService.commaDecimalWithCurrency(expList.balance) }})</td>
        </tr>
        <tr style="border-bottom: #333333 2px solid">
          <td><h5>মোট ব্যয়</h5></td>
          <td class="bn-number text-bold text-right  text-danger">({{ AmountService.commaDecimalWithCurrency(expenseTotal) }})</td>
        </tr>
        <tr style="border-bottom: #333333 5px solid">
          <td><h4 class="text-bold">মোট প্রকৃত <span v-if="incomeTotal<0">ক্ষতি</span><span v-else>আয়</span></h4></td>
          <td class="bn-number text-bold text-right text-danger" v-if="incomeTotal<0">
            ({{ AmountService.commaDecimalWithCurrency(incomeTotal*(-1)) }})
          </td>
          <td v-else class="bn-number text-bold text-right">
            {{ AmountService.commaDecimalWithCurrency(incomeTotal) }}
          </td>
        </tr>
      </table>

      <table class="table">
        <tr>
          <th colspan="2"><h4 class="text-bold">উত্তোলন</h4></th>
        </tr>
        <tr v-for="expList in drawingList" v-bind:key="expList.name">
          <td class="statement-title">{{ expList.name }}</td>
          <td class="bn-number text-danger">({{ AmountService.commaDecimalWithCurrency(expList.balance) }})</td>
        </tr>
        <tr style="border-bottom: #333333 2px solid">
          <td><h5>মোট উত্তোলন</h5></td>
          <td class="bn-number text-bold text-right  text-danger">({{ AmountService.commaDecimalWithCurrency(drawingTotal) }})</td>
        </tr>
        <tr style="border-bottom: #333333 5px solid">
          <td><h4 class="text-bold">অবশিষ্ট (<span v-if="remaining<0">ক্ষতি</span><span v-else>আয়</span> ー উত্তোলন)</h4></td>
          <td class="bn-number text-bold text-right text-danger" v-if="incomeTotal<0">
            ({{ AmountService.commaDecimalWithCurrency(remaining*(-1)) }})
          </td>
          <td v-else class="bn-number text-bold text-right">
            {{ AmountService.commaDecimalWithCurrency(remaining) }}
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import AmountService from "@/services/amountService";
import DateAdjust from "@/services/DateAdjust";
import Datepicker from "vuejs-datepicker";
import Identity from "@/services/DataStructure/Identity";

export default {
  name: "IncomeStatementCompactComponent",
  components: { Datepicker },
  data(){
    return{
      from: "",
      to: "",
      fromDate: 0,
      toDate: 0,
      trxList: [],
      cement:{
        sales: 0,
        cogs: 0,
        incentive: 0,
        revenue: 0,
      },
      steel:{
        sales: 0,
        cogs: 0,
        incentive: 0,
        revenue: 0,
      },
      hardware:{
        sales: 0,
        incentive: 0
      },
      profit:{
        truck: 0,
        harvest: 0,
        contract: 0,
        total: 0
      },
      otherProfitList:[],
      expenseList: [],
      expenseTotal: 0,
      drawingList: [],
      drawingTotal: 0,
      revenueTotal: 0,
      incomeTotal: 0,
      remaining: 0
    }
  },
  computed: {
    AmountService() {
      return AmountService
    }
  },
  methods:{
    generateReport(){
      this.expenseList = []
      this.expenseTotal = 0
      this.drawingList= []
      this.drawingTotal= 0
      this.trxListInit()
      this.cementReport()
      this.steelReport()
      this.hardwareReport()
      this.profitReport()
      this.expenseReport()
      this.drawingReport()

      this.revenueTotal = this.cement.revenue + this.steel.revenue + this.hardware.incentive + this.profit.total

      this.incomeTotal = this.revenueTotal - this.expenseTotal
      this.remaining = this.incomeTotal - this.drawingTotal
    },
    trxListInit(){
      this.fromDate = DateAdjust.formatDateToTimestamp(this.from, "from")
      this.toDate = DateAdjust.formatDateToTimestamp(this.to, "to")
      this.trxList = DateAdjust.printAllTrx()
      this.trxList = this.trxList.filter(x=> x.zz<=this.toDate && x.zz>=this.fromDate)
    },
    cementReport(){
      let cementList = this.trxList.filter(x=>x.status=="out").filter(x=> x.info.type === "cement")
      this.cement.sales = cementList.reduce((sum, x)=> sum+x.amount,0)
      this.cement.cogs = cementList.reduce((sum, x)=> sum+(x.quantity * x.purchaseRate),0)
      this.cement.incentive = this.trxList.filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="CEMENT" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)
      this.cement.revenue = this.cement.sales - this.cement.cogs + this.cement.incentive
    },
    steelReport(){
      let steelList = this.trxList.filter(x=>x.status=="out").filter(x=> x.info.type === "steel")
      this.steel.sales = steelList.reduce((sum, x)=> sum+x.amount,0)
      this.steel.cogs = steelList.reduce((sum, x)=> sum+(x.quantity * x.purchaseRate),0)
      this.steel.incentive = this.trxList.filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="STEEL" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)
      this.steel.revenue = this.steel.sales - this.steel.cogs + this.steel.incentive
    },
    hardwareReport(){
      this.hardware.sales = this.trxList.filter(x=>x.status=="out").filter(x=> x.info.type === "hardware").reduce((sum, x)=> sum+x.amount,0)
      this.hardware.incentive = this.trxList.filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="HARDWARE" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)
      console.log(this.hardware.sales)
    },
    profitReport(){
      this.profit.truck = this.trxList.filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="TRUCK" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)
      this.profit.contract = this.trxList.filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="CONTRACT" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)
      this.profit.harvest = this.trxList.filter(x=>x.info.secondaryName.split(" ")[x.info.secondaryName.split(" ").length-1]=="HARVEST" && x.info.type=="profit").reduce((sum, x)=> sum+x.amount, 0)

      // Now we are generating other Profit Information
      let defaultProfit = ["CEMENT", "STEEL", "HARDWARE", "TRUCK", "CONTRACT", "HARVEST"]
      let profitList = Identity.getIdentitiesByType("profit")
      let otherProfitList = []
      profitList.forEach((x)=>{
        console.log(x)
        if(!defaultProfit.includes(x.secondaryName.split(" ")[x.secondaryName.split(" ").length-1])){
          otherProfitList.push(x)
        }
      })

      let profitWithBalance = []
      otherProfitList.forEach((x)=>{
        let temp = {
          name: x.primaryName,
          balance: Identity.getIdentityBalanceWithinPeriodByID(x.id, this.from, this.to)
          }
          profitWithBalance.push(temp)
        }
      )
      this.otherProfitList = profitWithBalance
      this.profit.total = this.profit.truck + this.profit.contract + this.profit.harvest + this.otherProfitList.reduce((sum, x)=> sum+x.balance,0)
    },
    expenseReport(){
      let transportCement = this.trxList.filter(x=>x.status=="in" ).filter(x=>x.info.type=="cement").reduce((sum, x)=> sum+x.transportationCost,0)
      let transportSteel = this.trxList.filter(x=>x.status=="in" ).filter(x=>x.info.type=="steel").reduce((sum, x)=> sum+x.transportationCost,0)
      this.expenseList.push({
        name: "সিমেন্ট গাড়ী ভাড়া",
        balance: transportCement
      })
      this.expenseList.push({
        name: "রড গাড়ী ভাড়া",
        balance: transportSteel
      })
      let expenseList = Identity.getIdentitiesByType("expense")
      expenseList.forEach((x)=>{
        let balance = Identity.getIdentityBalanceWithinPeriodByID(x.id, this.from, this.to)*(-1)
        if(x.id=="exp03"){
          let laborCement = this.trxList.filter(x=>x.status=="in" ).filter(x=>x.info.type=="cement").reduce((sum, x)=> sum+x.laborCost,0)
          balance += laborCement
        }
        if(x.id=="exp04"){
          let laborSteel = this.trxList.filter(x=>x.status=="in" ).filter(x=>x.info.type=="steel").reduce((sum, x)=> sum+x.laborCost,0)
          balance += laborSteel
        }
        console.log("### laborCost"+balance)
        let temp;
        if(balance>0){
          temp = {
            name: x.primaryName,
            balance: balance
          }
          this.expenseList.push(temp)
        }
      })
      this.expenseTotal = this.expenseList.reduce((sum, x)=> sum+x.balance,0)
    },

    drawingReport(){
      let drawingList = Identity.getIdentitiesByType("drawing")
      drawingList.forEach((x)=>{
        let balance = Identity.getIdentityBalanceWithinPeriodByID(x.id, this.from, this.to)*(-1)
        let temp;
        if(balance>0){
          temp = {
            name: x.primaryName,
            balance: balance
          }
          this.drawingList.push(temp)
        }
      })
      this.drawingTotal = this.drawingList.reduce((sum, x)=> sum+x.balance,0)
    }
  }
}
</script>

<style scoped>
.sub-border-bottom{
  border-bottom: #000 2px solid;
}
</style>