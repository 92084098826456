<template>
<div>
  <router-link to="/product/add" class="btn btn-primary float-right my-3"> <i class="bi bi-plus-lg"></i> নতুন প্রোডাক্ট যোগ করুন</router-link>
  <h3 class="py-3 text-bold">প্রোডাক্টের তালিকা</h3>
  <b-table striped hover :items="items" :fields="fields">
    <template #cell(limit)="data">
      <b class="bn-number"> {{ AmountService.commaDecimal(data.item.limit) }} </b>
    </template>
    <template #cell(stock)="data">
      <b class="bn-number"> {{ AmountService.commaDecimal(data.item.stock) }} </b>
    </template>
    <template #cell(actions)="row">
      <b-link size="sm" class="btn btn-primary float-center" variant="success" :to="row.item.link">
        তথ্য হালনাগাদ
      </b-link>
    </template>
  </b-table>
  <ol>
    <li v-for="item in items" :key="item.id">
      <h4>{{ item.primaryName }}</h4>
      <h1>{{ stockAnalysis(item.id) }}</h1>
    </li>
  </ol>
</div>
</template>

<script>
import Product from "@/services/DataStructure/Product/Product";
import AmountService from "../../services/amountService";
// import TransactionInfo from "@/services/DataStructure/TransactionInfo";
import StockService from "@/services/stockService";
export default {
  name: "ProductList",
  computed: {
    AmountService() {
      return AmountService
    }
  },
  props: {
    type: String
  },
  data(){
    return{
      items:[],
      fields:[
        {
          key: 'primaryName',
          label: "নাম (বাংলায়)"
        },
        {
          key: 'secondaryName',
          label: "নাম (ইংরেজি)"
        },
        {
          key: 'type',
          label: "প্রোডাক্টের ধরণ"
        },
        {
          key: 'limit',
          label: "স্টক সীমা"
        },
        {
          key: 'stock',
          label: "বর্তমান স্টক"
        },
        {
          key: 'note',
          label: "নোট"
        },
        'actions'],
    }
  },
  methods: {
    stockAnalysis(pid) {
      StockService.stockAnalysis(pid)
  },
    loadData(){
      let data = Product.getProducts()
      let trxInfo = JSON.parse(localStorage.getItem("trxInfo"))

      //this.items = data.filter(x=>x.type === this.type)
      data.map((x)=>{
        let totalIn = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "in")).reduce((sum, i)=>sum+i.quantity,0)
        let totalOut = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "out")).reduce((sum, i)=>sum+i.quantity,0)
        let stock = totalIn - totalOut
        x.type = this.getProductTypeTranslation(x.type)
        x.stock = stock.toFixed(2)
        x.link = "/product/edit/" + x.id
        if(x.limit>stock){
          x._rowVariant= 'danger'
        }else if((stock/x.limit)<1.25){
          x._rowVariant= 'warning'
        }
      })
      this.items = data
    },
    getProductTypeTranslation(productType){
      if(productType==="cement"){
        return "সিমেন্ট"
      }else if(productType==="steel"){
        return "রড"
      }else if(productType==="hardware"){
        return "হার্ডওয়্যার"
      }
    }
  },
  watch:{
    type(){
      this.loadData()
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>