<template>
  <body class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      <h2>সোহাগ এন্টারপ্রাইজ TEST SERVER</h2>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">এখানে লগইন করুন</p>

        <form>
          <div class="input-group mb-3">
            <input v-model="username" class="form-control" placeholder="ইউজারনেম">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input v-model="password" type="password" class="form-control" placeholder="পাসওয়ার্ড">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
        </form>
          <div class="row">
            <!-- /.col -->
            <div class="col">
              <button type="submit" @click="getData" class="btn btn-primary btn-block">লগইন করুন</button>
            </div>
            <!-- /.col -->
          </div>
      </div>
      <!-- /.login-card-body -->
    </div>
    {{ response }}
  </div>
  <!-- /.login-box -->
  </body>
</template>

<script>

//import axios from "axios";
import {auth} from "@/firebase";

export default {
  name: "LoginComponent",
  data(){
    return{
      error: false,
      username: "test@gmail.com",
      password: "12345678",
      response: ""
    }
  },
  methods:{
    async getData(){
      console.log("Started...")
      auth.signInWithEmailAndPassword(this.username,this.password).then((data)=>{
        data.user.getIdToken().then(data=>{
          console.log(data)
        })
      }).catch((error)=>{
        console.log(error)
      })
      // this.$swal.showLoading();
      // if(this.username=="shohug" && this.password=="abc01717556600"){
      //
      //   await axios.get("https://xxxxxxxx.a.run.app/api/getalldata").then((response) => {
      //     localStorage.setItem("trxInfo", JSON.stringify(response.data.trxInfo))
      //     localStorage.setItem("identity", JSON.stringify(response.data.identity))
      //     localStorage.setItem("zer", JSON.stringify(response.data.zer))
      //     localStorage.setItem("product", JSON.stringify(response.data.product))
      //     this.$swal.close();
      //     this.$router.push("/home")
      //   })
      // }else{
      //   this.$swal('দুঃখিত!!!', "আপনি ভুল পাসওয়ার্ড দিয়েছেন! দয়া করে সঠিক পাসওয়ার্ড দিন", 'danger');
      // }
      // this.backupStatus = false
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>