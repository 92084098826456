<template>
  <div>
    <div class="row">
      <div class="col-md-6" >
        <div class="my-custom-class">
          <h5>First Step</h5>
          <p>This is tagline...</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="my-custom-class">
          <h5>Second Step</h5>
          <p>This is tagline...</p>
        </div>
      </div>
    </div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="First" active><p>I'm the first tab</p></b-tab>
        <b-tab title="Second"><p>I'm the second tab</p></b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "WizardComponent",
  methods:{
    showFirst(){

    },
    showLast(){

    }
  }
}
</script>

<style scoped>
.my-custom-class{
  background: aquamarine;
  border-bottom: #1963a3 5px solid;
  padding-top: 6px;
}
p,h5{
  margin-bottom: 0px;
  padding-left: 12px;
}
</style>