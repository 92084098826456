<template>
  <div>
    <h1 class="text-center py-3">সোহাগ এন্টারপ্রাইজ এর সফটওয়্যারে আপনাকে স্বাগতম</h1>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-6">

          <div class="small-box bg-primary">
            <div class="inner">
              <h3 class="bn-number">{{ AmountService.comma(totalCustomer) }}</h3>
              <h4>কাস্টমার</h4>
            </div>
            <div class="icon">
                <i class="fas fa-users"></i>
            </div>
            <router-link to="/customer" class="small-box-footer">
              বিস্তারিত... <i class="fas fa-arrow-circle-right"></i>
            </router-link >
          </div>
        </div>

        <div class="col-lg-4 col-6">

          <div class="small-box bg-warning">
            <div class="inner">
              <h3 class="bn-number">{{ AmountService.comma(totalCustomerWithDue) }}</h3>
              <h4>দেনাদার কাস্টমার</h4>
            </div>
            <div class="icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <router-link to="report/customer-receivable" class="small-box-footer">
              বিস্তারিত... <i class="fas fa-arrow-circle-right"></i>
            </router-link>
          </div>

        </div>

        <div class="col-lg-4 col-6">

          <div class="small-box bg-danger">
            <div class="inner">
              <h3 class="bn-number">{{ AmountService.comma(totalCustomerCrossedLimit) }}</h3>
              <h4>বিপদসীমা অতিক্রান্ত কাস্টমার</h4>
            </div>
            <div class="icon">
              <i class="fas fa-radiation"></i>
            </div>
            <router-link to="/report/limit-exceeded-customer-receivable" class="small-box-footer">
              বিস্তারিত...  <i class="fas fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </div>

        <div class="col-6">

          <div class="small-box bg-warning">
            <div class="inner">
              <h3 class="bn-number">{{ AmountService.commaDecimalWithCurrency(totalReceivable) }}</h3>
              <h4>মোট পাওনা</h4>
            </div>
            <div class="icon">
              <i class="fas fa-hand-holding-usd"></i>
            </div>
            <router-link to="report/customer-receivable" class="small-box-footer">
              বিস্তারিত... <i class="fas fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-6">

          <div class="small-box bg-danger">
            <div class="inner">
              <h3 class="bn-number">{{ AmountService.commaDecimalWithCurrency(totalReceivableFromCrossedLimitCustomer) }}</h3>
              <h4>বিপদসীমা অতিক্রান্ত কাস্টমার হতে পাওনা</h4>
            </div>
            <div class="icon">
              <i class="fas fa-radiation"></i>
            </div>
            <router-link to="/report/limit-exceeded-customer-receivable" class="small-box-footer">
              বিস্তারিত...  <i class="fas fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </div>



      </div>
    </div>


    <b-container>
      <b-row>
        <h3 class="mt-5">প্রোডাক্ট স্টক তথ্য</h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>প্রোডাক্টের নাম</th>
              <th class="text-right">স্টকের পরিমান</th>
              <th class="text-right">ক্রয়মূল্য</th>
            </tr>
          </thead>
          <tbody v-for="(item, i) in stockArray" :key="i">
            <tr>
              <td>{{ item.primaryName }}</td>
              <td class="bn-number text-right">{{ AmountService.commaDecimal(item.stock) }}</td>
              <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(item.avgPurchaseRate) }}</td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DummyRunner from "@/services/DummyData/DummyRunner";
import Identity from "@/services/DataStructure/Identity";
import AmountService from "@/services/amountService";

export default {
  name: "HomeComponent",
  data(){
    return{
      stockArray: [],
      totalCustomer: 0,
      totalCustomerWithDue:0,
      totalCustomerCrossedLimit:0,
      totalReceivable: 0,
      totalReceivableFromCrossedLimitCustomer:0
    }
  },
  computed: {
    AmountService() {
      return AmountService
    },
    DummyRunner() {
      return DummyRunner
    }
  },
  methods:{

    getTotalCustomer(){
      this.totalCustomer = Identity.getIdentitiesByType("customer").length
    },
    getTotalCustomerWithDue(){
      this.trxInfo = JSON.parse(localStorage.getItem('trxInfo'))
      let data = Identity.getIdentitiesByType("customer")
      data.map(function (x){
        x.balance = this.getCurrentBalance(x.id)
      }, this)


      this.totalCustomerWithDue = data.filter(x=> x.balance<0).length
      this.totalReceivable = (-1)*data.filter(x=> x.balance<0).reduce((sum, x)=> sum+x.balance,0)

    },
    getTotalCustomerCrossedLimit(){
      this.trxInfo = JSON.parse(localStorage.getItem('trxInfo'))
      let data = Identity.getIdentitiesByType("customer")
      data.map(function (x){
        x.balance = this.getCurrentBalance(x.id)
      }, this)
      this.totalCustomerCrossedLimit = data.filter(x=> x.balance<((-1)*x.limit)).length
      this.totalReceivableFromCrossedLimitCustomer = (-1)*data.filter(x=> x.balance<((-1)*x.limit)).reduce((sum, x)=> sum+x.balance,0)
    },
    getCurrentBalance(id){
      let trxInfoTemp = this.trxInfo.filter((x)=>x.info.id===id)
      return trxInfoTemp.filter((x)=>x.section==="deposit").reduce((sum,i)=>sum+i.amount,0)-trxInfoTemp.filter((x)=>x.section==="expense").reduce((sum,i)=>sum+i.amount,0)
    },
    getStock(){
      let xcv = [];
      let trxInfo = JSON.parse(localStorage.getItem("trxInfo"))
      let product = JSON.parse(localStorage.getItem("product"))
      product.forEach(function(x){
        let purchaseQtyWeighted = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "in")).map((z)=>parseFloat(z.purchaseRate)*parseFloat(z.quantity)).reduce((sum, i)=>sum+i,0)
        let totalIn = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "in")).reduce((sum, i)=>sum+i.quantity,0)
        let totalOut = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "out")).reduce((sum, i)=>sum+i.quantity,0)
        let stock = totalIn - totalOut
        let avgPurchaseRate = (purchaseQtyWeighted/totalIn)?purchaseQtyWeighted/totalIn:0

        x.stock = stock
        x.avgPurchaseRate = avgPurchaseRate
        xcv.push(x)
        console.log(x.primaryName + " -> IN:" +totalIn+"     OUT:"+totalOut+"    Stock:"+stock+"     WeightedRateQty:"+purchaseQtyWeighted+"     Average_Purchase_Rate:"+avgPurchaseRate)
        // console.log(JSON.stringify(trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "in")).reduce((sum, i)=>sum+i,0)))
      })
      this.stockArray = xcv
    }
  },
  created() {
    this.getStock()
    this.getTotalCustomer()
    this.getTotalCustomerWithDue()
    this.getTotalCustomerCrossedLimit()
  }
}
</script>

<style scoped>

</style>