<template>
  <div id="app">
    <template v-if="currentRoute">
      <BlankComponent />
    </template>
    <template v-else>
      <LoginComponent/>
    </template>


  </div>
</template>

<script>
// import NavigatorHome from "@/components/NavigatorHome.vue";
import BlankComponent from "@/components/BlankComponent.vue";
import LoginComponent from "@/components/Login.vue";

export default {
  name: 'App',
  components: {
    LoginComponent,
    BlankComponent,
    // NavigatorHome
    // HelloWorld,
  },
  created() {

  },
  computed: {
  // Computed property to check if the current route is the same as the specific route
    currentRoute() {
      return this.$route.path!="/"; // Replace '/specific-route' with your specific route
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Kalpurush";
  src: url("./assets/Kalpurush.woff") format("woff");
}
body {
  font-family: "Kalpurush" !important;
}
.sidebar-dark-primary{
background: linear-gradient(120deg, #194a7eff 0%, #1963a3 100%);
}

@font-face {
  font-family: "boishkhi";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Boishakhi"),
  url("./assets/Boishkhi.woff") format("woff");
}
.digit-input{
  font-family: 'boishkhi' !important;
  text-align: end;
}
.bn-number{
  font-family: 'boishkhi' !important;
  font-size: 18px;
  white-space: nowrap;
}
.absolute-button{
  position: absolute;
  top: 0;

}
@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}
span.highlighted{
  background-color: #ff0a0a !important;
  color: #FFFFFF !important;
  font-size: 18px;
  font-weight: 600;
}

.content-wrapper {
  background-color: #ffffff !important;
}
/*}*/
/*body {*/
/*  font-family: 'kalpurushregular', Arial, sans-serif !important;*/
/*}*/
</style>
