<template>
  <div class="container-fluid">
    <h2 class="mt-3 mb-3">সার্বিক স্টক রিপোর্ট</h2>

    <div class="row mb-3">
      <form onsubmit="return false" class="form-inline">
        <label>রিপোর্ট শুরুর তারিখ</label>
        <datepicker :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="from"
                    format="dd MMMM yyyy"></datepicker>
      হতে
        <datepicker :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="to"
                    format="dd MMMM yyyy"></datepicker>

        <label>পর্যন্ত</label>
        <button class="btn btn-primary ml-4" @click="getStockReport">রিপোর্ট তৈরী করুন</button>
      </form>

    </div>

  <table class="table">
    <thead>
    <tr>
      <th> প্রোডাক্টের নাম</th>
      <th class="text-right">পূর্বের স্টক (জের) ( Z )</th>
      <th class="text-right">মোট ক্রয় ( P )</th>
      <th class="text-right">মোট বিক্রয় ( S )</th>
      <th class="text-right">অবশিষ্ট ( Z + P ) - S</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="i in stockList" v-bind:key="i.id">
      <td>{{ i.primaryName }}</td>
        <td class="text-right bn-number">{{ AmountService.commaDecimal(parseFloat(i.previousStock).toFixed(2)) }}</td>
        <td class="text-right bn-number">{{ AmountService.commaDecimal(parseFloat(i.purchaseQuantity).toFixed(2))  }}</td>
      <td class="text-right bn-number">{{ AmountService.commaDecimal(parseFloat(i.saleQuantity).toFixed(2))  }}</td>
      <td class="text-right bn-number">{{ AmountService.commaDecimal((i.previousStock + i.purchaseQuantity) - i.saleQuantity) }}</td>
    </tr>
    </tbody>
  </table>
  </div>

</template>

<script>
import DateAdjust from "@/services/DateAdjust";
import Datepicker from 'vuejs-datepicker';
import AmountService from "@/services/amountService";
import Product from "@/services/DataStructure/Product/Product";

export default {
  name: "StockReportComponent",
  computed: {
    AmountService() {
      return AmountService
    },
    getProductList(){
      let pList = []
      if(this.productList){
        this.productList.forEach((x)=>pList.push({value: x.id, text: x.primaryName + "("+x.secondaryName+")", id: x.id}))
      }
      return pList
    }
  },
  components: { Datepicker},
  data(){
    return{
      productList: [],
      from: "",
      to: "",
      trxList: "",
      stockList: ""

    }
  },
watch:{
    selectedProduct(val){
      this.selectedProductInfo = Product.getProductInfoByID(val)
    }
},
  methods:{
    //get timestamp from full date
    getFullDateToTimeStamp(fullDate){
      let year = new Date(fullDate).getFullYear()
      let month = new Date(fullDate).getMonth()+1
      if(month>0 && month<10){
        month = "0"+month
      }
      let date = new Date(fullDate).getDate()
      if(date>0 && date<10){
        date = "0"+date
      }
      let formattedDate = year+"-"+month+"-"+date+"T00:00:00Z"
      return new Date(formattedDate).getTime()
    },
    getPreviousStockByProduct(productID, fromDate){
      let trxData = DateAdjust.getTimeStampedTrxData()
      let productOnlyData = trxData.filter(x=> x.zz<fromDate).filter(x=>x.type=="stock").filter(x=>x.info.id===productID)

      return parseFloat(productOnlyData.filter(x=>x.status==="in").reduce((sum, x)=> sum+x.quantity,0)) - parseFloat(productOnlyData.filter(x=>x.status==="out").reduce((sum, x)=> sum+x.quantity,0))
    },
    // stockType = "in" or "out" to indicate whether it is sales or purchase; in = purchase, out = sales
    getInPeriodQuantityByProduct(productID, fromDate, toDate, stockType){
      let trxData = DateAdjust.getTimeStampedTrxData()
      let productOnlyData = trxData.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.type=="stock").filter(x=>x.info.id===productID)
      return productOnlyData.filter(x=>x.status===stockType).reduce((sum, x)=> sum+x.quantity,0)
    },

    getStockReport(){
      let fromDate = this.getFullDateToTimeStamp(this.from)
      let toDate = this.getFullDateToTimeStamp(this.to)

      let result = [];

      this.productList.forEach((x)=>{
        let singleProduct = {
          id: x.id,
          primaryName: x.primaryName,
          secondaryName: x.secondaryName,
          previousStock: this.getPreviousStockByProduct(x.id, fromDate),
          purchaseQuantity: this.getInPeriodQuantityByProduct(x.id, fromDate, toDate, "in"),
          saleQuantity: this.getInPeriodQuantityByProduct(x.id, fromDate, toDate, "out")
        }
        result.push(singleProduct)
      })
        this.stockList = result
    }

  },
  created() {
    this.productList = Product.getProducts()
  }
}
</script>

<style scoped>
.bl{
  border-left: 2px solid #000000 !important;
}
.br{
  border-right: 2px solid #000000 !important;
}
.bb{
  border-bottom: 2px solid #000000 !important;
}
.bt{
  border-top: 2px solid #000000 !important;
}
.bb-double{
  border-bottom: 3px double #000000 !important;
}
</style>