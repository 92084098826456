<template>
  <div>
    <h4>{{ primaryName() }} {{ secondaryName() }}</h4>
    <p>{{ address() }} {{ mobile() }}</p>
  </div>
</template>

<script>
export default {
  name: "ModelSelectListRenderer",
  props: {
  text: String
  },
  methods:{
    primaryName(){
      return this.text.split("=")[0]
    },
    secondaryName(){
      let x = this.text.split("=")[1]
      if(x!=undefined){
        return "("+this.text.split("=")[1]+")"
      }else{
        return ""
      }

    },
    address(){
      return this.text.split("=")[3]
    },
    mobile(){
      return this.text.split("=")[4]
    },

  }
}
</script>

<style scoped>
h4{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}
p{
  margin-bottom: 0px;
}
</style>