import { render, staticRenderFns } from "./IdentityProfile.vue?vue&type=template&id=0a5613b4&scoped=true&"
import script from "./IdentityProfile.vue?vue&type=script&lang=js&"
export * from "./IdentityProfile.vue?vue&type=script&lang=js&"
import style0 from "./IdentityProfile.vue?vue&type=style&index=0&id=0a5613b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a5613b4",
  null
  
)

export default component.exports