<template>
  <div class="container">
    <div class="row pt-3">
      <div class="offset-2 col-8">
        <div class="card">
          <div class="card-header">
            <h3>{{ label.formTitle }}</h3>
          </div>
          <div class="card-body">
            <b-form @submit="onSubmit" @reset="resetForm">
              <b-form-row>
                <b-col>
                  <b-form-group
                      :label="label.primaryName">
                    <b-form-input
                        v-model="identityForm.primaryName"
                        type="text"
                        :placeholder="label.primaryNamePlaceholder"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                      :label="label.secondaryName">
                    <b-form-input
                        v-model="identityForm.secondaryName"
                        type="text"
                        :placeholder="label.secondaryNamePlaceholder"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>


              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group :label="label.keyword">
                    <b-form-input
                        v-model="identityForm.keyword"
                        type="text"
                        :placeholder="label.keywordPlaceholder"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                      :label="label.mobile"
                  >
                    <b-form-input
                        v-model="identityForm.mobile"
                        type="text"
                        placeholder="01XXXXXXXXX"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>


              <b-form-group
                  :label="label.address"
              >
                <b-form-textarea
                    id="textarea"
                    v-model="identityForm.address"
                    :placeholder="label.addressPlaceholder"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </b-form-group>



              <b-form-group
                  :label="label.limit"
              >
                <b-form-input
                    v-model="identityForm.limit"
                    type="number"
                    :placeholder="label.limitPlaceholder"
                    required
                ></b-form-input>
              </b-form-group>


              <b-form-group
                  :label="label.note"
              >
                <b-form-textarea
                    id="textarea"
                    v-model="identityForm.note"
                    :placeholder="label.notePlaceholder"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </b-form-group>


              <b-button type="submit" variant="primary" class="mr-3">Submit</b-button>
              <b-button variant="info" class="mr-3" @click="fakeData">Load</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>

            </b-form>
            <b-modal id="identity-confirmation-modal" :title="label.modalTitle" size="lg">
              <IdentityEntryConfirmationModal :labelData="label" :formData="identityForm" />
              <template #modal-footer="{ cancel }">


                <b-button variant="danger" @click="cancel()">
                  <i class="fa fa-times" aria-hidden="true"></i> সংশোধন করুন
                </b-button>

                <b-button variant="success" @click="updateIdentity()">
                  <i class="fa fa-undo" aria-hidden="true"></i>
                  নিশ্চিত করুন
                </b-button>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="col-md-6">

      </div>
    </div>

  </div>
</template>

<script>
import IdentityType from "@/services/DataStructure/IdentityType";
import {faker} from "@faker-js/faker/locale/en_IND";
import Identity from "@/services/DataStructure/Identity";
import IdentityEntryConfirmationModal from "@/components/IdentityComponents/IdentityEntryConfirmationModal.vue";

export default {
  name: "IdentityEdit",
  components: {IdentityEntryConfirmationModal},
  props: {
    type: String,
    id: String
  },
  data() {
    return {
      identityForm: {
        primaryName: "",
        secondaryName: "",
        keyword: "",
        address: "",
        mobile: "",
        limit: "",
        registeredDate: "",
        type: this.type,
        note: ""
      },
      iList: null,
      label: {
        formTitle: "",
        primaryName: "",
        primaryNamePlaceholder: "",
        secondaryName: "",
        secondaryNamePlaceholder: "",
        keyword: "",
        keywordPlaceholder: "",
        address: "",
        addressPlaceholder: "",
        mobile: "",
        mobilePlaceholder: "",
        limit: "",
        limitPlaceholder: "",
        note: ""
      },
      identityInfo: null
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.identityForm.registeredDate = new Date().toString()
      this.$bvModal.show('identity-confirmation-modal');
      // let customer = new Identity(this.identityForm)
      // console.log(customer)
      this.iList = JSON.parse(localStorage.getItem('identity'))
      console.log("this is iList" + this.iList)
    },
    updateIdentity(){
        Identity.updateIdentityByID(this.id, this.identityForm)
      this.$swal('Success!!!', "তথ্য সফলভাবে যোগ হয়েছে", 'success');
      this.$bvModal.hide("identity-confirmation-modal")
      this.resetForm()
        this.$router.push("/"+this.identityForm.type+"/"+this.id)
    },
    resetForm() {
      this.identityForm = {
        primaryName: "",
        secondaryName: "",
        keyword: "",
        address: "",
        mobile: "",
        limit: "",
        registeredDate: "",
        type: this.type,
        note: ""
      }
    },
    fakeData() {
      let name = faker.name.fullName()
      this.identityForm = {
        primaryName: name,
        secondaryName: name,
        keyword: name.toLowerCase(),
        address: "My Address",
        mobile: faker.phone.number(),
        limit: faker.finance.amount(1000, 100000, 0),
        registeredDate: faker.date.recent(),
        type: this.type,
        note: faker.lorem.sentences(2)
      }
    },
    applyIdentityConfig() {
      this.identityForm.type = this.type
      let identityName;
      let identityNameSuffix;
      switch (this.type) {
        case IdentityType.CUSTOMER:
          identityName = "কাস্টমার";
          identityNameSuffix = "কাস্টমারের";
          break;
        case IdentityType.SUPPLIER:
          identityName = "সাপ্লাইয়ার";
          identityNameSuffix = "সাপ্লাইয়ারের";
          break;
        case IdentityType.VEHICLE:
          identityName = "ট্রাক";
          identityNameSuffix = "ট্রাকের";
          break;
        case IdentityType.CONTRACT:
          identityName = "ঠিকাদারী কাজ";
          identityNameSuffix = "ঠিকাদারী কাজের";
          break;
        case IdentityType.BANK:
          identityName = "ব্যাংক একাউন্ট";
          identityNameSuffix = "ব্যাংক একাউন্টের";
          break;
        case IdentityType.THIRD_PARTY:
          identityName = "থার্ড পার্টি";
          identityNameSuffix = "থার্ড পার্টির";
          break;
        case IdentityType.HARVEST:
          identityName = "ফসল";
          identityNameSuffix = "ফসলের";
          break;
        case IdentityType.EXPENSE:
          identityName = "খরচ";
          identityNameSuffix = "খরচের";
          break;
        case IdentityType.DRAWING:
          identityName = "উত্তোলন";
          identityNameSuffix = "উত্তোলনের";
          break;
        case IdentityType.PROFIT:
          identityName = "লভ্যাংশ";
          identityNameSuffix = "লভ্যাংশের";
          break;
      }
      this.label = {
        formTitle: identityNameSuffix + " তথ্য হালনাগাদের ফরম",
        primaryName: identityNameSuffix + " নাম(বাংলায়)",
        primaryNamePlaceholder: "এখানে বাংলায় " + identityNameSuffix + " নাম লিখুন...",
        secondaryName: identityNameSuffix + " নাম(ইংরেজীতে)",
        secondaryNamePlaceholder: "এখানে ইংরেজীতে " + identityNameSuffix + " নাম লিখুন...",
        keyword: identityName + " খুঁজে পাওয়ার কিওয়ার্ড",
        keywordPlaceholder: "এখানে " + identityName + " খুঁজে পাওয়ার কিওয়ার্ড লিখুন...",
        address: identityNameSuffix + " ঠিকানা",
        addressPlaceholder: "এখানে " + identityNameSuffix + " ঠিকানা লিখুন",
        mobile: identityNameSuffix + " মোবাইল নং",
        mobilePlaceholder: "এখানে " + identityNameSuffix + " মোবাইল নং লিখুন...",
        limit: identityNameSuffix + " লেনদেন সীমা(লিমিট)",
        limitPlaceholder: "",
        note: "বিশেষ নোট(যদি থাকে)",
        notePlaceholder: "এখানে বিশেষ নোট লিখুন...",
        modalTitle: "নতুন " + identityNameSuffix + " তথ্য সতর্কতার সাথে নিশ্চিত করুন"
      }
      this.identityForm= {
        primaryName: this.identityInfo.primaryName,
            secondaryName: this.identityInfo.secondaryName,
            keyword: this.identityInfo.keyword,
            address: this.identityInfo.address,
            mobile: this.identityInfo.mobile,
            limit: this.identityInfo.limit,
            type: this.identityInfo.type,
            note: this.identityInfo.note
      }
    }
  },
  computed: {
    formTitle() {
      let ft;
      switch (this.type) {
        case IdentityType.CUSTOMER:
          ft = "Customer Entry Form"
          break;
        case IdentityType.SUPPLIER:
          ft = "Supplier Entry Form"
          break;
      }
      return ft;
    }
  },
  watch: {
    type() {
      this.applyIdentityConfig()
      this.resetForm()
    }
  },
  created() {
    this.identityInfo = Identity.getIdentityByID(this.id)[0]
    this.applyIdentityConfig()
  }
}
</script>

<style scoped>

</style>