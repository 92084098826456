<template>
  <div>
    <b-button v-b-modal.modal-deposit class="btn btn-success btn-sm absolute-button  no-print  float-right font-weight-bold"><i class="bi bi-plus-lg"></i> জমা</b-button>
    <b-modal id="modal-deposit" size="xl" title="জমার খাতা">
      <div class="container">
        <div class="row border-bottom">
          <div class="offset-md-3 col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="4" label="জমার ধরণ নির্বাচন করুন"
                          label-for="input-default">
              <b-form-select v-model="currentSelect" placeholder="ddsfdsfdsfds" :disabled="previewStatus"
                             :options="identityPropDeposit"></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div>
          <div class="row mt-3">
            <div class="col-md-6 col-sm-12">
              <ModelSelect :options="identity"
                            v-model="item"
                            placeholder="এখানে ক্নিক করে নির্বাচন করুন"/>
<!--              <ModelSelectListRenderer :text="item.text" />-->
              <b-form-group
                  label="টাকার পরিমান" class="mt-4">
              <b-form-input class="digit-input"  :readonly="previewStatus" type="number" label="Amount" v-model="amount"> </b-form-input>
              </b-form-group>
              <label for=""></label>
              <b-form-group
                  label="লেনদেনের বিবরণ">
              <b-form-textarea class="form-control"  :readonly="previewStatus" v-model="amountDescription"></b-form-textarea>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12">
              <div v-if="previewStatus">
                <h3 class="text-center h3 text-warning">সতর্কতার সাথে নিশ্চিত করুন</h3>
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>নির্বাচিত তথ্য</td>
                      <td colspan="2"><ModelSelectListRenderer :text="item.text" /></td>
                    </tr>
                    <tr>
                      <td>টাকার পরিমান</td>
                      <td colspan="2"> <p class="bn-number">{{ AmountService.commaDecimalWithCurrency(amount) }}</p>{{ AmountService.toWordCurrency(amount) }}</td>
                    </tr>
                    <tr>
                      <td>লেনদেনের বিবরণ</td>
                      <td colspan="2" class="description-text-break"> {{ amountDescription }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <b-row>
          <b-col>
            <b-button @click="preview" class="float-right button-abc" variant="primary">চেক করুন</b-button>
          </b-col>
          <b-col>
            <div v-if="previewStatus">
              <b-button @click="correction" variant="danger">সংশোধন করুন</b-button>
              <b-button class="float-right" @click="confirm" variant="success">নিশ্চিত করুন</b-button>
            </div>

          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ cancel }">

        <b-button  variant="info" @click="reset()">
          <i class="fa fa-undo" aria-hidden="true"></i>
          রিসেট করুন
        </b-button>
        <b-button variant="danger" @click="cancel()">
          <i class="fa fa-times" aria-hidden="true"></i> বন্ধ করুন
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// const fmt = require('indian-number-format');
// const {toBengaliWord} = require('bengali-number');
import 'vue-search-select/dist/VueSearchSelect.css'
import AmountService from "../../services/amountService";
import ModelSelect from '@/components/ModelSelect/ModelSelect'
import Identity from "@/services/DataStructure/Identity";
import ModelSelectListRenderer from "@/components/MiscComponents/ModelSelectListRenderer";
// /home/asif/Development/Shohug_Enterprise/shohug-enterprise-frontend/node_modules/vue-search-select/dist/VueSearchSelect.css
export default {
  name: 'DepositModal',
  components: {
      ModelSelectListRenderer,
      ModelSelect
  },
  props:{
    identityProp: [],
    identityListProp: [],
  },
  data() {
    return {
      currentSelect: "",
      previewStatus: false,
      amount: "",
      amountDescription: "",
      options: [
        {value: null, text: 'Please select an option'},
        // {value: 'customer', text: 'কাষ্টমারের নামে জমা'},
        // {value: 'supplier', text: 'সাপ্লাইয়ারের নামে জমা'},
        // {value: 'expenses', text: 'দোকান খরচ'},
        // {value: 'stock', text: 'প্রোডাক্ট খরিদ'},
        // {value: 'drawing', text: 'ব্যক্তিগত খরচ'},
      ],
      products: [
        {name: "শাহ্ সিমেন্ট", batch: [570, 565, 575, 590]},
        {name: "বসুন্ধরা সিমেন্ট", batch: [573, 550]},
        {name: "কিং ব্র্যান্ড সিমেন্ট", batch: [560, 565, 590]},
        {name: "স্ক্যান সিমেন্ট", batch: [570, 575, 590, 610, 680, 650, 615]},
        {name: "BSRM রড", batch: [85, 89, 92, 96]},
        {name: "AKS রড", batch: [82]}
      ],
      confirmedData: {},
      item: {
        value: '',
        text: ''
      },
      identity: [],
      selectedIdentity: {},
      identityPropDeposit: []
    }
  },
  methods: {
    preview() {
      this.previewStatus = true;
        this.confirmedData = {
          info: {
            id: this.item.value,
            primaryName: this.selectedIdentity.primaryName,
            secondaryName: this.selectedIdentity.secondaryName,
            mobile: this.selectedIdentity.mobile,
            address: this.selectedIdentity.address,
            type: this.selectedIdentity.type
          },
          amount: parseFloat(this.amount),
          description: this.amountDescription,
        }
    },
    correction() {
      this.confirmedData = null
      this.previewStatus = false;
    },
    confirm() {
      let finalData;
        finalData = this.confirmedData

      this.previewStatus= false
      this.amount= ""
      this.amountDescription= ""
      this.confirmedData = null;
      this.previewStatus = false
      this.$emit('receiveDepositData', finalData);
      console.log("Final Data: "+JSON.stringify(finalData))
      window.open("/"+finalData.info.type+"/"+finalData.info.id)
      // this.$swal('Success!!!', "জমা সফলভাবে যোগ হয়েছে", 'success');
      this.$bvModal.hide("modal-deposit")
    },
    clearNow($event) {
      $event.target.value = ""
    },
    checkEmit() {
      this.$emit('hhhh', 'hello, world!')
    }
  },
  computed: {
    AmountService() {
      return AmountService
    },
    getProductList: function () {
      return this.products.map(function (x, i) {
          return {value: i, text: x.name}
        }
      )
    },
    getNumberInWord: function () {
      // return toBengaliWord(this.grandTotal ? this.grandTotal : 0)
      return this.grandTotal ? this.grandTotal : 0
    }
  },
  watch: {
    quantity: function (val) {
      this.total = val * this.rate
      this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)
    },
    rate: function (val) {
      this.total = val * this.quantity
      this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)
    },
    laborCost: function (){
      this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)

    },
    transportationCost: function() {
      this.grandTotal = parseFloat(this.total) + parseFloat(this.laborCost) + parseFloat(this.transportationCost)

    },
    currentSelect(){
      this.identity = [];
      let specificIdentityList = Identity.getIdentitiesByType(this.currentSelect)
      specificIdentityList.forEach(x=>this.identity.push({value: x.id, text: x.primaryName + "=" +x.secondaryName + "="+ x.keyword + "="+ x.address + "=" + x.mobile, id: x.id}))
    },
    item(){
      let singleIdentityArray = this.identityListProp.filter((x)=>x.id===this.item.id)
      if(singleIdentityArray.length>0){
        this.selectedIdentity = singleIdentityArray[0]
      }else{
        this.selectedIdentity = {}
      }
      console.log("-->")
      // console.log(JSON.stringify(this.selectedIdentity))
      console.log(this.selectedIdentity.id + " " + this.selectedIdentity.primaryName + " " + this.selectedIdentity.secondaryName)
      console.log("<--")

    }
  },
  created(){
    console.log(Identity.getIdentityTypes())
    let ids = Identity.getIdentityTypes()
    ids.forEach(x=>this.options.push({value: x, text: x}))
    this.identityPropDeposit = this.identityProp.filter(x=> x.value!= 'expense')
  }
}
</script>
<style>
.modal-title {
  font-size: 24px;
  /* text-align: center;
  margin-left: auto;
  margin-right: auto; */
}
.description-text-break{
  white-space: unset !important;
}
.button-abc:active {
  color: #333333;
  background-color: #FFFFFF;
}
</style>
