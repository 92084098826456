import { v4 as uuidv4 } from 'uuid';
import ProductType from "@/services/DataStructure/Product/ProductType";
import BlackBox from "@/services/BlackBox";
// import ProductType from "@/services/DataStructure/Product/ProductType";

class Product{


    #_id;
    #_primaryName;
    #_secondaryName;
    #_limit;
    #_note;
    #_type;

    constructor(data) {
        this.id = uuidv4()
        this.primaryName = data.primaryName
        this.secondaryName = data.secondaryName
        this.limit = data.limit
        this.note = data.note
        this.type = data.type
        this.save()
    }

    get id() {
        return this.#_id;
    }

    set id(value) {
        this.#_id = value;
    }

    get primaryName() {
        return this.#_primaryName;
    }

    set primaryName(value) {
        this.#_primaryName = value;
    }

    get secondaryName() {
        return this.#_secondaryName;
    }

    set secondaryName(value) {
        this.#_secondaryName = value;
    }

    get limit() {
        return this.#_limit;
    }

    set limit(value) {
        this.#_limit = value;
    }

    get note() {
        return this.#_note;
    }

    set note(value) {
        this.#_note = value;
    }

    get type() {
        return this.#_type;
    }

    set type(value) {
        this.#_type = value;
    }

    save(){
        let productData =  {
            id: this.id,
            primaryName: this.primaryName,
            secondaryName: this.secondaryName,
            limit: this.limit,
            type: this.#_type,
            note: this.#_note
        }
        if(!localStorage.getItem('product')){
            let newProduct = [];
            newProduct.push(productData)
            localStorage.setItem('product', JSON.stringify(newProduct))
        }else{
            let oldProduct = JSON.parse(localStorage.getItem('product'))
            oldProduct.push(productData)
            localStorage.setItem('product', JSON.stringify(oldProduct))
        }
        let blackBox = {
            title: "New "+this.type+" Added via Form",
            msg: productData.primaryName + " " + productData.secondaryName,
            info: productData
        }
        BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
    }

    static save(data){
        data.id = uuidv4()
        if(!localStorage.getItem('product')){
            let newProduct = [];
            newProduct.push(data)
            localStorage.setItem('product', JSON.stringify(newProduct))
        }else{
            let oldProduct = JSON.parse(localStorage.getItem('product'))
            if(oldProduct.filter((x)=>x.primaryName===data.primaryName).length===0){
                oldProduct.push(data)
                localStorage.setItem('product', JSON.stringify(oldProduct))
            }
        }
    }

    static getProductTypes(){

        let typesArray = [];
        for (const [key, value] of Object.entries(ProductType)) {
            typesArray.push(value)
            key.toLowerCase()
        }
        return typesArray
    }

    static getProducts(){
        let productData = JSON.parse(localStorage.getItem('product'))
        return productData;
    }

    static getProductInfoByID(id){
        let productData = JSON.parse(localStorage.getItem('product')).filter(x=>x.id ===id)
        return productData[0];
    }

    static getProductsByType(type){
        let productData = JSON.parse(localStorage.getItem('product')).filter(x=>x.type === type)
        return productData;
    }

    static updateProductByID(id, data){
        console.log("Data Info: "+id+" ->"+data.primaryName);
        let product = JSON.parse(localStorage.getItem('product'))
        product.forEach(function (val, index){
            if(val.id===id){
                product[index] = data
                product[index].id = id
            }
            // console.log(val)
        })
        localStorage.setItem('product', JSON.stringify(product))
        let blackBox = {
            title: "Update Product "+data.type+" Information",
            msg: data.primaryName + " " + data.secondaryName,
            info: data
        }
        BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
    }


}

export default Product;