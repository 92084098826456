const IdentityType = {
    CUSTOMER: "customer",
    SUPPLIER: "supplier",
    VEHICLE: "vehicle",
    CONTRACT: "contract",
    BANK: "bank",
    THIRD_PARTY: "third-party",
    HARVEST: "harvest",
    EXPENSE: "expense",
    DRAWING: "drawing",
    PROFIT: "profit"
}

export default IdentityType;