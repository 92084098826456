<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="text-center border-bottom pb-2">


          
        <!--  <p class="p-0 m-0">বিসমিল্লাহির রাহমানির রাহিম</p>
          <h2 class="p-0 m-0">সোহাগ এন্টারপ্রাইজ</h2>
          <h5 class="p-0">হাউসপুর, আলমডাঙ্গা, চুয়াডাঙ্গা।</h5> -->
        </div>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col cols="8">
        <b-form inline>
          <label class="mr-2" for="inline-form-input-name">ইংরেজী তারিখ:</label>
          <datepicker :highlighted="{days: [5]}" input-class="form-control" v-model="selectedDate"
                      format="dd MMMM yyyy"></datepicker>
        </b-form>

      </b-col>
      <b-col>
        রোজ: <b>{{ days[new Date(selectedDate).getDay()] }}</b>
      </b-col>
    </b-row>
    <b-row v-if="selectedDate">
      <b-col class="border" sm="12" md="12" lg="4">
        <h4 class="text-center">জমা</h4>
        <DepositModal :identityProp="identity" :identityListProp="identityList"
                      @receiveDepositData="processDepositData"/>
        <table class="table table-bordered table-sm ">
          <tr class="last-row">
            <td>জের</td>
            <td><input v-model="zer" class="form-control digit-input" /></td>
          </tr>
          <tbody v-for="(item, i) in trxInfo" :key="i">
<template v-if="item.section=='deposit'">
          <tr class="last-row">
            <td><router-link class="link-profile" :to="getProfileLink(item.info.type,item.info.id)"> {{ item.info.primaryName }} </router-link>  - <i> {{ item.info.address}} </i><button @click="deleteItem(item)" class="btn btn-sm btn-danger float-right"><span class="fas fa-times"></span></button></td>
            <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(item.amount) }}</td>
          </tr>
</template>

          </tbody>
          <tfoot class="text-right">
          <tr>
            <td>সর্বমোট জমা</td>
            <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(depositTotal) }}</td>
          </tr>
          <tr>
            <td>(<i class="bi bi-plus-lg"></i>) সর্বমোট বিক্রয়</td>
            <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(saleTotal) }}</td>
          </tr>
          <tr>
            <td>জমা <i class="bi bi-plus-lg"></i> বিক্রয়</td>
            <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(depositWithSalesTotal) }}</td>
          </tr>
          <tr>
            <td>(<i class="bi bi-dash-lg"></i>) বাদ খরচ</td>
            <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(expenseTotal) }}</td>
          </tr>
          <tr>
            <td>(জমা <i class="bi bi-plus-lg"></i> বিক্রয় <i class="bi bi-dash-lg"></i> বাদ খরচ) আজকের জের</td>
            <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(todaySummaryTotal) }}</td>
          </tr>
          </tfoot>
        </table>
      </b-col>


      <b-col class="border">
        <h4 class="text-center">খরচ</h4>
        <ExpenseModal :identityProp="identity" :identityListProp="identityList" :productListProp="productList"  @receiveModalData="processExpenseData"></ExpenseModal>
        <table class="table table-bordered table-sm">

          <tbody v-for="(item, i) in trxInfo" :key="i">
          <template v-if="item.status=='in'">
            <tr>
              <td colspan="2">প্রোডাক্ট খরিদ: <b>{{ item.info.primaryName }}</b><button @click="deleteItem(item)" class="btn btn-sm btn-danger float-right"><span class="fas fa-times"></span></button></td>
            </tr>
            <tr>
              <td>দর <span class="bn-number">{{ AmountService.commaDecimalWithCurrency(item.purchaseRate) }}</span> x
                পরিমান <span class="bn-number">{{ AmountService.comma(item.quantity) }} </span></td>
              <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(item.total) }}</td>
            </tr>
            <tr>
              <td>লেবার খরচ</td>
              <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(item.laborCost) }}</td>
            </tr>
            <tr class="last-row">
              <td>গাড়িভাড়া</td>
              <td class="bn-number text-right">{{
                  AmountService.commaDecimalWithCurrency(item.transportationCost)
                }}
              </td>
            </tr>
          </template>

          <template v-else>
            <template v-if="item.section=='expense'">
            <tr class="last-row">
              <td><router-link class="link-profile" :to="getProfileLink(item.info.type,item.info.id)"> {{ item.info.primaryName }} </router-link> - <i> {{ item.info.address}} </i><button @click="deleteItem(item)" class="btn btn-sm btn-danger float-right"><span class="fas fa-times"></span></button></td>
              <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(item.amount) }}</td>
            </tr>
            </template>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td>সর্বমোট খরচ</td>
            <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(expenseTotal) }}</td>
          </tr>
          </tfoot>
        </table>

      </b-col>



      <b-col class="border">
        <h4 class="text-center">বিক্রয়</h4>
      <SaleModal @receiveSaleData="processSaleData" :productListProp="productList"/>
        <table class="table table-sm table-bordered">
          <tbody>
          <tr>
            <td colspan="2"><h5 class="font-weight-bold"> সিমেন্ট </h5></td>
          </tr>

          <tr v-for="(item, i) in trxInfo.filter(v=>(v.info.type === 'cement') && (v.status === 'out'))" :key="i">
            <td>{{ item.info.primaryName }}: দর <span
                class="bn-number">{{ AmountService.commaDecimalWithCurrency(item.saleRate) }}</span> x পরিমান <span
                class="bn-number">{{ AmountService.commaDecimal(item.quantity) }}</span><button @click="deleteItem(item)" class="btn btn-sm btn-danger float-right"><span class="fas fa-times"></span></button></td>
            <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(item.amount) }}</td>
          </tr>
          <tr>
            <td class="text-right">মোট সিমেন্ট বিক্রয়</td>
            <td class="text-right bn-number font-weight-bold">{{ AmountService.commaDecimalWithCurrency(cementTotal) }}</td>
          </tr>
          <tr>
            <td colspan="2"><h5 class="font-weight-bold"> রড</h5></td>
          </tr>

          <tr v-for="(item, j) in trxInfo.filter(v=>(v.info.type === 'steel') && (v.status === 'out'))" :key="j">
            <td>{{ item.info.primaryName }}: দর <span
                class="bn-number">{{ AmountService.commaDecimalWithCurrency(item.saleRate) }}</span> x পরিমান <span
                class="bn-number">{{ AmountService.commaDecimal(item.quantity) }}</span><button @click="deleteItem(item)" class="btn btn-sm btn-danger float-right"><span class="fas fa-times"></span></button></td>
            <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(item.amount) }}</td>
          </tr>
          <tr>
            <td class="text-right">মোট রড বিক্রয়</td>
            <td class="text-right bn-number font-weight-bold">{{
                AmountService.commaDecimalWithCurrency(steelTotal)
              }}
            </td>
          </tr>
          <tr>
            <td colspan="2"><h5 class="font-weight-bold"> হার্ডওয়ার </h5></td>
          </tr>
          <tr v-for="(item, k) in trxInfo.filter(v=>(v.info.type === 'hardware') && (v.status === 'out'))" :key="k">
            <td>{{ item.info.primaryName }}<button @click="deleteItem(item)" class="btn btn-sm btn-danger float-right"><span class="fas fa-times"></span></button></td>
            <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(item.amount) }}</td>
          </tr>
          <tr>
            <td class="text-right">মোট হার্ডওয়ার বিক্রয়</td>
            <td class="text-right bn-number font-weight-bold">{{
                AmountService.commaDecimalWithCurrency(hardwareTotal)
              }}
            </td>
          </tr>
          <tr>
            <td class="text-right font-weight-bold">মোট সিমেন্ট + রড + হার্ডওয়ার বিক্রয়</td>
            <td class="text-right bn-number font-weight-bold">{{
                AmountService.commaDecimalWithCurrency(saleTotal)
              }}
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-sm table-bordered">
          <thead>
          <th colspan="2">আজকের বিক্রয়ের সারমর্ম:</th>
          </thead>
          <tbody>
          <tr>
            <td>সর্বমোট বিক্রয়</td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(saleTotal) }}</td>
          </tr>
          <tr>
            <td>সর্বমোট বিক্রয় (কথায়)</td>
            <td>{{ AmountService.toWordCurrency(saleTotal) }}</td>
          </tr>
          <tr>
            <td>বিক্রয় হতে লাভ</td>
            <td class="bn-number font-weight-bold">{{ AmountService.commaDecimalWithCurrency(profitTotal) }}</td>
          </tr>
          </tbody>

        </table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import ExpenseModal from '../components/DailyRegistryComponents/ExpenseModal.vue';
import AmountService from "../services/amountService";
import SaleModal from "@/components/DailyRegistryComponents/SaleModal.vue";
import DepositModal from "@/components/DailyRegistryComponents/DepositModal.vue";
import Identity from "@/services/DataStructure/Identity";
import IdentityType from "@/services/DataStructure/IdentityType";
import Datepicker from 'vuejs-datepicker';
import {v4 as uuidv4} from "uuid";
import TransactionInfo from "@/services/DataStructure/TransactionInfo";
import Product from "@/services/DataStructure/Product/Product";
import BlackBox from "@/services/BlackBox";

export default {

  data() {
    return {
      zer: "",
      selectedDate: "",
      selectedDateFormatted: "",
      identity: [],
      identityList: [],
      productList: [],
      trxInfo: [],
      masterTrxInfo: [],
      sales: [],
      days: [
        'রবিবার',
        'সোমবার',
        'মঙ্গলবার',
        'বুধবার',
        'বৃহস্পতিবার',
        'শুক্রবার',
        'শনিবার'
      ],
      fridayOff : {
        highlighted: {
          days: [5], // Highlight Friday
        }
      }
    };
  },
  components: {
    DepositModal,
    SaleModal,
    ExpenseModal,
    Datepicker
  },
  methods: {
  deleteItem(item){
    let localLoc = this.trxInfo.findIndex(x=>x.id === item.id)
    console.log("trxInfo [index]:"+localLoc)
    let masterLoc = this.masterTrxInfo.findIndex(x=>x.id === item.id)
    console.log("masterTrxInfo [index]:"+masterLoc)
    let trxDeletePassword = prompt("আপনি কি এই লেনদেন মুছে দিতে নিশ্চিত? নিশ্চিত হলে পাসওয়ার্ড প্রবেশ করান")
    if(trxDeletePassword==="556600"){
      this.trxInfo.splice(localLoc,1)
      this.masterTrxInfo.splice(masterLoc,1)
      TransactionInfo.addTrxInfo(this.masterTrxInfo)
    }
  },
  getProfileLink: function(type,id){
    return "/"+type+"/"+id
  },
    // Need to refactor
    processExpenseData(data) {
      data.date = this.selectedDateFormatted
      data.section = 'expense'
      data.id = uuidv4()
      console.log("Expense-->")
      console.log(JSON.stringify(data))
      this.trxInfo.push(data)
      this.masterTrxInfo.push(data)
      TransactionInfo.addTrxInfo(this.masterTrxInfo)
        let blackBox = {
            title: "New Expense Added",
            msg: data.date + " -> " + data.info.primaryName + "("+data.info.secondaryName+")",
            info: data
        }
        BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
    },

    // Need to refactor
    processSaleData(data) {
      data.date = this.selectedDateFormatted
      data.section = 'sale'
      data.id = uuidv4()
      console.log("Sale-->")
      console.log(JSON.stringify(data))
      this.trxInfo.push(data)
      this.masterTrxInfo.push(data)
      TransactionInfo.addTrxInfo(this.masterTrxInfo)

        let blackBox = {
            title: "New Sales Added",
            msg: data.date + " -> " + data.info.primaryName + "("+data.info.secondaryName+")",
            info: data
        }
        BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
    },


    processDepositData(data) {
      data.date = this.selectedDateFormatted
      data.section = 'deposit'
      data.id = uuidv4()
      console.log("Deposit-->")
      console.log(JSON.stringify(data))
      this.trxInfo.push(data)
      this.masterTrxInfo.push(data)
      TransactionInfo.addTrxInfo(this.masterTrxInfo)

      let blackBox = {
          title: "New Deposit Added",
          msg: data.date + " -> " + data.info.primaryName + "("+data.info.secondaryName+")",
          info: data
      }
      BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)


      //
      // localStorage.setItem('deposit', JSON.stringify(this.masterDeposit))
    },

    calculateTotal: function (arr) {
      if (arr.length > 0) {
        return arr.reduce((p, a) => p + parseFloat(a.amount), 0)
      } else {
        return 0
      }
    },
    calculateProfit() {
      console.log(this.trxInfo.filter(x=>x.date===this.selectedDateFormatted && x.status==="out"))
      return this.trxInfo.filter(x=>x.date===this.selectedDateFormatted && x.status==="out").reduce((sum, v) => sum + v.profit, 0);
    },

    processIdentityOption(identityType) {

      identityType.forEach(x => {

        let optionText;
        switch (x) {
          case IdentityType.CUSTOMER:
            optionText = "কাস্টমার";
            break;
          case IdentityType.SUPPLIER:
            optionText = "সাপ্লাইয়ার";
            break;
          case IdentityType.VEHICLE:
            optionText = "ট্রাক";
            break;
          case IdentityType.CONTRACT:
            optionText = "ঠিকাদারী কাজ";
            break;
          case IdentityType.BANK:
            optionText = "ব্যাংক একাউন্ট";
            break;
          case IdentityType.THIRD_PARTY:
            optionText = "থার্ড পার্টি";
            break;
          case IdentityType.HARVEST:
            optionText = "ফসল";
            break;
          case IdentityType.EXPENSE:
            optionText = "খরচের তালিকা";
            break;
          case IdentityType.DRAWING:
            optionText = "ব্যক্তিগত উত্তোলনের তালিকা";
            break;
          case IdentityType.PROFIT:
            optionText = "লভ্যাংশের তালিকা";
            break;

        }
        this.identity.push({value: x, text: optionText})
      });

    }
  },
  mounted() {

  },
  created() {
    this.processIdentityOption(Identity.getIdentityTypes())
    this.identityList = Identity.getIdentities()
    this.productList = Product.getProducts()
    this.masterTrxInfo = TransactionInfo.getTrxInfo()
  },
  watch: {
    selectedDate(val) {
      let sDate = new Date(val)
      this.selectedDateFormatted = sDate.getDate() + "-" + (sDate.getMonth() + 1) + "-" + sDate.getFullYear()
      console.log(this.selectedDateFormatted)
      console.log(JSON.stringify(this.trxInfo))
      this.trxInfo = this.masterTrxInfo.filter(x=>x.date === this.selectedDateFormatted)

      //zer functionality
      let zerArray = JSON.parse(localStorage.getItem("zer"))
      if(zerArray) {
        const pos = zerArray.map(e => e.date).indexOf(this.selectedDateFormatted)
        if(pos===-1){
          this.zer = ""
        }else{
          this.zer = zerArray[pos].zer
        }
      }
    },
    zer(){
      let zerArray = JSON.parse(localStorage.getItem("zer"))
      if(zerArray){
        const pos = zerArray.map(e => e.date).indexOf(this.selectedDateFormatted)
        if(pos===-1){
          zerArray.push({date: this.selectedDateFormatted, zer: this.zer})
          localStorage.setItem("zer", JSON.stringify(zerArray))
        }else{
          zerArray[pos].zer = this.zer
          localStorage.setItem("zer", JSON.stringify(zerArray))
        }
      }else{
        localStorage.setItem("zer", JSON.stringify([{date: this.selectedDateFormatted, zer: this.zer}]))
      }
    }
  },
  computed: {
    AmountService() {
      return AmountService
    },
    expenseTotal: function () {
      return this.calculateTotal(this.trxInfo.filter(x=>x.section==='expense'))
    },
    cementTotal: function () {
      return this.calculateTotal(this.trxInfo.filter(v => (v.info.type === "cement") && (v.status === 'out')))
    },
    steelTotal: function () {
      return this.calculateTotal(this.trxInfo.filter(v => (v.info.type === "steel") && (v.status === 'out')))
    },
    hardwareTotal: function () {
      return this.calculateTotal(this.trxInfo.filter(v => (v.info.type === "hardware") && (v.status === 'out')))
    },
    saleTotal: function () {
      return this.calculateTotal(this.trxInfo.filter(v => v.status === "out"))
    },
    profitTotal() {
      return this.calculateProfit().toFixed(2)
    },
    depositTotal: function () {
      console.log(parseInt(this.zer))
      return this.calculateTotal(this.trxInfo.filter(x=>x.section==='deposit'))+(parseInt(this.zer) || 0)
    },
    depositWithSalesTotal: function () {
      return this.calculateTotal(this.trxInfo.filter(x=>x.section==='deposit')) + this.calculateTotal(this.trxInfo.filter(v => v.status === "out")) + (parseInt(this.zer) || 0)
    },
    todaySummaryTotal: function () {
      return this.calculateTotal(this.trxInfo.filter(x=>x.section==='deposit')) + this.calculateTotal(this.trxInfo.filter(v => v.status === "out")) - this.calculateTotal(this.trxInfo.filter(x=>x.section==='expense'))+(parseInt(this.zer) || 0)
    },
    getDepositSection: function(){
      return this.trxInfo.filter(x=>x.section==='deposit')
    }

  }
};
</script>
<style>
.last-row {
  border-bottom: #000000 solid 2px;
}

.date-control {
  width: 20% !important;
}
.link-profile{
  color: black;
}
.link-profile:hover{
  color: black;
}
</style>
