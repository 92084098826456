<template>
  <section class="content">
    <div class="container-fluid">
        <WizardComponent/>

<div class="row">
  <div class="col-2">
    <datepicker :highlighted="{days: [5]}"  input-class="form-control" v-model="from"
                format="dd MMMM yyyy"></datepicker>
  </div>
  <div class="col-2">
    <datepicker :highlighted="{days: [5]}"  input-class="form-control" v-model="to"
                format="dd MMMM yyyy"></datepicker>
  </div>
</div>
{{ from }}
        <button @click="getList">Get Trx List</button>
<!--        <ul v-for="i in trxList" v-bind:key="i.id">-->
<!--            <pre><li>{{ i }}</li></pre>-->
<!--        </ul>-->
<table class="table">
  <thead>
    <tr>
      <th>Date</th>
      <th>Product Name</th>
      <th>Purchase Rate</th>
      <th>Sale Rate</th>
      <th>Quantity</th>
      <th>Total Sale</th>
      <th>Profit</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="i in trxList" v-bind:key="i.id">
      <td>{{ i.date }}</td>
      <td>{{ i.info.primaryName }}</td>
      <td class="bn-number">{{ AmountService.commaDecimalWithCurrency( i.purchaseRate )}}</td>
      <td class="bn-number">{{ AmountService.commaDecimalWithCurrency( i.saleRate ) }}</td>
      <td class="bn-number">{{  i.quantity }}</td>
      <td class="bn-number">{{ AmountService.commaDecimalWithCurrency( i.amount ) }}</td>
      <td class="bn-number">{{ AmountService.commaDecimalWithCurrency( i.profit.toFixed(2) ) }}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="bn-number fw-bold">{{ AmountService.commaDecimalWithCurrency(salesMot.toFixed(2))}}</td>
      <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(profitMot.toFixed(2))}}</td>
    </tr>
  </tfoot>
</table>
      <h1></h1>
      <h2></h2>
      <ul v-for="i in trxList" v-bind:key="i.id">
        <pre><li>{{ i }}</li></pre>
      </ul>


{{ trxList }}
    </div><!-- /.container-fluid -->
  </section>
</template>

<script>
import WizardComponent from "@/components/WizardComponents/WizardComponent.vue";
import DateAdjust from "@/services/DateAdjust";
import Datepicker from 'vuejs-datepicker';
import AmountService from "../services/amountService";

export default {
  name: "TestComponent",
  computed: {
    AmountService() {
      return AmountService
    }
  },
  components: {WizardComponent, Datepicker},
    data(){
      return{
          trxList: [],
          from: '',
          to: '',
        profitMot: 0.00,
        salesMot: 0.00
      }
    },
  methods:{
    getList(){
      let year = new Date(this.from).getFullYear()
      let month = new Date(this.from).getMonth()+1
      if(month>0 && month<10){
        month = "0"+month
      }
      let date = new Date(this.from).getDate()
      if(date>0 && date<10){
        date = "0"+date
      }
      let fromDate = year+"-"+month+"-"+date+"T00:00:00Z"
      year = new Date(this.to).getFullYear()
      month = new Date(this.to).getMonth()+1
      if(month>0 && month<10){
        month = "0"+month
      }
      date = new Date(this.to).getDate()
      if(date>0 && date<10){
        date = "0"+date
      }
      let toDate = year+"-"+month+"-"+date+"T23:59:59Z"




      console.log("From Date: "+fromDate)
      this.from = new Date(fromDate).getTime()
      this.to = new Date(toDate).getTime()
        this.trxList = DateAdjust.printAllTrx()
      this.trxList = this.trxList.filter(x=> x.zz<=this.to && x.zz>=this.from).filter(x=>x.status=="out")
      this.profitMot = this.trxList.reduce((sum, x)=> sum+x.profit,0)
      this.salesMot = this.trxList.reduce((sum, x)=> sum+x.amount,0)
    }
  }
}
</script>

<style scoped>

</style>