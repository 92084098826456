import DateAdjust from "@/services/DateAdjust";
export default {
    stockAnalysis(pid) {
        let productStock = DateAdjust.getTimeStampedTrxData().filter(x => x.info.id === pid).filter(x => x.type === "stock")
        productStock.sort(function (a, b) {
            return a["zz"] - b["zz"];
        });
        let priceQueue = [];
        let qtyQueue = [];
        // console.log(productStock)

        // let abc = [{name: "asif", age: 27}, {name: "sadiq", age: 17}, {type: "student"}]
        //
        for (const trx of productStock) {
            if (trx.status === "in") {
                let qty = parseFloat(trx.quantity)
                let purchase = parseFloat(trx.purchaseRate)
                priceQueue.push(purchase);
                qtyQueue.push(qty);

                // let priceQueueTemp = priceQueue.filter( x=>x )
                // let qtyQueueTemp = qtyQueue.filter( x=>x )
                //
                // let stockPriceNow = 0.0, stockQtyNow = 0.0, stockWeightedPriceQtyNow = 0.0;
                //
                // while(priceQueueTemp.length>0){
                //   let stockQtyLast = qtyQueueTemp.shift();
                //   stockQtyNow += stockQtyLast;
                //   stockPriceNow = priceQueueTemp.shift();
                //   stockWeightedPriceQtyNow += stockPriceNow * stockQtyLast;
                // }
                //
                // console.log("New Purchase Added! "+qty+" @ BDT "+purchase+". Current Stock: "+stockQtyNow+" @ BDT "+(stockWeightedPriceQtyNow/stockQtyNow));
            } else {

                let saleQty = parseFloat(trx.quantity)

                let lastQty = 0.0, lastPurchase = 0.0, totalQty = 0.0;
                while (saleQty > totalQty) {
                    lastQty = qtyQueue.shift();
                    lastPurchase = priceQueue.shift();
                    //totalWeighted += lastQty * lastPurchase;
                    totalQty += lastQty;
                    //totalPurchase += lastPurchase;
                    // System.out.println("Price "+totalPurchase+" Qty "+totalQty);
                }
                let remainingQty = totalQty - saleQty;

                if (remainingQty >= -500000000) {
                    let newPriceQueue = []
                    let newQtyQueue = []
                    newPriceQueue.push(lastPurchase);
                    newQtyQueue.push(remainingQty);
                    if (remainingQty < 0) {
                        console.log(remainingQty)
                    }
                    while (priceQueue.length > 0) {
                        newPriceQueue.push(priceQueue.shift());

                    }
                    priceQueue = newPriceQueue;
                    while (qtyQueue.length > 0) {
                        newQtyQueue.push(qtyQueue.shift());

                    }
                    qtyQueue = newQtyQueue;
                }

                //console.log("Sales Info: " + saleQty + " @ BDT " + (totalWeighted / totalQty) + "#######  " + qtyQueue + "-------" + totalPurchase);
                // System.out.println("total weighted: "+totalWeighted+" total qty:"+totalQty);
                // System.out.println("Price Queue: "+priceQueue);
                // System.out.println("Qty Queue: "+qtyQueue);

            }
        }
        console.log("Current Stock Info: Price ->" + priceQueue + "   Quantity->" + qtyQueue + "   Total Stock:" + qtyQueue.reduce((a, x) => a + x, 0));
        console.log("####################################################################################################################################################")
        return "ABCD"

    }
}