<template>
  <div>
    <b-row>
      <b-col>
        <label class="confirmation-label">{{ labelData.primaryName }}</label>
        <p class="confirmation-data">{{ formData.primaryName }}</p>
      </b-col>
      <b-col>
        <label class="confirmation-label">{{ labelData.secondaryName }}</label>
        <p class="confirmation-data">{{ formData.secondaryName }}</p>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col>
        <label class="confirmation-label">{{ labelData.limit }}</label>
        <p class="confirmation-data bn-number">{{ AmountService.commaDecimal( formData.limit )}}</p>
      </b-col>
      <b-col>
        <label class="confirmation-label">{{ labelData.type }}</label>
        <p class="confirmation-data">{{ formData.type }}</p>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col>
        <label class="confirmation-label">{{ labelData.note }}</label>
        <p class="confirmation-data">{{  formData.note }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AmountService from "../../services/amountService";

export default {
  name: "ProductEntryConfirmationModal",
  computed: {
    AmountService() {
      return AmountService
    }
  },
  props: {
    labelData: {},
    formData: {}
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.confirmation-label {
  margin: 0px;
}

.confirmation-data {
  margin: 0px;
  font-size: 18px;
  font-weight: bold;
}
</style>