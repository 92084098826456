<template>
  <div class="container">
    <div class="row">
      <div class="offset-2 col-8 mt-3">
        <div class="card">
          <div class="card-header">
            <h3>{{ label.formTitle }}</h3>
          </div>
          <div class="card-body">
            <b-form @submit="onSubmit" @reset="resetForm">
              <b-form-row>
                <b-col>
                  <b-form-group
                      :label="label.primaryName">
                    <b-form-input
                        v-model="productForm.primaryName"
                        type="text"
                        :placeholder="label.primaryNamePlaceholder"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                      :label="label.secondaryName">
                    <b-form-input
                        v-model="productForm.secondaryName"
                        type="text"
                        :placeholder="label.secondaryNamePlaceholder"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>


              </b-form-row>

              <b-form-group
                  :label="label.type"
              >
                <b-form-select v-model="productForm.type" :options="productTypes" placeholder="এখানে ক্নিক করুন Stock"></b-form-select>
              </b-form-group>

              <b-form-group
                  :label="label.limit"
              >
                <b-form-input
                    v-model="productForm.limit"
                    type="number"
                    :placeholder="label.limitPlaceholder"
                    required
                    class="bn-number"
                ></b-form-input>
              </b-form-group>


              <b-form-group
                  :label="label.note"
              >
                <b-form-textarea
                    id="textarea"
                    v-model="productForm.note"
                    :placeholder="label.notePlaceholder"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </b-form-group>


              <b-button type="submit" variant="primary" class="mr-3">Submit</b-button>
              <b-button variant="info" class="mr-3" @click="fakeData">Load</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>

            </b-form>
            <b-modal id="product-confirmation-modal" :title="label.modalTitle" size="lg">
              <ProductEntryConfirmationModal :labelData="label" :formData="productForm" />
              <template #modal-footer="{ cancel }">


                <b-button variant="danger" @click="cancel()">
                  <i class="fa fa-times" aria-hidden="true"></i> সংশোধন করুন
                </b-button>

                <b-button variant="success" @click="updateProduct()">
                  <i class="fa fa-undo" aria-hidden="true"></i>
                  নিশ্চিত করুন
                </b-button>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IdentityType from "@/services/DataStructure/IdentityType";
import {faker} from "@faker-js/faker/locale/en_IND";
import ProductEntryConfirmationModal from "@/components/ProductComponents/ProductEntryConfirmationModal";
import Product from "@/services/DataStructure/Product/Product";


export default {
  name: "IdentityEntry",
  components: {ProductEntryConfirmationModal},
  props: {
    id: String
  },
  data() {
    return {
      productForm: {
        primaryName: "",
        secondaryName: "",
        limit: "",
        type: "",
        note: ""
      },
      iList: null,
      label: {
        formTitle: "",
        primaryName: "",
        primaryNamePlaceholder: "",
        secondaryName: "",
        secondaryNamePlaceholder: "",
        limit: "",
        limitPlaceholder: "",
        note: ""
      },
      productTypes: [],
      productTypesRaw: [],
      productData: []
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.productForm.registeredDate = new Date().toString()
      this.$bvModal.show('product-confirmation-modal');
      // let customer = new Identity(this.identityForm)
      // console.log(customer)
      this.iList = JSON.parse(localStorage.getItem('product'))
      console.log("this is iList" + this.iList)
    },
    updateProduct(){
      Product.updateProductByID(this.id, this.productForm)
      this.$swal('Success!!!', "তথ্য সফলভাবে যোগ হয়েছে", 'success');
      this.$bvModal.hide("identity-confirmation-modal")
      this.resetForm()
      this.$router.push("/product")
    },
    resetForm() {
      this.productForm = {
        primaryName: "",
        secondaryName: "",
        limit: "",
        type: "",
        note: ""
      }
    },
    fakeData() {
      let name = faker.name.fullName()
      this.productForm = {
        primaryName: name,
        secondaryName: name,
        keyword: name.toLowerCase(),
        address: "My Address",
        mobile: faker.phone.number(),
        limit: faker.finance.amount(1000, 100000, 0),
        registeredDate: faker.date.recent(),
        type: this.type,
        note: faker.lorem.sentences(2)
      }
    },
    applyIdentityConfig() {
      // this.productForm.type = this.type
      let identityName = "প্রোডাক্ট";
      let identityNameSuffix = "প্রোডাক্টের";

      this.label = {
        formTitle: "" + identityName + " তথ্য হালনাগাদ করুন",
        primaryName: identityNameSuffix + " নাম(বাংলায়)",
        primaryNamePlaceholder: "এখানে বাংলায় " + identityNameSuffix + " নাম লিখুন...",
        secondaryName: identityNameSuffix + " নাম(ইংরেজীতে)",
        secondaryNamePlaceholder: "এখানে ইংরেজীতে " + identityNameSuffix + " নাম লিখুন...",
        // keyword: identityName + " খুঁজে পাওয়ার কিওয়ার্ড",
        // keywordPlaceholder: "এখানে " + identityName + " খুঁজে পাওয়ার কিওয়ার্ড লিখুন...",
        // address: identityNameSuffix + " ঠিকানা",
        // addressPlaceholder: "এখানে " + identityNameSuffix + " ঠিকানা লিখুন",
        // mobile: identityNameSuffix + " মোবাইল নং",
        // mobilePlaceholder: "এখানে " + identityNameSuffix + " মোবাইল নং লিখুন...",
        type: identityNameSuffix + " ধরণ",
        typePlaceholder: identityNameSuffix + " ধরণ নির্বাচন করুন",
        limit: identityNameSuffix + " স্টক/মজুদ সীমা(লিমিট)",
        limitPlaceholder: "",
        note: "বিশেষ নোট(যদি থাকে)",
        notePlaceholder: "এখানে বিশেষ নোট লিখুন...",
        modalTitle: identityNameSuffix + " তথ্য সতর্কতার সাথে নিশ্চিত করুন"
      }

      let tempProductTypes = [];
      this.productTypesRaw.forEach(function(x){
        if(x==='cement'){
          tempProductTypes.push({value: x, text: "সিমেন্ট"})
        }else if(x==='steel'){
          tempProductTypes.push({value: x, text: "রড"})
        }else if(x==='hardware'){
          tempProductTypes.push({value: x, text: "হার্ডওয়্যার"})
        }
      })
      this.productTypes = tempProductTypes
    }
  },
  computed: {
    formTitle() {
      let ft;
      switch (this.type) {
        case IdentityType.CUSTOMER:
          ft = "Customer Entry Form"
          break;
        case IdentityType.SUPPLIER:
          ft = "Supplier Entry Form"
          break;
      }
      return ft;
    }
  },
  watch: {
    type() {
      this.applyIdentityConfig()
      this.resetForm()
    }
  },
  created() {
    this.productForm = Product.getProductInfoByID(this.id)
    console.log(Product.getProductInfoByID(this.id))
    this.productTypesRaw = Product.getProductTypes()
    this.applyIdentityConfig()

  }
}
</script>

<style scoped>

</style>