<template>
  <div class="container">
    <div class="row pt-3">
      <div class="col">

        <b-card>
          <div class="float-right"><router-link class="btn btn-warning" :to="this.editLink"><i class="far fa-edit"></i> প্রোফাইল আপডেট</router-link></div>
          <h4>{{ identityInfo.primaryName }}</h4>
            <p class="lead">{{ getIdentityType }}</p>
          <b-card-text>
            {{ identityInfo.secondaryName }}
          </b-card-text>

          <b-card-text>
            মোবাইল: {{ identityInfo.mobile }}
          </b-card-text>

          <b-card-text>
            ঠিকানা: {{ identityInfo.address }}
          </b-card-text>

          <b-card-text>
            নোট: {{ identityInfo.note }}
          </b-card-text>

          <b-card-text>লিমিট: {{ identityInfo.limit }}</b-card-text>
            <b-button variant="danger" @click="deleteProfile"><i class="fas fa-skull-crossbones"></i> DELETE PROFILE <i class="fas fa-exclamation-triangle"></i></b-button>
        </b-card>
      </div>
      <div class="col">
        <b-card header="বর্তমান ব্যালেন্স">

          <span v-if="isBalanceNegative">
            <h3 class="bn-number balance" :class="{ negative: isBalanceNegative }"> - ({{  AmountService.commaDecimalWithCurrency(currentBalance * (-1)) }})</h3>
            <h5 class="negative"> বাকি আছেঃ {{  AmountService.toWordCurrency(currentBalance *(-1)) }}</h5>
          </span>
          <span v-else>
            <h3 class="bn-number balance"> {{  AmountService.commaDecimalWithCurrency(currentBalance) }}</h3>
            <h5>{{  AmountService.toWordCurrency(currentBalance) }}</h5>
          </span>
        </b-card>

    </div>
    </div>
    <h3 class="mt-3">লেনদেনের ইতিহাস</h3>
    <table class="table">
      <thead>
        <tr>
          <th>তারিখ</th>
          <th>নোট</th>
          <th>জমা</th>
          <th>খরচ</th>
          <th>ব্যালেন্স</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in trxInfo" :key="i.date">
          <td>{{ i.date }}</td>
          <td>{{ i.description }}</td>
          <template v-if="i.section=='deposit'">
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(i.amount) }}</td>
            <td></td>
          </template>
          <template v-else>
            <td></td>
            <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(i.amount) }}</td>
          </template>
            <td class="bn-number text-danger" v-if="i.balance<0">({{ AmountService.commaDecimalWithCurrency((i.balance*(-1))) }})</td>
            <td class="bn-number" v-else>{{ AmountService.commaDecimalWithCurrency(i.balance) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import IdentityType from "@/services/DataStructure/IdentityType";
import AmountService from "../../services/amountService";
import Identity from "@/services/DataStructure/Identity";
import DateAdjust from "@/services/DateAdjust";
export default {
  name: "IdentityProfile",
  props:{
    type: String,
    id: String
  },
  data(){
    return {
      identityInfo: {},
      trxInfo:[],
      editLink: "asdfghjkkl",
      balanceX: 0
    }
  },
  methods:{
  translate(v){
    if(v==="deposit"){
      return "জমা"
    }else{
      return "খরচ"
    }
  },
    deleteProfile(){
      if(confirm("আপনি কি নিশ্চিতভাবে এই প্রোফাইল ডিলিট করতে চান?")){
          let password= prompt("Enter Delete Password");
          if(password==="2023"){
              let res = Identity.deleteIdentityByID(this.id)
              if(res){
                  this.$router.push("/"+this.type)
              }
          }else{
              alert("আপনি ভুল পাসওয়ার্ড দিয়েছেন!")
          }
      }
    },
    updateBalance(){
      let balance = 0.0;
      for(let i = 0; i<this.trxInfo.length; i++){
        if(this.trxInfo[i].section == "deposit"){
          balance = parseFloat(balance.toString()) + parseFloat(this.trxInfo[i].amount);
        }else{
          balance = parseFloat(balance.toString()) - parseFloat(this.trxInfo[i].amount);
        }
        this.trxInfo[i].balance = balance;
      }
    }
  },
  created() {
    let temp = JSON.parse(localStorage.getItem('identity'))
    this.identityInfo = temp.filter((x)=>x.id===this.id)[0]
    let deposit = DateAdjust.printAllTrx()
    this.trxInfo = deposit.filter((x)=>x.info.id===this.id)
    this.trxInfo = this.trxInfo.sort((a, b) => {
      if (a.zz < b.zz) {
        return -1;
      }
    })
    this.updateBalance()
    this.editLink = "/"+this.identityInfo.type+"/edit/"+this.id
  },
  watch:{
    id(){
      this.editLink = "/"+this.identityInfo.type+"/edit/"+this.id
    },
    balance(){
      console.log(this.balance())
    }
  },
  computed:{
    AmountService() {
      return AmountService
    },
    isBalanceNegative(){
      if(this.currentBalance<0){
        return true
      }else{
        return false
      }
    },
    getIdentityType(){
      let identityName;
      switch (this.identityInfo.type) {
        case IdentityType.CUSTOMER:
          identityName = "কাস্টমার";
          break;
        case IdentityType.SUPPLIER:
          identityName = "সাপ্লাইয়ার";
          break;
        case IdentityType.VEHICLE:
          identityName = "ট্রাক";
          break;
        case IdentityType.CONTRACT:
          identityName = "ঠিকাদারী কাজ";
          break;
        case IdentityType.BANK:
          identityName = "ব্যাংক একাউন্ট";
          break;
        case IdentityType.THIRD_PARTY:
          identityName = "থার্ড পার্টি";
          break;
        case IdentityType.HARVEST:
          identityName = "ফসল";
          break;
        case IdentityType.PROFIT:
          identityName = "লভ্যাংশ";
          break;
      }
      return identityName
    },
    currentBalance(){
      return this.trxInfo.filter((x)=>x.section==="deposit").reduce((sum,i)=>sum+i.amount,0)-this.trxInfo.filter((x)=>x.section==="expense").reduce((sum,i)=>sum+i.amount,0)
    }
  }
}
</script>

<style scoped>
.card-title{
  font-family: 'boishkhi' !important;
  font-size: 18px;
  white-space: nowrap;
}
.negative{
  color: red;
}
.balance{
  font-size: 30px;
}
</style>