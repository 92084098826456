import { v4 as uuidv4 } from 'uuid';
export default {

    writeToBlackBox(title, msg, info){
        let currentTimestamp = new Date(Date.now()).toLocaleString()
        let data = {
            log_id: uuidv4(),
            timestamp: currentTimestamp,
            title: title,
            msg: msg,
            info: info
        }
        if(!localStorage.getItem('blackBox')){
            let newBlackBox = [];
            newBlackBox.push(data)
            localStorage.setItem('blackBox', JSON.stringify(newBlackBox))
        }else{
            let oldBlackBox = JSON.parse(localStorage.getItem('blackBox'))
            oldBlackBox.push(data)
            localStorage.setItem('blackBox', JSON.stringify(oldBlackBox))
        }
    },
    readFromBlackBox(){
        let productData = JSON.parse(localStorage.getItem('blackBox'))
        return productData;
    }
}