import TransactionInfo from "@/services/DataStructure/TransactionInfo";

export default {
    printAllTrx(){
        let trxData = TransactionInfo.getTrxInfo()
        // trxData.map(v=> v.zz = Date.now()-((Date.now()%86400000)-3600000))
        trxData.map(function (v){
            let selfDate = v.date.split("-")
            let year = selfDate[2]
            let month = selfDate[1]
            if(month>0 && month<10){
                month = "0"+month
            }
            let date = selfDate[0]
            if(date>0 && date<10){
                date = "0"+date
            }
            let isoDate = year+"-"+month+"-"+date+"T00:00:00Z"
            // console.log(isoDate)
            let selfDateTimeStamp = new Date(isoDate)
            // console.log("--> "+selfDateTimeStamp.getTime())
            v.dateArray = selfDate
            v.trzzz = selfDateTimeStamp
            v.zz = selfDateTimeStamp.getTime()
            // v.zz = 1234
            // - ((selfDateTimeStamp.getTime()%86400000)-3600000)
        })

        return trxData
    },
    getTimeStampedTrxData(){

        let trxData = TransactionInfo.getTrxInfo()

        // trxData.map(v=> v.zz = Date.now()-((Date.now()%86400000)-3600000))
        trxData.map(function (v){
            let selfDate = v.date.split("-")
            let year = selfDate[2]
            let month = selfDate[1]
            if(month>0 && month<10){
                month = "0"+month
            }
            let date = selfDate[0]
            if(date>0 && date<10){
                date = "0"+date
            }
            let isoDate = year+"-"+month+"-"+date+"T00:00:00Z"
            // console.log(isoDate)
            let selfDateTimeStamp = new Date(isoDate)
            // console.log("--> "+selfDateTimeStamp.getTime())
            v.dateArray = selfDate
            v.trzzz = selfDateTimeStamp
            v.zz = selfDateTimeStamp.getTime()
            // v.zz = 1234
            // - ((selfDateTimeStamp.getTime()%86400000)-3600000)
        })

        return trxData
    },
    formatDateToTimestamp(dataValue, dataType){
        // dataType is either "from" or "to"
        let year = new Date(dataValue).getFullYear()
        let month = new Date(dataValue).getMonth()+1
        if(month>0 && month<10){
            month = "0"+month
        }
        let date = new Date(dataValue).getDate()
        if(date>0 && date<10){
            date = "0"+date
        }
        let formattedDate = year+"-"+month+"-"+date
        if(dataType==="from"){
            formattedDate+="T00:00:00Z"

        }else if(dataType === "to"){
            formattedDate+="T23:59:59Z"
        }
        return new Date(formattedDate).getTime()
    },
    plainUKDatetoTimestamp(dataValue){
        let selfDate = dataValue.split("-")
        let year = selfDate[2]
        let month = selfDate[1]
        if(month>0 && month<10){
            month = "0"+month
        }
        let date = selfDate[0]
        if(date>0 && date<10){
            date = "0"+date
        }
        let isoDate = year+"-"+month+"-"+date+"T00:00:00Z"
        // console.log(isoDate)
        let selfDateTimeStamp = new Date(isoDate)
        // console.log("--> "+selfDateTimeStamp.getTime())

        return selfDateTimeStamp.getTime()
    },
    fullDateToReportDate(d){
        let date = new Date(d)
        let day = date.getDate()
        let month = this.getMonthByNumber( date.getMonth())
        let year = date.getFullYear()
        console.log( day+ "  -   " + month + "   -   "+ year)
        return day+ " " + month + " "+ year
    },
    formalDateToReportDate(d){
        let selfDate = d.split("-")
        let year = selfDate[2]
        let month = selfDate[1]-1
        let date = selfDate[0]
        if(date>0 && date<10){
            date = "0"+date
        }
        return date+ " " + this.getMonthByNumber(month) + " "+ year
    },
    getMonthByNumber(month){
        let MonthArray = ["জানুয়ারি" , "ফেব্রুয়ারি", "মার্চ" , "এপ্রিল" , "মে" ,"জুন" ,"জুলাই" , "আগস্ট" , "সেপ্টেম্বর", "অক্টোবর" ,"নভেম্বর" ,"ডিসেম্বর"]
        return MonthArray[month]
    }

}