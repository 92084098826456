<template>
    <div>
        <b-table striped hover :items="items"></b-table>
    </div>
</template>

<script>
import BlackBox from "@/services/BlackBox";

export default {
    name: "ProductList",
    props: {
        type: String
    },
    data(){
        return{
            items:[]
        }
    },
    methods:{
        loadData(){
            let data = BlackBox.readFromBlackBox()
            //this.items = data.filter(x=>x.type === this.type)
            this.items = data
        }
    },
    watch:{
        type(){
            this.loadData()
        }
    },
    created() {
        this.loadData()
    }
}
</script>

<style scoped>

</style>