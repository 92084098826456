import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDv5BxpMOQioGMZ8UTBq7YRXyLGZ7um0l0",
    authDomain: "my-invoicer-b68be.firebaseapp.com",
    databaseURL: "https://my-invoicer-b68be-default-rtdb.firebaseio.com",
    projectId: "my-invoicer-b68be",
    storageBucket: "my-invoicer-b68be.appspot.com",
    messagingSenderId: "214994771193",
    appId: "1:214994771193:web:a4a4109200ad9844521628",
    measurementId: "G-N9FSLX3XZ4"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
const auth = firebase.auth();

export {auth}