<template>
  <div>
    <b-button v-b-modal.modal-sale variant="primary" size="sm" class="float-right no-print absolute-button"><b class="bi bi-plus-square"></b>
      বিক্রয়
    </b-button>
    <b-modal id="modal-sale" size="xl" title="বিক্রয়ের খাতা">
      <b-row>
        <b-col>
          <model-select :options="getProductList"
                        v-model="currentProduct"
                        placeholder="এখানে ক্নিক করুন Stock">
          </model-select>
          <h5 class="mt-2">নির্বাচিত প্রোডাক্ট: <b>{{ selectedProduct.primaryName }}</b></h5>

          <p>স্টকের বিবরণী</p>
          <table class="table table-bordered">
            <thead>
            <tr>
              <td>গড় ক্রয়মূল্য</td>
              <td>বর্তমান স্টকের পরিমান</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="i in stockLength" :key="i">
              <td class="bn-number">{{ AmountService.commaDecimalWithCurrency( stockInfo.priceQueue[i-1]) }}</td>
              <td class="bn-number">{{ stockInfo.qtyQueue[i-1] }}</td>
            </tr>
            <tr>
              <td>মোট স্টক</td>
              <td class="bn-number">{{ stockTotal }}</td>
            </tr>
            </tbody>
          </table>

          <b-form class="mb-3">
            <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0" v-model="purchaseRateCheckBox">ক্রয়মূল্য পরিবর্তন করুন</b-form-checkbox>
            <b-form-input
                id="inline-form-input-name"
                class="mb-2 mr-sm-2 mb-sm-0 bn-number"
                placeholder="0.00"
                :readonly="!purchaseRateCheckBox"
                v-model="editablePurchaseRate"
            ></b-form-input>
          </b-form>

          <table class="table table-bordered table-sm">
            <thead class="text-right">
            <tr>
              <th scope="col">পরিমান
              </th>
              <th scope="col">বিক্রয় দর
              </th>
              <th scope="col">মোট মূল্য</th>
            </tr>
            </thead>
            <tbody>
            <tr>


              <td><input class="form-control digit-input" type="number" :readonly="previewStatus"
                         v-model="saleQuantity"/></td>
              <td><input class="form-control digit-input" type="number" disabled  v-model="saleRate"/>
              </td>
              <td><input class="form-control digit-input" :readonly="previewStatus" v-model="saleTotal"/></td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">সর্বমোট</td>
              <td class="text-right bn-number">{{ AmountService.commaWithCurrency(saleTotal) }}</td>
            </tr>
            </tbody>
          </table>
          <p>এই বিক্রয় হতে লাভ: <b class="bn-number">{{ AmountService.commaDecimalWithCurrency(getProfit) }}</b>, শতকরা
            লাভের হার <b v-if="editablePurchaseRate!=0" class="bn-number">{{ AmountService.commaDecimalWithCurrency(getProfitMargin) }}</b>
            <b v-else >ক্রয়মূল্য শূণ্য, শতকরা লাভ নির্ণয় অসম্ভব।</b></p>
        </b-col>
        <b-col>
          <div v-if="previewStatus">
            <h3 class="text-center h3 text-warning font-weight-bold">সতর্কতার সাথে নিশ্চিত করুন</h3>
            <h5 class="text-center h4">প্রোডাক্ট বিক্রয়</h5>
            <table class="table table-bordered table-sm">

              <thead>
              <tr>
                <td colspan="3"><h5>প্রোডাক্টের নাম: <b>{{ confirmedData.info.primaryName }}</b></h5></td>
              </tr>
              <tr class="text-right">
                <th scope="col">পরিমান
                </th>
                <th scope="col">দর
                </th>
                <th scope="col">মোট মূল্য</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-right">


                <td class="bn-number">{{ AmountService.comma(this.confirmedData.quantity) }}</td>
                <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(this.confirmedData.saleRate) }}</td>
                <td class="bn-number">{{ AmountService.commaDecimalWithCurrency(this.confirmedData.amount) }}</td>
              </tr>
              <tr>
                <td colspan="2" class="text-right">সর্বমোট</td>
                <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(this.confirmedData.amount) }}</td>
              </tr>
              <tr>
                <td colspan="3"><i>কথায়: </i> {{ AmountService.toWordCurrency(this.confirmedData.amount) }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="preview" class="float-right button-abc" variant="primary">চেক করুন</b-button>
        </b-col>
        <b-col>
          <div v-if="previewStatus">
            <b-button @click="correction" variant="danger">সংশোধন করুন</b-button>
            <b-button class="float-right" @click="confirm" variant="success">নিশ্চিত করুন</b-button>
          </div>

        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">

        <b-button variant="info" @click="reset()">
          <i class="fa fa-undo" aria-hidden="true"></i>
          রিসেট করুন
        </b-button>
        <b-button variant="danger" @click="cancel()">
          <i class="fa fa-times" aria-hidden="true"></i> বন্ধ করুন
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {ModelSelect} from 'vue-search-select'
import AmountService from "../../services/amountService";
import axios from "axios";

export default {
  name: "SaleModal",
  components: {
    ModelSelect
  },
  data() {
    return {
      previewStatus: false,
      weightedPurchaseRate: "",
      saleQuantity: "",
      saleRate: "",
      saleTotal: "",
      profit: 0,
      stockPurchaseStatus: null,
      stockPurchaseWeightedTotalSumArray: [],
      confirmedData: {},
      selectedProduct:{},
      currentProduct: "",
      stockArray: [],
      purchaseRateCheckBox: false,
      editablePurchaseRate: "",
      stockInfo: [],
      stockLength : 0
    }

  },
  props: {
    productListProp: []
  },
  computed: {
    AmountService() {
      return AmountService
    },
    getProductList(){
      let pList = []
      if(this.productListProp){
        this.productListProp.forEach((x)=>pList.push({value: x.id, text: x.primaryName, id: x.id}))
      }
      return pList
    },
    stockPurchaseAndStatusComputed() {
      return this.stockPurchaseAndStatus()
    },
    getStockWeightedTotalSumComputed() {
      return this.getStockWeightedTotalSum()
    },
    getProfit() {
      return this.calculateProfit()
    },
    getProfitMargin() {
      return this.calculateProfitMargin()
    },
    stockTotal() {
      if(this.stockInfo.qtyQueue){
        return this.stockInfo.qtyQueue.reduce((sum, x)=> sum+x,0);
      }else{
        return 0;
      }

    }
      // getWeightedAveragePurchaseRate(){
    //   let total_stock = this.stockPurchaseAndStatus().reduce((sum , x)=>x.quantity, 0)
    //   return total_stock
    // }
  },
  methods: {
    preview() {
      this.previewStatus = true;

      this.confirmedData = {
        info: this.selectedProduct,
        purchaseRate: parseFloat(this.editablePurchaseRate), // Need to modify
        quantity: isNaN(parseFloat(this.saleQuantity)) ? 0 : parseFloat(this.saleQuantity),
        saleRate: parseFloat(this.saleRate),
        profit: this.getProfit, // Need to modify
        amount: parseFloat(this.saleTotal),
        // category: this.productListProp[this.item.value].info.category
      }
    },
    correction() {
      this.confirmedData = null
      this.previewStatus = false;
    },

    confirm() {
      let finalData = this.confirmedData
      finalData.type = "stock"
      finalData.status= "out"
      this.previewStatus = false,
          this.weightedPurchaseRate = "",
          this.saleQuantity = "",
          this.saleRate = "0",
          this.saleTotal = "",
          this.profit = 0,
          this.previewStatus = false
      this.$emit('receiveSaleData', finalData);
      this.$swal('Success!!!', "তথ্য সফলভাবে যোগ হয়েছে", 'success');
      this.$bvModal.hide("modal-sale")
      this.backupNow()
    },

    stockPurchaseAndStatus: function () {
      this.stockPurchaseStatus = this.productListProp.map(
          function (v) {
            return v.stock.map(function (val) {
              let indvStock = {
                purchase_rate: val.purchase_rate,
                quantity: val.quantity,
                weighted_total: parseFloat(val.purchase_rate) * parseFloat(val.quantity)
              }
              return indvStock
            })
          }
      );
      return this.stockPurchaseStatus
    },
    getStockWeightedTotalSum() {
      this.stockPurchaseWeightedTotalSumArray = this.stockPurchaseStatus.map(function (v) {
        return {
          "weighted_total_sum": v.reduce((val, sum) => val + parseFloat(sum.weighted_total), 0),
          "quantity_sum": v.reduce((val, sum) => val + parseFloat(sum.quantity), 0),
          "weighted_avg_purchase_rate": parseFloat(parseFloat(v.reduce((val, sum) => val + parseFloat(sum.weighted_total), 0) / v.reduce((val, sum) => val + parseFloat(sum.quantity), 0)).toFixed(2))
        }
      })

      return this.stockPurchaseWeightedTotalSumArray
    },
    calculateProfit() {
      // console.log("sale rate: " + this.saleRate + " | sale quantity: " + this.saleQuantity + " weightedPurchaseRate: " + this.weightedPurchaseRate + " item.value: " + this.item.value)
      return (this.saleRate * this.saleQuantity) - (this.editablePurchaseRate * this.saleQuantity)
    },
    calculateProfitMargin() {
      return (((this.saleRate * this.saleQuantity) / (this.editablePurchaseRate * this.saleQuantity)) * 100) - 100
    },

    getStock(){
      let xcv = [];
      let trxInfo = JSON.parse(localStorage.getItem("trxInfo"))
      let product = JSON.parse(localStorage.getItem("product"))
      product.forEach(function(x){
        let purchaseQtyWeighted = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "in")).map((z)=>parseFloat(z.purchaseRate)*parseFloat(z.quantity)).reduce((sum, i)=>sum+i,0)
        let totalIn = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "in")).reduce((sum, i)=>sum+i.quantity,0)
        let totalOut = trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "out")).reduce((sum, i)=>sum+i.quantity,0)
        let stock = totalIn - totalOut
        let avgPurchaseRate = (purchaseQtyWeighted/totalIn)?purchaseQtyWeighted/totalIn:0

        x.stock = stock
        x.avgPurchaseRate = parseFloat(avgPurchaseRate).toFixed(2)
        xcv.push(x)

        console.log(x.primaryName + " -> IN:" +totalIn+"     OUT:"+totalOut+"    Stock:"+stock+"     WeightedRateQty:"+purchaseQtyWeighted+"     Average_Purchase_Rate:"+avgPurchaseRate)
        // console.log(JSON.stringify(trxInfo.filter((trx)=>(trx.info.id===x.id && trx.status === "in")).reduce((sum, i)=>sum+i,0)))
      })
      this.stockArray = xcv
    },
    async getStockData(){
      this.$swal.showLoading();
      let url = "https://xxxxxxxxxxxxxxxx.a.run.app/api/stock/"+this.currentProduct
      await axios.get(url,{
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'text/plain',
        headers: {"Authorization" : "1800938444211"}
      }).then((response) => {
        this.$swal.close()
        console.log(response)
        console.log(response.data.data.priceQueue.length)
        this.stockInfo = response.data.data
        this.stockLength = response.data.data.priceQueue.length
        // this.myData = response.data
        // if(response.data.status === 'success'){
        //   this.$swal('তথ্য সংরক্ষণ সফল হয়েছে!', "আপনার তথ্য সফলভাবে সার্ভারে আপলোড হয়েছে", 'success');
        // }else{
        //   this.$swal('বিপদ সংকেত!!!', "তথ্য সংরক্ষণ ব্যর্থ হয়েছে! দয়া করে আবার চেষ্টা করুন...", 'danger');
        // }


      }).catch(()=>{
        this.$swal.close()
      })
    },
    getWeightedPurchaseRateFIFO(){
      console.log(this.stockInfo.qtyQueue.reduce((sum, x)=> sum+x,0))
      if(this.stockInfo.qtyQueue.reduce((sum, x)=> sum+x,0)<this.saleQuantity){
        alert("স্টকের অতিরিক্ত পণ্য বিক্রয় সম্ভব নয়!")
        this.saleQuantity = ""
      }

      let saleQuantityCopy = this.saleQuantity
      let qumulativeWeightedQtyPrice = 0;
      for(let i=0; i<this.stockLength; i++){
        if(saleQuantityCopy>this.stockInfo.qtyQueue[i]){
          // console.log(this.stockInfo.priceQueue[i]+" X "+this.stockInfo.qtyQueue[i]);
          qumulativeWeightedQtyPrice += this.stockInfo.priceQueue[i] * this.stockInfo.qtyQueue[i];
          saleQuantityCopy -= this.stockInfo.qtyQueue[i];
        }else{
          // console.log(this.stockInfo.priceQueue[i]+" X "+saleQuantityCopy);
          qumulativeWeightedQtyPrice += this.stockInfo.priceQueue[i] * saleQuantityCopy;
          break;
        }
      }
      let resultRate = (qumulativeWeightedQtyPrice / this.saleQuantity).toFixed(2)
      if(isNaN(resultRate)){
        this.editablePurchaseRate = 0.0;
      }else{
        this.editablePurchaseRate = resultRate
      }
    },
    async backupNow(){
      this.$swal.showLoading();
      let zer = JSON.parse(localStorage.getItem("zer"))
      let identity = JSON.parse(localStorage.getItem("identity"))
      let trxInfo = JSON.parse(localStorage.getItem("trxInfo"))
      let product = JSON.parse(localStorage.getItem("product"))
      let blackBox = JSON.parse(localStorage.getItem("blackBox"))
      await axios.post("https://xxxxxxxxxxxxxxxx.a.run.app/api/update/database",{
        zer: zer,
        identity: identity,
        trxInfo: trxInfo,
        product: product,
        blackBox: blackBox
      },{
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'text/plain'
      }).then((response) => {
        this.$swal.close()
        this.myData = response.data
        if(response.data.status === 'success'){
          this.$swal('তথ্য সংরক্ষণ সফল হয়েছে!', "আপনার তথ্য সফলভাবে সার্ভারে আপলোড হয়েছে", 'success');
        }else{
          this.$swal('বিপদ সংকেত!!!', "তথ্য সংরক্ষণ ব্যর্থ হয়েছে! দয়া করে আবার চেষ্টা করুন...", 'danger');
        }


      })
    }

  },
  created() {
    this.getStock()
    // this.stockPurchaseAndStatus();
    // this.getStockWeightedTotalSum()
  },
  watch: {

    currentProduct(){
      let singleProductArray = this.productListProp.filter((x)=>x.id===this.currentProduct)
      if(singleProductArray.length>0){
        this.selectedProduct = singleProductArray[0]
        let selectedProductStock = this.stockArray.filter(y=>y.id===this.selectedProduct.id)[0]
        this.selectedProduct.avgPurchaseRate = selectedProductStock.avgPurchaseRate
        this.selectedProduct.stock = selectedProductStock.stock
        // this.editablePurchaseRate = selectedProductStock.avgPurchaseRate
        this.getStockData()
      }else{
        this.selectedProduct = {}
      }
    },

    saleQuantity: function (val) {
      if(isNaN(this.saleRate)){
        this.saleRate = 0.00
      }
      this.saleTotal = (val * this.saleRate).toFixed(2)
      this.getWeightedPurchaseRateFIFO();
    },
    saleTotal: function (val) {
      this.saleRate = (val / this.saleQuantity).toFixed(2)
    },
    item: function () {
      this.weightedPurchaseRate = this.stockPurchaseWeightedTotalSumArray[this.item.value].weighted_avg_purchase_rate
    }
  }
}
</script>

<style scoped>

</style>