import VueRouter from "vue-router";
import Vue from "vue";

import DailyRegistry from "@/components/DailyRegistry.vue";
// import NavigatorHome from "@/components/NavigatorHome.vue";
import IdentityEntry from "@/components/IdentityComponents/IdentityEntry.vue";
import IdentityList from "@/components/IdentityComponents/IdentityList.vue";
import ProductEntry from "@/components/ProductComponents/ProductEntry";
import ProductList from "@/components/ProductComponents/ProductList";
import Home from "@/components/Home.vue";
import IdentityProfile from "@/components/IdentityComponents/IdentityProfile.vue";
import BlankComponent from "@/components/BlankComponent.vue";
import TestComponent from "@/components/TestComponent.vue";
import BackupComponent from "@/components/BackupComponent.vue";
import IdentityEdit from "@/components/IdentityComponents/IdentityEdit";
import BlackBoxComponent from "@/components/BlackBoxComponent.vue";
import ProfitReportComponent from "@/components/Report/ProfitReportComponent.vue";
import CustomerReceivableReportComponent from "@/components/Report/CustomerReceivableReportComponent.vue";
import IndividualStockReportComponent from "@/components/Report/IndividualStockReportComponent.vue";
import StockReportComponent from "@/components/Report/StockReportComponent.vue";
import ProductEdit from "@/components/ProductComponents/ProductEdit.vue";
import LimitCrossedCustomerReceivableReportComponent
    from "@/components/Report/LimitCrossedCustomerReceivableReportComponent.vue";
import IncomeStatementComponent from "@/components/Report/IncomeStatementComponent.vue";
// import IdentityType from "@/services/DataStructure/IdentityType";
import ExpenseStatementComponent from "@/components/Report/ExpenseStatementComponent.vue"
import DepositStatementComponent from "@/components/Report/DepositStatementComponent.vue";
import PurchaseStatementComponent from "@/components/Report/PurchaseStatementComponent.vue"
import SalesStatementComponent from "@/components/Report/SalesStatementComponent.vue"
import ProductCategoryBasedReportComponent from "@/components/Report/ProductCategoryBasedReportComponent.vue";
import TruckPerformanceReportComponent from "@/components/Report/TruckPerformanceReportComponent.vue";
import DailyAverageBalanceReportComponent from "@/components/Report/DailyAverageBalanceReportComponent.vue";
import IncomeStatementCompactComponent from "@/components/Report/IncomeStatementCompactComponent.vue";
import ProductBasedStatementComponent from "@/components/Report/ProductBasedStatementComponent.vue";
import Login from "@/components/Login.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: "/daily",
        name: "DailyRegistry",
        component: DailyRegistry
    },
    {
        path: "/blank",
        name: "BlankComponent",
        component: BlankComponent
    },
    {
        path: "/blackbox",
        name: "BlackBoxComponent",
        component: BlackBoxComponent
    },
    {
        path: "/home",
        name: "NavigatorHome",
        component: Home
    },
    {
        path: "/",
        name: "Login",
        component: Login
    },
    // {
    //     path: "/customer/add",
    //     name: "CustomerEntry",
    //     component: IdentityEntry,
    //     props: { type: IdentityType.CUSTOMER}
    // },
    // {
    //     path: "/supplier/add",
    //     name: "SupplierEntry",
    //     component: IdentityEntry,
    //     props: { type: IdentityType.SUPPLIER}
    // },
    {
        path: "/product/add",
        name: "ProductEntry",
        component: ProductEntry
    },
    {
        path: "/product",
        name: "ProductList",
        component: ProductList
    },
    {
        path: "/product/edit/:id",
        name: "ProductEdit",
        component: ProductEdit,
        props: route => ({ id: route.params.id})
    },
    {
        path: "/test-xyz",
        name: "TestComponent",
        component: TestComponent
    },
    {
        path: "/backup",
        name: "BackupComponent",
        component: BackupComponent
    },
    {
        path: "/report/profit",
        name: "ProfitReportComponent",
        component: ProfitReportComponent
    },
    {
        path: "/report/income",
        name: "IncomeStatementComponent",
        component: IncomeStatementComponent
    },
    {
        path: "/report/income-compact",
        name: "IncomeStatementCompactComponent",
        component: IncomeStatementCompactComponent
    },
    {
        path: "/report/customer-balance",
        name: "DailyAverageBalanceReportComponent",
        component: DailyAverageBalanceReportComponent
    },
    {
        path: "/report/customer-receivable",
        name: "CustomerReceivableReportComponent",
        component: CustomerReceivableReportComponent
    },
    {
        path: "/report/limit-exceeded-customer-receivable",
        name: "LimitCrossedCustomerReceivableReportComponent",
        component: LimitCrossedCustomerReceivableReportComponent
    },
    {
        path: "/report/stock-individual",
        name: "IndividualStockReportComponent",
        component: IndividualStockReportComponent
    },
    {
        path: "/report/stock",
        name: "StockReportComponent",
        component: StockReportComponent
    },
    {
        path: "/report/product-based",
        name: "ProductBasedStatementComponent",
        component: ProductBasedStatementComponent
    },
    {
        path: "/report/product/category-wise",
        name: "ProductCategoryBasedReportComponent",
        component: ProductCategoryBasedReportComponent
    },    {
        path: "/report/vehicle",
        name: "TruckPerformanceReportComponent",
        component: TruckPerformanceReportComponent
    },

    // {
    //     path: "/report/statement/deposit",
    //     name: "DepositReport",
    //     component: DepositReport,
    // },
    {
        path: "/report/statement/expense",
        name: "ExpenseStatementComponent",
        component: ExpenseStatementComponent,
    },
    {
        path: "/report/statement/purchase",
        name: "PurchaseStatementComponent",
        component: PurchaseStatementComponent,
    },
    {
        path: "/report/statement/sales",
        name: "SalesStatementComponent",
        component: SalesStatementComponent,
    },
    {
        path: "/report/statement/deposit",
        name: "DepositStatementComponent",
        component: DepositStatementComponent,
    },
    {
        path: "/:identity/add",
        name: "IdentityEntry",
        component: IdentityEntry,
        props: route => ({ type: route.params.identity })
    },
    {
        path: "/:identity",
        name: "IdentityList",
        component: IdentityList,
        props: route => ({ type: route.params.identity })
    },
    {
        path: "/:identity/:id",
        name: "IdentityProfile",
        component: IdentityProfile,
        props: route => ({ type: route.params.identity, id: route.params.id})
    },
    {
        path: "/:identity/edit/:id",
        name: "IdentityEdit",
        component: IdentityEdit,
        props: route => ({ type: route.params.identity, id: route.params.id})
    }

]


const router = new VueRouter({
    mode: 'history',
    routes
})

export default router;