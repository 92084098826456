<template>
  <div>
    <p class="bn-number">{{ calculation }}</p>
    <h5 class="bn-number">{{ result }}</h5>
    <input class="form-control digit-input" v-model="expression" @keyup="calculateNow"/>
  </div>
</template>

<script>

import Calc from 'expression-calculator';
export default {
  name: "CalculatorComponent",
  data(){
    return{
      expression: "",
      calculation: "",
      result: ""
    }
  },
  methods:{
    calculateNow(e){
      console.log(e)
      if(e.keyCode ===13){
        let calc = new Calc();
        try{
          this.result = calc.compile(this.expression).calc()
        }catch (e) {
          this.result = "আপনি ভুল ইনপুট দিয়েছেন"

        }
        this.calculation = this.expression.slice()
        this.expression = ""
      }
    }
  }
}
</script>
