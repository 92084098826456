import vehicle from './vehicle.json'
import product from './product.json'
import supplier from './supplier.json'
import expense from './expense.json'
import customer from './customer.json'
import Identity from "@/services/DataStructure/Identity";
import Product from "@/services/DataStructure/Product/Product";
class DummyRunner{
    static addTruck(){
        vehicle.forEach((x)=>Identity.save(x))
    }

    static addProduct(){
        product.forEach((x)=>Product.save(x))
    }

    static addSupplier(){
        supplier.forEach((x)=>Identity.save(x))
    }

    static addExpense(){
        expense.forEach((x)=>Identity.save(x))
    }

    static addCustomer(){
        customer.forEach((x)=>Identity.save(x))
    }
}

export default DummyRunner;