<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card mt-2">
          <div class="card-header">
            <h3 class="card-title">এখন ব্যাকআপ করুন</h3>
          </div>
          <div class="card-body">
            <form class="form-inline" @submit="backupNow">
              <input type="text" v-model="backupName" class="form-control mr-sm-2" id="inlineFormInputName2" placeholder="ব্যাকআপের নাম">
              <input type="password" v-model="backupPassword" class="form-control mr-sm-2" id="inlineFormInputName2" placeholder="ব্যাকআপের পাসওয়ার্ড">

              <button type="submit" :disabled="backupStatus" class="btn btn-primary">ব্যাকআপ করুন</button>
            </form>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">ব্যাকআপের তালিকা</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-success" @click="getData" title="Collapse">
                <i class="fas fa-sync-alt mr-1"></i> ব্যাকআপের তালিকা লোড করুন
              </button>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>ব্যাকআপের সময়কাল</th>
                <th>এখন থেকে</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="d in backupLogs" :key="d.timestamp">
                <td>{{ new Date(d.timestamp).toLocaleString() }}</td>
                <td>{{ timeDifference(Date.now() - d.timestamp) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "BackupComponent",
  data(){
    return{
      backupLogs: "",
      myData: "",
      backupName:"shohug2023",
      backupPassword: "01717556600",
      backupStatus: false
    }
  },
  methods:{
     async getData(){
      if(this.backupName!="" && this.backupPassword!=""){

        let getURL = "?name=" + this.backupName + "&password=" + this.backupPassword
        await axios.get("https://xxxxxxxxxxxxxxx/app/backupLogs" + getURL).then((response) => {

          if (response.data.msg === 'invalid_password') {
            this.$swal('দুঃখিত!!!', "আপনি ভুল পাসওয়ার্ড দিয়েছেন! দয়া করে সঠিক পাসওয়ার্ড দিন", 'danger');
          } else {
            this.backupLogs = response.data.reverse()
          }
        })
      }else{
        this.$swal('দুঃখিত!!!', "আপনি ভুল পাসওয়ার্ড দিয়েছেন! দয়া করে সঠিক পাসওয়ার্ড দিন", 'danger');
      }
      // this.backupStatus = false
    },
    async backupNow(event){
      this.backupStatus = true
      event.preventDefault()
      let zer = JSON.parse(localStorage.getItem("zer"))
      let identity = JSON.parse(localStorage.getItem("identity"))
      let trxInfo = JSON.parse(localStorage.getItem("trxInfo"))
      let product = JSON.parse(localStorage.getItem("product"))
        let blackBox = JSON.parse(localStorage.getItem("blackBox"))
      await axios.post("https://xxxxxxxxxxxxxxx.a.run.app/api/update/database",{
        zer: zer,
        identity: identity,
        trxInfo: trxInfo,
        product: product,
          blackBox: blackBox,
        credential: {backup_name: this.backupName, backup_password: this.backupPassword}
          },{
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'text/plain'
      }).then((response) => {
        this.myData = response.data
        if(response.data.msg === 'invalid_password'){
          this.$swal('দুঃখিত!!!', "আপনি ভুল পাসওয়ার্ড দিয়েছেন! দয়া করে সঠিক পাসওয়ার্ড দিন", 'danger');
        }else{
          this.$swal('ব্যাকআপ সফল!!!', "ব্যাকআপ সফলভাবে সার্ভারে আপলোড হয়েছে", 'success');
          this.getData()
        }


      })
      this.backupStatus = false
    },
    timeDifference(x){
      let days = (x/(60*60*24*1000)).toFixed(0);
      x = x%(60*60*24*1000)
      let hours = parseInt(x/(60*60*1000));
      x = x%(60*60*1000)
      let minutes = parseInt(x/(60*1000));
      x = x%(60*1000)
      let seconds = (x/1000).toFixed(0)

      let output = (days>0?(days + " দিন "):"" )+ (hours>0?(hours + " ঘন্টা "):"" ) + (minutes>0?(minutes + " মিনিট "):"" ) + seconds + " সেকেন্ড আগে";

      return output
    }
  },
  computed:{
    asif(){
      return Date.now()
    }
  },
  watch:{
    asif(){
      return Date.now()
    }
  }
}
</script>

<style scoped>

</style>