<template>
  <div class="container-fluid">
    <h3 class="mt-3 mb-3">ক্রয় বিবরণী</h3>
    <div class="row mb-3">
      <form onsubmit="return false" class="form-inline">
        <label>রিপোর্ট শুরুর তারিখ</label>
        <datepicker :highlighted="{ days: [5] }" input-class="form-control ml-2 mr-2" v-model="from"
                    format="dd MMMM yyyy"></datepicker>
        হতে
        <datepicker :highlighted="{ days: [5] }" input-class="form-control ml-2 mr-2" v-model="to"
                    format="dd MMMM yyyy"></datepicker>

        <label>পর্যন্ত</label>
        <button class="btn btn-primary ml-4" @click="getReportTraining">রিপোর্ট তৈরী করুন</button>
      </form>
    </div>
    <h3 class="mt-3">ক্রয় বিবরণী</h3>
    <table class="table">
      <thead>
      <tr>
        <th>তারিখ</th>
        <th>প্রোডাক্টের নাম</th>
        <th class="text-right">পরিমান</th>
        <th class="text-right">ক্রয়মূল্য (দর)</th>
        <th class="text-right">মোট মূল্য</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="i in expenseIn" v-bind:key="i.info.id">
        <td>{{ i.date }}</td>
        <td>{{ i.info.primaryName }}</td>
        <td class="text-right bn-number">{{ i.quantity }}</td>
        <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(i.purchaseRate) }}</td>
        <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(i.total) }}</td>
      </tr>
      <tr>
        <td colspan="3"></td>
        <td class="text-right">মোট</td>
        <td class="text-right bn-number">{{ AmountService.commaDecimalWithCurrency(expenseIn.reduce((sum,x)=>sum+=x.total, 0)) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DateAdjust from "@/services/DateAdjust.js";
import Datepicker from 'vuejs-datepicker';
import AmountService from "../../services/amountService";
export default {
  name: "PurchaseStatementComponent",
  components: { Datepicker },
  data() {
    return {
      expenseIn: [],
      from: '',
      to: '',
    }
  },
  methods: {
    getFullDateToTimeStamp(fullDate) {
      let year = new Date(fullDate).getFullYear()
      let month = new Date(fullDate).getMonth() + 1
      if (month > 0 && month < 10) {
        month = "0" + month
      }
      let date = new Date(fullDate).getDate()
      if (date > 0 && date < 10) {
        date = "0" + date
      }
      let formattedDate = year + "-" + month + "-" + date + "T00:00:00Z"
      return new Date(formattedDate).getTime()
    },
    getReportTraining() {
      let fromDate = this.getFullDateToTimeStamp(this.from);
      let toDate = this.getFullDateToTimeStamp(this.to);
      this.expenseIn = DateAdjust.printAllTrx().filter(x => x.zz <= toDate && x.zz >= fromDate).filter(x => x.section === "expense").filter(x => x.status === "in");
    }
  },
  computed: {
    AmountService() {
      return AmountService
    }

  }
}
</script>
<style scoped></style>