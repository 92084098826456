import { v4 as uuidv4 } from 'uuid';
class TransactionInfo{
    static getTrxInfo(){
        let trxInfo = JSON.parse(localStorage.getItem('trxInfo'))
        if(trxInfo){
            return trxInfo
        }else {
            return []
        }
    }

    static addTrxInfo(data){
        data.id = uuidv4()
        localStorage.setItem('trxInfo', JSON.stringify(data))
    }


}
export default TransactionInfo;