<template>
  <div class="container-fluid">
    <h2 class="mt-3 mb-3">প্রোডাক্ট ভিত্তিক স্টক রিপোর্ট</h2>

    <div class="row mb-3">
      <form onsubmit="return false" class="form-inline">
        <label for="input-live">প্রোডাক্ট নির্বাচন করুন:</label>
        <b-form-select v-model="selectedProduct" class="mx-3"
                       :options="getProductList"></b-form-select>
        <label>রিপোর্ট শুরুর তারিখ</label>
        <datepicker :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="from"
                    format="dd MMMM yyyy"></datepicker>
      হতে
        <datepicker :highlighted="{days: [5]}"  input-class="form-control ml-2 mr-2" v-model="to"
                    format="dd MMMM yyyy"></datepicker>

        <label>পর্যন্ত</label>
        <button class="btn btn-primary ml-4" @click="getStockReport">রিপোর্ট তৈরী করুন</button>
      </form>

    </div>

  <table class="table">
    <thead>
    <tr>
      <th>বিক্রয়ের তারিখ</th>
      <th class="text-center bl bt">ক্রয়ের পরিমান</th>
      <th class="text-center bt br">ক্রয় দর</th>
      <th class="text-center bt">বিক্রয়ের পরিমান</th>
      <th class="text-center bt br">বিক্রয় দর </th>
<!--      <th class="text-center">পরিমান ( Q )</th>-->
<!--      <th class="text-right">মোট বিক্রয় ( S &#215; Q )</th>-->
<!--      <th class="text-right">লাভ ( S &#215; Q ) - ( P &#215; Q )</th>-->
    </tr>
    </thead>
    <tbody>
    <tr>
      <td >পূর্বের স্টক (জের)</td>
      <td class="bl bn-number text-center">{{ zerStock }}</td>
      <td class="br"></td>
      <td></td>
      <td class="br"></td>
    </tr>
    <tr v-for="i in trxList" v-bind:key="i.id">
      <td>{{ i.date }}</td>
      <template v-if="i.status=='in'">
        <td class="text-center bl bn-number">{{ i.quantity }}</td>
        <td class="text-center br bn-number">{{ AmountService.commaDecimalWithCurrency(i.purchaseRate) }}</td>
        <td></td>
        <td class="br"></td>
      </template>
      <template v-else>
        <td class="bl"></td>
        <td class="br"></td>
        <td class="text-center bn-number">{{ i.quantity }}</td>
        <td class="text-center br bn-number">{{ AmountService.commaDecimalWithCurrency(i.saleRate) }}</td>
      </template>


<!--      <td>{{ i.info.primaryName }}</td>-->
<!--      <td class="bn-number text-center">{{ AmountService.commaDecimalWithCurrency( i.purchaseRate )}}</td>-->
<!--      <td class="bn-number text-center">{{ AmountService.commaDecimalWithCurrency( i.saleRate ) }}</td>-->
<!--      <td class="bn-number text-center">{{  i.quantity }}</td>-->
<!--      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency( i.amount ) }}</td>-->
<!--      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency( i.profit.toFixed(2) ) }}</td>-->
<!--      -->

    </tr>
    </tbody>
    <tfoot>
    <tr class="font-weight-bold bb-double">
      <td><h4 class="font-weight-bold text-center">সর্বমোট</h4></td>
      <td class="text-center bn-number bt bl">{{ purchaseTotal }}</td>
      <td class="bt br"></td>
      <td class="text-center bn-number bt">{{ salesTotal }}</td>
      <td class="bt br"></td>
<!--      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(salesMot.toFixed(2))}}</td>-->
<!--      <td class="bn-number text-right">{{ AmountService.commaDecimalWithCurrency(profitMot.toFixed(2))}}</td>-->
    </tr>
    </tfoot>
  </table>
    <h4 v-if="lastDate!=''">{{ lastDate }} এর দিনশেষে স্টকের পরিমান <span class="bn-number">{{ purchaseTotal-salesTotal }}</span></h4>
  </div>

</template>

<script>
import DateAdjust from "@/services/DateAdjust";
import Datepicker from 'vuejs-datepicker';
import AmountService from "@/services/amountService";
import Product from "@/services/DataStructure/Product/Product";

export default {
  name: "IndividualStockReportComponent",
  computed: {
    AmountService() {
      return AmountService
    },
    getProductList(){
      let pList = []
      if(this.productList){
        this.productList.forEach((x)=>pList.push({value: x.id, text: x.primaryName + "("+x.secondaryName+")", id: x.id}))
      }
      return pList
    }
  },
  components: { Datepicker},
  data(){
    return{
      productList: [],
      selectedProduct: "",
      from: "",
      to: "",
      selectedProductInfo: "",
      trxList: "",
      purchaseTotal: 0,
      salesTotal: 0,
      zerStock: 0,
      lastDate: "",
      statement: ""
      // trxList: [],
      // from: '',
      // to: '',
      // profitMot: 0.00,
      // salesMot: 0.00,
      // dateWise: [],
      // chartData: {
      //   labels: [],
      //   datasets: [
      //     {
      //       label: 'বিক্রয়',
      //       backgroundColor: '#0D47A1',
      //       data: []
      //     },
      //     {
      //       label: 'লাভ',
      //       backgroundColor: '#1B5E20',
      //       data: []
      //     }
      //   ]
      // }
    }
  },
watch:{
    selectedProduct(val){
      this.selectedProductInfo = Product.getProductInfoByID(val)
    }
},
  methods:{

    getStockReport(){
        let year = new Date(this.from).getFullYear()
        let month = new Date(this.from).getMonth()+1
        if(month>0 && month<10){
          month = "0"+month
        }
        let date = new Date(this.from).getDate()
        if(date>0 && date<10){
          date = "0"+date
        }
        let fromDate = year+"-"+month+"-"+date+"T00:00:00Z"
        year = new Date(this.to).getFullYear()
        month = new Date(this.to).getMonth()+1
        if(month>0 && month<10){
          month = "0"+month
        }
        date = new Date(this.to).getDate()
        if(date>0 && date<10){
          date = "0"+date
        }
        let toDate = year+"-"+month+"-"+date+"T23:59:59Z"
        this.lastDate = date+"-"+month+"-"+year
        fromDate = new Date(fromDate).getTime()
        toDate = new Date(toDate).getTime()
        this.trxList = DateAdjust.getTimeStampedTrxData()
        this.trxList = this.trxList.filter(x=> x.zz<=toDate && x.zz>=fromDate).filter(x=>x.type=="stock").filter(x=>x.info.id===this.selectedProductInfo.id)
        this.purchaseTotal = this.trxList.filter(x=>x.status==="in").reduce((sum, x)=> sum+x.quantity,0)
        this.salesTotal = this.trxList.filter(x=>x.status==="out").reduce((sum, x)=> sum+x.quantity,0)
      this.zerStock = DateAdjust.getTimeStampedTrxData().filter(x=> x.zz<fromDate).filter(x=>x.type=="stock").filter(x=>x.info.id===this.selectedProductInfo.id)
      this.zerStock = parseFloat(this.zerStock.filter(x=>x.status==="in").reduce((sum, x)=> sum+x.quantity,0)) - parseFloat(this.zerStock.filter(x=>x.status==="out").reduce((sum, x)=> sum+x.quantity,0))
      this.purchaseTotal = parseFloat(this.purchaseTotal) + parseFloat(this.zerStock)
    }

    // getPercent(sales, profit){
    //   let s = parseFloat(sales)
    //   let p = parseFloat(profit)
    //   let percent = (p/s)*100
    //   return percent
    // },
    // getList(){
    //   this.chartData.labels =[]
    //   this.chartData.datasets[0].data = []
    //   this.chartData.datasets[1].data = []
    //
    //   let year = new Date(this.from).getFullYear()
    //   let month = new Date(this.from).getMonth()+1
    //   if(month>0 && month<10){
    //     month = "0"+month
    //   }
    //   let date = new Date(this.from).getDate()
    //   if(date>0 && date<10){
    //     date = "0"+date
    //   }
    //   let fromDate = year+"-"+month+"-"+date+"T00:00:00Z"
    //   year = new Date(this.to).getFullYear()
    //   month = new Date(this.to).getMonth()+1
    //   if(month>0 && month<10){
    //     month = "0"+month
    //   }
    //   date = new Date(this.to).getDate()
    //   if(date>0 && date<10){
    //     date = "0"+date
    //   }
    //   let toDate = year+"-"+month+"-"+date+"T23:59:59Z"
    //
    //
    //
    //
    //   console.log("From Date: "+fromDate)
    //   this.from = new Date(fromDate).getTime()
    //   this.to = new Date(toDate).getTime()
    //   this.trxList = DateAdjust.printAllTrx()
    //   this.trxList = this.trxList.filter(x=> x.zz<=this.to && x.zz>=this.from).filter(x=>x.status=="out")
    //   this.profitMot = this.trxList.reduce((sum, x)=> sum+x.profit,0)
    //   this.salesMot = this.trxList.reduce((sum, x)=> sum+x.amount,0)
    //   this.getDateWise()
    // },
    // getDateWise(){
    //
    //   let result = [];
    //   this.trxList.reduce(function(res, value) {
    //     if (!res[value.date]) {
    //
    //       let selfDate = value.date.split("-")
    //       let year = selfDate[2]
    //       let month = selfDate[1]
    //       if(month>0 && month<10){
    //         month = "0"+month
    //       }
    //       let date = selfDate[0]
    //       if(date>0 && date<10){
    //         date = "0"+date
    //       }
    //       let isoDate = year+"-"+month+"-"+date
    //
    //
    //
    //       console.log(isoDate)
    //
    //       let dateItem = new Date(isoDate).getTime();
    //       res[value.date] = { date: value.date, sales: 0, profit: 0, timestamp: dateItem, full: isoDate };
    //       res[value.date].timestamp = new Date(value.date).getTime();
    //       result.push(res[value.date])
    //     }
    //     res[value.date].sales += value.amount;
    //     res[value.date].profit += value.profit;
    //
    //     return res;
    //   }, {});
    //   for(let i = 0; i<result.length-1; i++){
    //     for(let j = i+1; j<result.length; j++){
    //       if(result[i].timestamp>result[j].timestamp){
    //         let temp = result[i].timestamp;
    //         result[i].timestamp = result[j].timestamp
    //         result[j].timestamp = temp
    //       }
    //     }
    //   }
    //
    //   this.dateWise = result;
    //  for(let i = 0 ; i<result.length; i++){
    //     this.chartData.labels.push(result[i].date)
    //
    //    // for(let j = 0 ; j<result.length; j++) {
    //      this.chartData.datasets[0].data.push(result[i].sales)
    //    // }
    //
    //
    //    // for(let k = 0 ; k<result.length; k++) {
    //      this.chartData.datasets[1].data.push(result[i].profit)
    //    // }
    //
    //
    //  }
    //   console.log(this.chartData)
    // }

  },
  created() {
    this.productList = Product.getProducts()
  }
}
</script>

<style scoped>
.bl{
  border-left: 2px solid #000000 !important;
}
.br{
  border-right: 2px solid #000000 !important;
}
.bb{
  border-bottom: 2px solid #000000 !important;
}
.bt{
  border-top: 2px solid #000000 !important;
}
.bb-double{
  border-bottom: 3px double #000000 !important;
}
</style>