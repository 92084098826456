import { v4 as uuidv4 } from 'uuid';
import IdentityType from "@/services/DataStructure/IdentityType";
// import IdentityType from "@/services/DataStructure/IdentityType";
import BlackBox from "@/services/BlackBox";
import DateAdjust from "@/services/DateAdjust";
class Identity{




    #_id;
    #_primaryName;
    #_secondaryName;
    #_keyword;
    #_address;
    #_mobile;
    #_limit;
    #_registeredDate;
    #_type;
    #_note;
    #_reg_status;
    #_msg_info;

    constructor(data) {
        this.#_id = uuidv4()
        this.#_primaryName = data.primaryName
        this.#_secondaryName = data.secondaryName
        this.#_keyword = data.keyword
        this.#_address = data.address
        this.#_mobile = data.mobile
        this.#_limit = data.limit
        this.#_registeredDate = data.registeredDate
        this.#_type = data.type
        this.#_note = data.note
        Identity.getIdentityByPrimaryName(data.primaryName)
        Identity.getIdentityBySecondaryName(data.secondaryName)
        Identity.getIdentityByMobile(data.mobile)
        this.save()
    }

    get id() {
        return this.#_id;
    }
    get primaryName() {
        return this.#_primaryName;
    }

    set primaryName(value) {
        this.#_primaryName = value;
    }

    get secondaryName() {
        return this.#_secondaryName;
    }

    set secondaryName(value) {
        this.#_secondaryName = value;
    }

    get keyword() {
        return this.#_keyword;
    }

    set keyword(value) {
        this.#_keyword = value;
    }

    get address() {
        return this.#_address;
    }

    set address(value) {
        this.#_address = value;
    }

    get mobile() {
        return this.#_mobile;
    }

    set mobile(value) {
        this.#_mobile = value;
    }

    get limit() {
        return this.#_limit;
    }

    set limit(value) {
        this.#_limit = value;
    }

    get registeredDate() {
        return this.#_registeredDate;
    }

    set registeredDate(value) {
        this.#_registeredDate = value;
    }

    get type() {
        return this.#_type;
    }

    set type(value) {
        this.#_type = value;
    }

    get note() {
        return this.#_note;
    }

    set note(value) {
        this.#_note = value;
    }

    get regStatus() {
        return this.#_reg_status;
    }

    set regStatus(value) {
        this.#_reg_status = value;
    }

    get msgInfo(){
        return this.#_msg_info;
    }

    set msgInfo(value){
        this.#_msg_info = value
    }


    save(){

       let identityData =  {
           id: this.#_id,
           primaryName: this.#_primaryName,
           secondaryName: this.#_secondaryName,
           keyword: this.#_keyword,
           address: this.#_address,
           mobile: this.#_mobile,
           limit: this.#_limit,
           registeredDate: this.#_registeredDate,
           type: this.#_type,
           note: this.#_note
        }
        if(!localStorage.getItem('identity')){
            BlackBox.test()
            let newIdentity = [];
            newIdentity.push(identityData)
            localStorage.setItem('identity', JSON.stringify(newIdentity))
            this.regStatus = true
        }else{
            let oldIdentity = JSON.parse(localStorage.getItem('identity'))
            let res = oldIdentity.find( x => x.primaryName === identityData.primaryName || x.mobile === identityData.mobile || x.secondaryName === identityData.secondaryName)
            if(res === undefined){
                this.regStatus = true
                oldIdentity.push(identityData)
                localStorage.setItem('identity', JSON.stringify(oldIdentity))
            }else{
                this.regStatus = false
                //alert("This Identity is already exists!")
                let pData = Identity.getIdentityByPrimaryName(identityData.primaryName)
                let sData = Identity.getIdentityBySecondaryName(identityData.secondaryName)
                let mData = Identity.getIdentityByMobile(identityData.mobile)
                let msgInfo = "";
                if(pData.length>0){
                    let temp = Identity.getIdentityByID(pData[0].id)[0]
                    msgInfo += '<h4>এই বাংলা নামে রয়েছে!</h4>'+temp.primaryName+' ('+temp.secondaryName+'): '+temp.mobile+ ' '+temp.address+'<br><br>'

                }
                if(sData.length>0){
                    let temp = Identity.getIdentityByID(sData[0].id)[0]
                    msgInfo += '<h4>এই ইংরেজী নামে রয়েছে!</h4>'+temp.primaryName+' ('+temp.secondaryName+'): '+temp.mobile+ ' '+temp.address+'<br><br>'


                }if(mData.length>0){
                    let temp = Identity.getIdentityByID(mData[0].id)[0]
                    msgInfo += '<h4>এই মোবাইল নম্বরে রয়েছে!</h4>'+temp.primaryName+' ('+temp.secondaryName+'): '+temp.mobile+ ' '+temp.address+'<br><br>'

                }
                this.msgInfo = msgInfo
                /*
                this.$swal({

    title: '<strong>HTML <u>example</u></strong>',
    icon: 'info',
    html:
        'You can use <b>bold text</b>, ' +
        '<a href="//sweetalert2.github.io">links</a> ' +
        'and other HTML tags'
}) */

            }
            // oldIdentity.push(identityData)
            // localStorage.setItem('identity', JSON.stringify(oldIdentity))
        }
        let blackBox = {
            title: "New "+this.type+" Added from Form",
            msg: this.primaryName + " (" + this.secondaryName + ") " + this.mobile,
            info: identityData
        }
        BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
    }

    static save(data){
        if(!data.id){
            data.id = uuidv4()
        }
        if(!localStorage.getItem('identity')){
            let newIdentity = [];
            newIdentity.push(data)
            localStorage.setItem('identity', JSON.stringify(newIdentity))
        }else{
            let oldIdentity = JSON.parse(localStorage.getItem('identity'))
            if(oldIdentity.filter((x)=>x.primaryName===data.primaryName).length===0){
                oldIdentity.push(data)
                localStorage.setItem('identity', JSON.stringify(oldIdentity))
            }
        }

        let blackBox = {
            title: "New "+data.type+" Added Directly",
            msg: data.primaryName + " (" + data.secondaryName + ") " + data.mobile,
            info: data
        }
        BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
    }

    static getIdentities(){
        let data = JSON.parse(localStorage.getItem('identity'))
        return data;
    }

    static getIdentitiesByType(type){
        let data = JSON.parse(localStorage.getItem('identity')).filter(x=>x.type === type)
        return data;
    }

    static getIdentityByID(id){
        let data = JSON.parse(localStorage.getItem('identity')).filter(x=>x.id === id)
        return data;
    }

    static getIdentityByPrimaryName(primaryName){
        let data = JSON.parse(localStorage.getItem('identity')).filter(x=>x.primaryName === primaryName)
        console.log("Primary Name: "+data)
        return data;
    }
    static getIdentityBySecondaryName(secondaryName){
        let data = JSON.parse(localStorage.getItem('identity')).filter(x=>x.secondaryName === secondaryName)
        console.log("Secondary Name: "+data.length)
        return data;
    }

    static getIdentityByMobile(mobile){
        let data = JSON.parse(localStorage.getItem('identity')).filter(x=>x.mobile === mobile)
        console.log("Mobile: "+data.length)
        return data;
    }


    static updateIdentityByID(id, data){
        console.log("Data Info: "+id+" ->"+data.primaryName);
        let identity = JSON.parse(localStorage.getItem('identity'))
        identity.forEach(function (val, index){
            if(val.id===id){
                identity[index] = data
                identity[index].id = id
            }
            // console.log(val)
        })
        localStorage.setItem('identity', JSON.stringify(identity))
        let blackBox = {
            title: "Update "+data.type+" Information",
            msg: data.primaryName + " " + data.secondaryName,
            info: data
        }
        BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
    }

    static deleteIdentityByID(id){
        console.log("Data Info: "+id);
        let indexID = -1;
        let identity = JSON.parse(localStorage.getItem('identity'))
        let trxInfo = JSON.parse(localStorage.getItem('trxInfo'))
        if((trxInfo.find(v => v.info.id === id)) !==  undefined)
        {
            alert("এই প্রোফাইলে লেনদেন করা হয়েছে। তাই ডিলিট করা সম্ভব না। দয়া করে কাস্টমার কেয়ারে যোগাযোগ করুন।")
            return false
        }
        else{
            let blackBox;
            identity.forEach(function (val, index){
                if(val.id===id){
                    indexID = index
                    blackBox = {
                        title: "Delete "+val.type+" Information",
                        msg: val.primaryName + " " + val.secondaryName,
                        info: val
                    }
                }
                console.log("inside forEach-> "+indexID)
            })
            console.log("global scope-> "+indexID)
            if(indexID > -1){
                identity.splice(indexID,1)
                BlackBox.writeToBlackBox(blackBox.title,blackBox.msg, blackBox.info)
                localStorage.setItem('identity', JSON.stringify(identity))
                return true
            }

        }
    }


    static getIdentityTypes(){

        let typesArray = [];
        for (const [key, value] of Object.entries(IdentityType)) {
            typesArray.push(value)
            key.toLowerCase()
        }
        return typesArray
    }

    static getIdentityBalanceToDateByID(identityID, toDateParam){
        let fromDate = DateAdjust.formatDateToTimestamp("01 January 1970", "from")
        let toDate = DateAdjust.formatDateToTimestamp(toDateParam, "from")
        let trxInfo = DateAdjust.printAllTrx()
        trxInfo = trxInfo.filter(x=>x.info.id === identityID).filter(x=>x.zz<=toDate && x.zz>=fromDate)
        return trxInfo.filter((x)=>x.section==="deposit").reduce((sum,i)=>sum+i.amount,0)-trxInfo.filter((x)=>x.section==="expense").reduce((sum,i)=>sum+i.amount,0)
    }

    static getIdentityBalanceWithinPeriodByID(identityID, fromDateParam, toDateParam){
        // from and to date has to be in String 01 January 1970
        console.log(fromDateParam + "  ->  "+toDateParam)
        let fromDate = DateAdjust.formatDateToTimestamp(fromDateParam, "from")
        let toDate = DateAdjust.formatDateToTimestamp(toDateParam, "from")
        let trxInfo = DateAdjust.printAllTrx()
        trxInfo = trxInfo.filter(x=>x.info.id === identityID).filter(x=>x.zz<=toDate && x.zz>=fromDate)
        return trxInfo.filter((x)=>x.section==="deposit").reduce((sum,i)=>sum+i.amount,0)-trxInfo.filter((x)=>x.section==="expense").reduce((sum,i)=>sum+i.amount,0)
    }


    // toString(){
    //     return "Identity name is: "+this.#_primaryName
    // }

}

export default Identity;