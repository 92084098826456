const fmt = require('indian-number-format');
const {toBengaliWord} = require('bengali-number');

export default {
  valueParse(value){
    value = parseFloat(value)
    if(isNaN(value) || typeof value === 'string' || value instanceof String){
      return 0;
    }
    return parseFloat(value);
  },

  commaWithCurrency(value){
    return "৳ "+fmt.format(this.valueParse(value))
  },
  commaDecimalWithCurrency(value){
    return "৳ "+fmt.formatFixed(this.valueParse(value),2)
  },
  comma(value){
    return fmt.format(this.valueParse(value))
  },
  commaDecimal(value){
    return fmt.formatFixed(this.valueParse(value),2)
  },
  toWordPlain(value){
    return toBengaliWord(this.valueParse(value))
  },
  toWordCurrency(value){
    return toBengaliWord(this.valueParse(value))+" টাকা মাত্র";
  }

}
